<template>
  <div class="rateChart" :style="`font-size: ${fontSize}px`" ref="rateChart">
    <div class="rateTitle">{{ title }}</div>
    <div class="chart">
      <div class="rateHand" :style="`transform: rotate(${(value-0.5) * 180}deg)`">
        <svg preserveAspectRatio="none" viewBox="0 0 20 200">
          <path fill="#fff" d="M5 0 L0 200 L20 200 L15 0Z" />
        </svg>
      </div>
    </div>
    <div class="rateInfo d-flex justify-content-around">
      <div class="item">
        <div class="number">{{ sell }}</div>
        <div class="label">Sell</div>
      </div>
      <div class="item">
        <div class="number">{{ neutral }}</div>
        <div class="label">Neutral</div>
      </div>
      <div class="item">
        <div class="number">{{ buy }}</div>
        <div class="label">Buy</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'value',
    'title',
    'sell',
    'neutral',
    'buy'
  ],
  data(){
    return {
      fontSize: 12,
      windowEvent: null
    }
  },
  methods: {
    checkResizeWindow(){
      this.fontSize = this.$refs.rateChart.offsetWidth/20;
    }
  },
  mounted(){
    this.checkResizeWindow();
    this.windowEvent = () => { this.checkResizeWindow() };
    window.addEventListener('resize', this.windowEvent);
  },
  destroyed(){
    window.removeEventListener('resize', this.windowEvent);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';

.rateChart {

  .chart {
    background-image: url('~@/assets/images/transaction/rate.svg');
    padding-top: 50%;
    position: relative;

    .rateHand {
      height: 40%;
      left: 49%;
      position: absolute;
      bottom: 5%;
      transform-origin: 50% 100%;
      width: 2%;
      overflow: hidden;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .rateTitle {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    color: $primaryColor;
    margin-bottom: -1em;
  }

  .rateInfo {
    text-align: center;
    padding: 5% 10%;

    .number {
      font-weight: bold;
      font-size: 1.2em;
    }

    .label {
      font-size: .8em;
    }
  }
}
</style>