<template>
  <div class="sidePanel">
    <transition name="fadeIn">
      <div class="panelBackground" v-if="value" @click="closePanel"></div>
    </transition>
    <transition name="slideInRight">
      <div class="panelContent" v-if="value">
        <!-- CLOSE PANEL-BUTTON -->
        <div class="closeBar text-right w-100">
          <b-button class="closeButton" variant="none" @click="closePanel">
            <img v-lazy="require('@/assets/images/icons/close.svg')" alt="" class="w-100">
          </b-button>
        </div>
        <!-- END CLOSE PANEL-BUTTON -->

        <!-- MAIN CONTENT -->
        <slot/>
        <!-- END MAIN CONTENT -->
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [
    'value'
  ],
  methods: {
    closePanel(){
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="scss" scoped>

/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';

.sidePanel {
  .panelBackground {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 3000;
  }

  .panelContent {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3001;
    max-width: 420px;
    width: 100%;
    height: 100%;
    background-color: #111111;
  }

  .closeBar {
    height: 3em;
    padding: .5em 1em;
    line-height: 3em;

    .closeButton {
      color: white;
      font-family: monospace;
      font-weight: bold;
      background-color: black;
      border-radius: 50%;
      width: 32px;
      padding: 4px 8px 4px 8px;
      height: 32px;

      img {
        @include whiteFilter();
      }
    }
  }
}
</style>