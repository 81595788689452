<script>
// Spline renderer. (SMAs, EMAs, TEMAs...
// you know what I mean)
// TODO: make a real spline, not a bunch of lines...

// Adds all necessary stuff for you.
import { Overlay } from "trading-vue-js";

export default {
	name: "TestOverlay",
	mixins: [Overlay],
	methods: {
		meta_info() {
			return { author: "CyberFist", version: "1.0.0" };
		},
		// Here goes your code. You are provided with:
		// { All stuff is reactive }
		// $props.layout -> positions of all chart elements +
		//  some helper functions (see layout_fn.js)
		// $props.interval -> candlestick time interval
		// $props.sub -> current subset of candlestick data
		// $props.data -> your indicator's data subset.
		//  Comes "as is", should have the following format:
		//  [[<timestamp>, ... ], ... ]
		// $props.colors -> colors (see TradingVue.vue)
		// $props.cursor -> current position of crosshair
		// $props.settings -> indicator's custom settings
		//  E.g. colors, line thickness, etc. You define it.
		// $props.num -> indicator's layer number (of All
		// layers in the current grid)
		// $props.id -> indicator's id (e.g. EMA_0)
		// ~
		// Finally, let's make the canvas dirty!
		draw(ctx) {
			ctx.lineWidth = this.line_width;
			ctx.strokeStyle = this.color;

			const layout = this.$props.layout;
			const j = this.data_index;
			var lines = [];
			for (var p of this.$props.data) {
				let x = layout.t2screen(p[0]);
				let y = layout.$2screen(p[j]);
				p = { x: x, y: y };
				lines.push(p);
			}
			bzCurve(lines, 0.4, 1.01);
			function bzCurve(points, f, t) {
				if (points && points[0]) {
					//f = 0, will be straight line
					//t suppose to be 1, but changing the value can control the smoothness too
					if (typeof f == "undefined") f = 0.3;
					if (typeof t == "undefined") t = 0.6;

					ctx.beginPath();
					ctx.moveTo(points[0].x, points[0].y);

					var m = 0;
					var dx1 = 0;
					var dy1 = 0;
					var dx2, dy2;
					var preP = points[0];
					for (var i = 1; i < points.length; i++) {
						var curP = points[i];
						var nexP = points[i + 1];
						if (nexP) {
							m = gradient(preP, nexP);
							dx2 = (nexP.x - curP.x) * -f;
							dy2 = dx2 * m * t;
						} else {
							dx2 = 0;
							dy2 = 0;
						}
						ctx.bezierCurveTo(
							preP.x - dx1,
							preP.y - dy1,
							curP.x + dx2,
							curP.y + dy2,
							curP.x,
							curP.y
						);
						dx1 = dx2;
						dy1 = dy2;
						preP = curP;
					}
					ctx.stroke();
				}
			}
			function gradient(a, b) {
				return (b.y - a.y) / (b.x - a.x);
			}
		},

		// For all data with these types overlay will be
		// added to the renderer list. And '$props.data'
		// will have the corresponding values. If you want to
		// redefine the default behviour for a prticular
		// indicator (let's say EMA),
		// just create a new overlay with the same type:
		// e.g. use_for() { return ['EMA'] }.
		use_for() {
			return ["EMA"];
		},

		// Colors for the legend, should have the
		// same dimention as a data point (excl. timestamp)
		data_colors() {
			return [this.color];
		},
	},
	// Define internal setting & constants here
	computed: {
		sett() {
			return this.$props.settings;
		},
		line_width() {
			return this.sett.lineWidth || 1.5;
		},
		color() {
			const n = this.$props.num % 5;
			return this.sett.color || this.COLORS[n];
		},
		data_index() {
			return this.sett.dataIndex || 1;
		},
	},
	data() {
		return {
			COLORS: ["#5691ce", "#ff2316", "#d50b90", "#612ff9"],
		};
	},
};
</script>
