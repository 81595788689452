<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { lang } from 'moment';
import { mapMutations, mapState, mapGetters } from 'vuex';
export default {
	methods: {
		...mapMutations(['onload', 'outload']),
	},
	computed: {
		...mapState(['']),
		...mapGetters({
			Error: 'core/Error',
			Success: 'core/Success',
			user: 'userinfo/User',
		}),
	},
	mounted() {},
	watch: {
		user: {
			handler(newVal) {
				if (newVal) {
					this.$store.dispatch('userinfo/req_getInfo');
				}
			},
		},
	},
	created() {
		let self = this;
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case 'core/SUCCESS_MESSANGE':
					this.$toastr.s(self.Success, this.$t('app.success'));
					this.outload();
					break;
				case 'core/ERROR_MESSANGE':
					this.$toastr.e(self.Error, this.$t('app.error'));
					this.outload();
					break;
			}
		});
		const soundst = this.$cookies.get('wx_sound');
		if (soundst !== undefined) {
			this.$store.commit('getSound', soundst == 'false' ? false : true);
		}
		const userRaw = this.$cookies.get('wx_info');
		if (userRaw !== undefined && userRaw !== null) {
			this.$store.commit('userinfo/GET_USER_INFO', userRaw);
		}
		const userC = this.$cookies.get('wx_user');
		if (userC !== undefined && userC !== null) {
			this.$store.commit('userinfo/setUser', userC);
		}

		const langUser = this.$cookies.get('language');
		if (langUser) {
			this.$i18n.locale = langUser;
		}
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>
<style lang="scss">
.text-uppercase {
	text-transform: uppercase;
}
.text-capitalize {
	text-transform: capitalize;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-middle {
	vertical-align: middle !important;
}
.table-responsive {
	position: relative;
}
.thead-ig {
    th{
        padding-right:20px!important;
    }
}
</style>
