<template>
	<div class="publicLayout layout">
		<Loader v-if="isLoad" class="position-absolute" />
		<!--TOPBAR -->
		<div class="container">
			<div class="layout-auth position-relative">
				<div class="sidePanelForm text-light">
					<h3 class="text-center mt-4 mb-2">
						<img
							v-lazy="require('@/assets/images/logo.svg')"
							style="width: 100%; margin-bottom: 20px; margin-top: 10px"
						/>
					</h3>
					<div class="text-center">
						<label class="text-white text-left d-block" for="username">{{
							$t('auth.email')
						}}</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								:style="showAuthentication ? 'opacity: 0.2;' : ''"
								type="email"
								class="auth-input"
								v-model="users"
								:placeholder="$t('auth.email')"
								required
							/>
						</div>
						<label class="text-white text-left d-block" for="username">{{
							$t('auth.password')
						}}</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-lock"></i>
								</span>
							</div>
							<input
								:style="showAuthentication ? 'opacity: 0.2;' : ''"
								type="password"
								class="auth-input"
								v-model="password"
								:placeholder="$t('auth.password')"
								required
								@keyup.enter="login"
							/>
						</div>

						<div class="input-group" v-if="showAuthentication">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								type="text"
								class="auth-input g2a"
								v-model="authCode"
								:placeholder="$t('auth.please_enter_authentication_code')"
								required
								@keyup.enter="login"
							/>
						</div>
						<div class="input-group d-flex justify-content-end mb-3">
							<a
								class="text-right"
								style="cursor: pointer"
								@click="isForgotPanelShow = !isForgotPanelShow"
								>{{ $t('auth.forgot_password') }}</a
							>
						</div>
						<div class="input-group d-flex justify-content-around mb-3 mt-2">
							<b-button
								variant="primary"
								class="btn-logo"
								@click="login"
								:disabled="!users || !password"
							>
								{{ $t('auth.sign_in') }}
							</b-button>
							<b-button
								class="topBarButton btn-logo ml-1 outline"
								variant="primary"
								@click="isRegisterPanelShow = true"
								v-if="!user"
								>{{ $t('auth.sign_up') }}</b-button
							>
						</div>
						<div class="input-group d-flex justify-content-center mb-2 mt-4">
							<a
								class="text-center text-danger"
								style="cursor: pointer"
								@click="isSendPanelShow = !isSendPanelShow"
							>
								{{ $t('auth.resend_email') }}</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<SidePanel v-model="isRegisterPanelShow">
			<!-- REGISTER FORM -->
			<div class="sidePanelForm text-light" style="width: 80%; margin: auto">
				<h3 class="text-center mb-4">
					<img
						v-lazy="require('@/assets/images/logo.svg')"
						style="width: 100%; margin-bottom: 20px; margin-top: 10px"
					/>
				</h3>

				<form @submit.prevent="onRegister()">
					<div
						class="text-center d-flex flex-column justify-content-center align-items-center align-self-center"
					>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								type="text"
								class="auth-input"
								v-model="register.email"
								:placeholder="$t('auth.email')"
								required
							/>
						</div>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-lock"></i>
								</span>
							</div>
							<input
								type="password"
								class="auth-input"
								v-model="register.password"
								:placeholder="$t('auth.password')"
								required
							/>
						</div>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-lock"></i>
								</span>
							</div>
							<input
								type="password"
								class="auth-input"
								v-model="register.password_confirm"
								:placeholder="$t('auth.password_confirmation')"
								required
							/>
						</div>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								type="text"
								class="auth-input"
								v-model="register.sponsor"
								:placeholder="$t('auth.enter_sponsor')"
							/>
						</div>
						<b-button variant="primary" class="btn-logo" type="submit">
							{{ $t('auth.sign_up') }}
						</b-button>
					</div>
				</form>
			</div>
			<!-- END REGISTER FORM -->
		</SidePanel>
		<!-- END REGISTER -->
		<SidePanel v-model="isForgotPanelShow">
			<!-- REGISTER FORM -->
			<div class="sidePanelForm text-light" style="width: 80%; margin: auto">
				<h3 class="text-center mb-4">
					<img
						v-lazy="require('@/assets/images/logo.svg')"
						style="width: 100%; margin-bottom: 20px; margin-top: 10px"
					/>
				</h3>

				<form @submit.prevent="onForgot()">
					<div
						class="text-center d-flex flex-column justify-content-center align-items-center align-self-center"
					>
						<h4 for="">Forgot Password</h4>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								type="text"
								class="auth-input"
								v-model="forgot.email"
								:placeholder="$t('auth.enter_your_email')"
								required
							/>
						</div>
						<b-button variant="primary" class="btn-logo" type="submit">
							{{ $t('auth.send') }}
						</b-button>
					</div>
				</form>
			</div>
		</SidePanel>
		<SidePanel v-model="isSendPanelShow">
			<!-- REGISTER FORM -->
			<div class="sidePanelForm text-light" style="width: 80%; margin: auto">
				<h3 class="text-center mb-4">
					<img
						v-lazy="require('@/assets/images/logo.svg')"
						style="width: 100%; margin-bottom: 20px; margin-top: 10px"
					/>
				</h3>

				<form @submit.prevent="onResend()">
					<div
						class="text-center d-flex flex-column justify-content-center align-items-center align-self-center"
					>
						<h4 for="">{{ $t('auth.resend_email') }}</h4>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="basic-addon1">
									<i class="fas fa-user-tie"></i>
								</span>
							</div>
							<input
								type="text"
								class="auth-input"
								v-model="resend.email"
								:placeholder="$t('auth.enter_your_email')"
								required
							/>
						</div>
						<b-button variant="primary" class="btn-logo" type="submit">
							{{ $t('auth.send') }}
						</b-button>
					</div>
				</form>
			</div>
		</SidePanel>
		<!-- DASHBOARD -->
		<router-link
			class="btn btn-primary topBarButton"
			to="/dashboard"
			v-if="user"
			>{{ $t('exchange.dashboard') }}</router-link
		>
		<!-- END DASHBOARD -->

		<!-- LOGOUT -->
		<b-button
			class="topBarButton"
			variant="primary"
			@click="logout"
			v-if="user"
			>{{ $t('exchange.logout') }}</b-button
		>
		<b-modal
			id="modal-notifi"
			title=""
			hide-footer
			body-class="p-0"
			header-class="p-0"
			modal-class="d-flex justify-content-center flex-column"
		>
			<b-carousel
				id="carousel-1"
				v-model="slide"
				:interval="4000"
				controls
				indicators
				background="#ababab"
				img-width="1024"
				img-height="480"
				style="text-shadow: 1px 1px 2px #333"
				@sliding-start="onSlideStart"
				@sliding-end="onSlideEnd"
			>
				<!-- Text slides with image -->
				<b-carousel-slide
					v-for="notifi in NotifileList"
					:key="notifi.image"
					class="img-carousel"
					:img-src="notifi.image"
				></b-carousel-slide>
			</b-carousel>
		</b-modal>
		<!-- END LOGOUT -->
	</div>
</template>

<script>
// @ is an alias to /src
import SidePanel from '@/components/SidePanel.vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
import Loader from '@/components/Loader.vue';
export default {
	name: 'Home',
	data() {
		return {
			isLoginPanelShow: false,
			isRegisterPanelShow: false,
			isForgotPanelShow: false,
			isSendPanelShow: false,
			currentLanguage: 'vi',
			languageName: {
				vi: 'Tiếng Việt',
				en: 'English',
			},
			showAuthentication: false,
			users: '',
			password: '',
			authCode: '',
			register: {
				email: '',
				password: '',
				password_confirm: '',
				sponsor: '',
			},
			forgot: {
				email: '',
			},
			resend: {
				email: '',
			},
			slide: 0,
			sliding: null,
		};
	},
	components: {
		SidePanel,
		Loader,
	},
	computed: {
		...mapState(['user', 'isLoad']),
		...mapGetters({
			NotifileList: 'core/NotificationImage',
		}),
	},
	watch: {
		NotifileList: {
			handler(newVal) {
				if (newVal.length > 0) {
					this.$bvModal.show('modal-notifi');
				}
			},
		},
	},
	created() {
		if (this.$route.query.s && this.$route.query.m) {
			if (this.$route.query.s == 1) {
				this.$toastr.s(this.$route.query.m);
			}
			if (this.$route.query.s == 0) {
				this.$toastr.w(this.$route.query.m);
			}
			if (this.$route.query.s == -1) {
				this.$toastr.e(this.$route.query.m);
			}
		}
		this.$store.dispatch('core/req_getNotificationImages');
		const sponsor = this.$route.query.sponsor;
		if (sponsor) {
			this.register.sponsor = sponsor;
			this.isRegisterPanelShow = true;
		}
		const regis = this.$route.query.register;

		if (regis !== undefined) {
			this.isRegisterPanelShow = true;
		}
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case 'auth/LOGIN_SUCCESS':
					this.$toastr.s(this.$t('auth.login_successful'), this.$t('app.success'));
					this.isLoginPanelShow = false;
					this.outload();
					this.$router.push({ name: 'dashboard' });
					break;

				case 'auth/REGISTER_SUCCESS':
					this.isLoginPanelShow = true;
					this.isRegisterPanelShow = false;
					break;
				case 'auth/REQUIRE_AUTH':
					this.showAuthentication = true;
					this.outload();
					break;
				case 'auth/FORGOT_SUCCESS':
					this.isForgotPanelShow = false;
					break;
				case 'auth/RESEND_SUCCESS':
					this.isSendPanelShow = false;
					break;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	methods: {
		...mapMutations(['onload', 'outload']),
		onSlideStart() {
			this.sliding = true;
		},
		onSlideEnd() {
			this.sliding = false;
		},
		login() {
			if (this.users && this.password) {
				this.onload();
				this.$store.dispatch('auth/req_PostLogin', {
					email: this.users,
					password: this.password,
					authCode: this.authCode,
				});
			}
		},
		onRegister() {
			this.onload();
			this.$store.dispatch('auth/req_PostRegister', this.register);
		},
		onForgot() {
			this.onload();
			this.$store.dispatch('auth/req_PostForgot', this.forgot);
		},
		onResend() {
			this.onload();
			this.$store.dispatch('auth/req_SendMail', this.resend);
		},
		logout() {
			this.$store.commit('auth/LOGOUT_SUCCESS');
		},
		changeLanguage(code) {
			this.currentLanguage = code;
			this.$i18n.locale = code;
		},

		getFlag(code) {
			// eslint-disable-next-line no-undef
			var images = require.context('@/assets/images/home/', false, /\.png$/);
			return images('./' + code + '.png');
		},
	},
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';
.btn-logo {
	letter-spacing: 0.05rem;
	position: relative;
	background:  #00ff85;
	color: #fff !important;
	min-width: 130px;
	overflow: hidden;
	transition: 0.3s ease-in-out;
	border-radius: 0.3rem;
	border-color: #00ff85 !important;
	z-index: 1;
	box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
}
.btn-logo.outline {
	background: #fff0 !important;
}
.btn-logo:hover {
	transform: scale(1.1);
}
.ml-10 {
	margin-left: 10px;
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.428571rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.428572;
	color: #575757;
	text-align: center;
	white-space: nowrap;
	border: 1px solid #27ab70;
	border-radius: 0.25rem;
	background:#00ff85;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 100px;
	border-top-left-radius: 100px;
	color: #fff;
	border-color: #27ab70;
}
input.auth-input {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}
.input-group-prepend,
.input-group-append {
	display: -ms-flexbox;
	display: flex;
}
/*** TOPBAR ***/
.publicLayout {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	min-height: 100vh;
}
@media (min-width: 768px) {
	.publicLayout {
		background: url('~@/assets/images/bg_ex_pc.jpg');
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
@media (max-width: 768px) {
	.publicLayout {
		background: url('~@/assets/images/bg_ex_mb.png');
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
.layout-auth {
	width: 100%;
	max-width: 370px;
	margin: auto;
	border: none;
	border-top: 3px #027d4c solid;
	border-bottom: 2px #00ff85 solid;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 10px;
	transition: 0.5s;
	position: relative;
	overflow: hidden;
	padding: 25px;
}
@media (max-width: 400px) {
	.layout-auth {
		padding: 10px;
	}
	.publicLayout.layout {
		.container {
			padding: 5px !important;
		}
	}
}
.topBar {
	height: 50px;
	padding: 7px 20px;
	line-height: 36px;
	background-image: linear-gradient(0deg, #101416, #42403f);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 900;

	.topBarButton {
		font-weight: bold;
		margin: 0 0.3em;
	}
}

/*** FOOTER SECTION ***/
.footerWrapper.homeSection {
	min-height: auto;
}

.footerWrapper {
	@include overlayBackground('~@/assets/images/home/footerBackground.jpg', 0);
	border-bottom: 30px solid $primaryColor;

	h4 {
		text-transform: uppercase;
		font-family: Roboto;
		letter-spacing: 0;
	}

	a {
		color: white;
		display: block;
		margin: 0.5em 0;
	}

	.languageSwitch {
		img {
			margin: 0.3em 0.5em 0.3em 0;
		}
	}

	hr {
		border-bottom: 1px solid $primaryColor;
		width: 100%;
	}
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.topBar {
		.topBarButton {
			line-height: 1em;
			font-size: 1.3em;
		}
	}
}
input.auth-input {
	border-radius: 0;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	border: 1px solid;
	border-color: #27ab70;
	outline: none;
	display: block;
	height: calc(1.428572em + 0.857143rem + 2px);
	padding: 0.428571rem 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.428572;
	background: #000;
	color: #fff;
	background-clip: padding-box;
	transition: border-color ease-in-out 0.15s;
}
.input-group {
	margin-bottom: 1rem;
}
.img-carousel {
	width: auto !important;
	max-width: 100%;
	max-height: 85vh;
}
</style>