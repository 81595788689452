import Vue from 'vue'
import VueRouter from 'vue-router'

// layouts
import Public from '../layouts/Public.vue'
import Private from '../layouts/Private.vue'
import store from '../store'
// views
import Home from '../views/Home.vue'
import PrivateDashboard from '../views/PrivateDashboard.vue'
import PrivateAdminDashboard from '../views/PrivateAdminDashboard.vue'
import PrivateTransaction_live from '../views/PrivateTransaction_live.vue'
import PrivateTransaction_demo from '../views/PrivateTransaction_demo.vue'
import PrivateAgency from '../views/PrivateAgency.vue'
import PrivateWallet from '../views/PrivateWallet.vue'
import PrivateProfile from '../views/PrivateProfile.vue'
import PrivateSupport from '../views/PrivateSupport.vue'
import PrivateInsurance from '../views/PrivateInsurance.vue'
import PrivateCommunity from '../views/PrivateCommunity.vue'
import PrivateLucky from '../views/PrivateLucky';
import landing from '../views/Landing.vue';
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: landing,
        children: [{
            path: '',
            name: 'landing',
            component: landing,
        }],
    },
    {
        path: '/login',
        component: Public,
        children: [{
            path: '',
            name: 'landing',
            component: Home,
        }],
    },
    {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "group-foo" */ '../components/errors/404.vue')
    },
    {
        path: '/500',
        name: '500',
        component: () => import(/* webpackChunkName: "group-foo" */ '../components/errors/500.vue')
    },
    {
        path: '/zxvaBQTdsffavdsadasd/dasdasdasdasd',
        component: () => import(/* webpackChunkName: "group-foo" */ '../views/door.vue')
    },
    {
        path: '/system',
        component: Private,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'dashboard',
                name: "dashboard",
                component: PrivateDashboard
            },
            {
                path: 'statistical-trade',
                name: "statistical-trade",
                component: () => import(/* webpackChunkName: "group-foo" */ '../views/PrivateStatistical-trade.vue')
            },
            {
                path: 'statistical-user',
                name: "statistical-user",
                component: () => import(/* webpackChunkName: "group-foo" */ '../views/PrivateStatistical-user.vue')
            },
            {
                path: 'transaction-live',
                name: 'live',
                component: PrivateTransaction_live,
                meta: {
                    balance: "live",
                    page: "trade"
                }
            },
            {
                path: 'transaction-demo',
                name: 'demo',
                component: PrivateTransaction_demo,
                meta: {
                    balance: "demo",
                    page: "trade"
                }
            },
            {
                path: 'community',
                component: PrivateCommunity
            },
            {
                path: 'lucky',
                component: PrivateLucky,
                meta:{
                    page: "lucky"
                }
            },
            {
                path: 'agency',
                component: PrivateAgency
            },
            {
                path: 'wallet',
                component: PrivateWallet
            },
            {
                path: 'insurance',
                component: PrivateInsurance
            },
            // {
            //     path: 'trade',
            //     component: PrivateTrade
            // },
            // {
            //     path: 'competition',
            //     component: PrivateCompetition
            // },
            {
                path: 'profile',
                component: PrivateProfile
            },
            {
                path: 'support',
                component: PrivateSupport
            },
            // {
            //     path: 'news',
            //     component: PrivateNews
            // },
            {
                path: 'admin-dashboard',
                name: "adminDashboard",
                component: PrivateAdminDashboard
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 1 };
        }
    }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
router.beforeEach(async (to, from, next) => {
    const userRaw = window.$cookies.get("wx_user")
    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (userRaw) {
            next();
            if (to.name == 'demo') {
                store.commit('core/SET_ACCOUNT_TYPE', 1);
            } else {
                store.commit('core/SET_ACCOUNT_TYPE', 2);
            }
        } else {
            next({ name: "landing" });
        }
    } else {
        next();
    }
});
export default router