<template>
  <keep-alive>
    <div
      class="privateDashboard py-3"
      v-show="UserInfo.level == 99"
    >
      <b-container fluid>
        <b-row class="mb-4">
          <b-col cols="12" md="7">
            <!-- TRADE STATS -->
            <div class="card">
              <div class="flex-fill d-flex justify-content-around">
                <h2 class="mb-3 text-left">
                  User Bet
                  <div class="float-right">
                    <div class="float-left">
                      <span class="font-15">
                        FundDs: {{ TruncateToDecimals(Funds.playMoney) }}
                      </span>
                    </div>
                    <b-button variant="success" class="mr-1" @click="getFund()">
                      <i class="fad fa-sync"></i>
                    </b-button>
                    <b-button
                      variant="info"
                      title="Deposit Fund"
                      class="mr-1"
                      v-if="remain.timefull <= 45"
                      v-b-modal.depositFund
                    >
                      <i class="fas fa-plus"></i>
                    </b-button>
                    <b-button
                      variant="danger"
                      class="mr-1"
                      title="Withdraw Fund"
                      v-if="remain.timefull <= 45"
                      v-b-modal.withdrawFund
                    >
                      <i class="fas fa-minus"></i>
                    </b-button>
                    <b-button
                      variant="light"
                      title="History Fund"
                      v-b-modal.historyFund
                      @click="getHistory()"
                    >
                      <i class="fas fa-history"></i>
                    </b-button>
                  </div>
                </h2>
              </div>
              <div
                class="tableWrapper table-responsive"
                style="max-height: 80vh; overflow: auto"
              >
                <div class="footer-card">
                  <div class="btn-group">
                    <!-- khong danh -->
                    <button @click.prevent="errorForTrader('SKIP_BUY_SELL')">
                      Close Buy Sell
                    </button>
                    <!-- x2 -->
                    <button @click.prevent="errorForTrader('X2_BET')">
                      x2 Buy Sell
                    </button>
                    <!-- dao nut -->
                    <button @click.prevent="errorForTrader('SWAP_BUY_SELL')">
                      Swap Buy Sell
                    </button>
                    <!-- disconnect -->
                    <button @click.prevent="errorForTrader('LOG_OUT')">
                      Logout user
                    </button>
                    <!-- danh het -->
                    <button @click.prevent="errorForTrader('ALL_BET')">
                      Bet All
                    </button>
                  </div>
                  <textarea
                    cols="4"
                    class="text-white"
                    v-model="errorTrader.listUserId"
                  ></textarea>
                  <hr />
                </div>
                <table class="table w-100">
                  <thead>
                    <th>user</th>
                    <th>buy</th>
                    <th>sell</th>
                    <th>symbol</th>
                    <th>Balance Percent</th>
                    <th class="text-right pr-4">endBalance</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(user, index) in betList"
                      :key="`userbet_${user.userId + index}`"
                    >
                      <td class="text-center">{{ user.userId }}</td>
                      <td class="text-center">
                        {{ user.betType == "GREEN" ? user.amount : "" }}
                      </td>
                      <td class="text-center">
                        {{ user.betType == "RED" ? user.amount : "" }}
                      </td>

                      <td class="text-center">{{ user.symbolId }}</td>
                      <td class="text-center">
                        {{
                          truncateToDecimals(
                            (user.amount / user.balance) * 100
                          )
                        }}%
                      </td>
                      <td class="text-right pr-4">
                        {{ user.balance.toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="5">
            <!-- SUMMARY -->
            <!-- <div class="card">{{ remain.time }}{{ remain.round }}</div> -->
            <div class="card countdown">
              <span
                class="time"
                :key="`admin_okie_${remain.timefull}_${remain.time}_${remain.round}`"
                :class="remain.round == 'Order' ? 'up' : 'down'"
                >{{ remain.time }}</span
              >
              <span
                class="round"
                :class="remain.round == 'Order' ? 'up' : 'down'"
                >{{ remain.round }}</span
              >
            </div>
            <div class="card">
              <div class="flex-fill">
                <h2 class="mb-3">symbol</h2>
              </div>
              <div class="tableWrapper table-responsive">
                <table class="table w-100 table-symbol">
                  <thead>
                    <th>symbol</th>
                    <th>buy</th>
                    <th>sell</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">BTCUSDT</td>
                      <td class="text-center">
                        <span>{{ betAmount.BTCUSDT.GREEN }} USDT</span>
                        <button
                          class="btn-buy-win"
                          v-if="remain.timefull <= 50"
                          @click="setResult('BTCUSDT', 'GREEN')"
                        >
                          Win
                        </button>
                      </td>
                      <td class="text-center">
                        <span>{{ betAmount.BTCUSDT.RED }} USDT</span>
                        <button
                          class="btn-sell-win"
                          v-if="remain.timefull <= 50"
                          @click="setResult('BTCUSDT', 'RED')"
                        >
                          Win
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">ETHUSDT</td>
                      <td class="text-center">
                        <span>{{ betAmount.ETHUSDT.GREEN }} USDT</span>
                        <button
                          class="btn-buy-win"
                          v-if="remain.timefull <= 50"
                          @click="setResult('ETHUSDT', 'GREEN')"
                        >
                          Win
                        </button>
                      </td>
                      <td class="text-center">
                        <span>{{ betAmount.ETHUSDT.RED }} USDT</span>
                        <button
                          class="btn-sell-win"
                          v-if="remain.timefull <= 50"
                          @click="setResult('ETHUSDT', 'RED')"
                        >
                          Win
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card">
              <div class="flex-fill">
                <h2 class="mb-3">User Online ({{ onlineList.length }})</h2>
              </div>
              <div class="tableWrapper table-responsive">
                <table class="table w-100">
                  <thead>
                    <th style="width: 20%" class="text-left pl-3">id</th>
                    <th class="text-center">User Id</th>
                    <th style="width: 60%" class="text-right pr-4">balance({{totalBalance.toFixed(2)}})</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(user, index) in onlineList"
                      :key="`useronline_${user.userId}_${index}`"
                    >
                      <td class="text-left pl-3" style="width: 20%">
                        {{ index + 1 }}
                      </td>
                      <td style="width: 20%">{{ user.userId }}</td>
                      <td style="width: 60%" class="text-right pr-4">
                        {{ user.balance.toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-modal id="depositFund" :title="'Deposit Fund'" hide-footer>
        <div class="form-group">
          <label for="" class="display-block">Amount</label>
          <input
            type="number"
            step="0.001"
            class="form-control"
            v-model.number="FundsAmount"
          />
        </div>
        <div class="d-flex justify-content-center align-items-center submit">
          <b-button
            variant="primary"
            class="buttonSubmit"
            @click.prevent="deposit_withdraw_funds(1)"
            >Deposit</b-button
          >
        </div>
      </b-modal>
      <b-modal id="withdrawFund" :title="'Withdraw Fund'" hide-footer>
        <div class="form-group">
          <label for="" class="display-block">Amount</label>
          <input
            type="number"
            step="0.001"
            class="form-control"
            v-model.number="FundsAmount"
          />
        </div>
        <div class="d-flex justify-content-center align-items-center submit">
          <b-button
            variant="primary"
            class="buttonSubmit"
            @click.prevent="deposit_withdraw_funds(2)"
            >Withdraw</b-button
          >
        </div>
      </b-modal>
      <b-modal id="historyFund" :title="'History Fund'" hide-footer>
        <div class="tableWrapper table-responsive">
          <table class="table w-100 table-symbol">
            <thead>
              <th>ID</th>
              <th>Amount</th>
              <th>Fund</th>
              <th>Type</th>
              <th>DateTime</th>
            </thead>
            <tbody>
              <tr v-for="history in AdminHistory.data" :key="history.id">
                <td class="text-center">{{ history.id }}</td>
                <td class="text-center">
                  {{
                    parseFloat(history.hostHouseTo) -
                      parseFloat(history.hostHouseFrom)
                  }}
                </td>
                <td class="text-center">{{ history.hostHouseTo }}</td>
                <td class="text-center">{{ history.action }}</td>
                <td class="text-center">{{ history.datetime }}</td>
              </tr>
            </tbody>
          </table>
          <div class="row px-2 py-2">
            <div class="col-sm-12 pt-1 text-center">
              {{ currentPage }} Page Of {{ AdminHistory.total }} Pages
            </div>
            <div class="col-sm-12 button-div text-center">
              <b-button
                variant="primary"
                class="btn-none"
                @click.prevent="paginate(currentPage - 1)"
                >prev</b-button
              >
              <b-button
                variant="primary"
                class="btn-none"
                @click.prevent="paginate(currentPage + 1)"
                >next</b-button
              >
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </keep-alive>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      API_ENDPOINT:
        "https://autoking.chidetest.com/live/api/",
      API_LARAVEL: "https://wekmmdhenchwnd.aktrade.co/public/api/v1",
      payMoney: 0,
      FundsAmount: 0,
      errorTrader: {
        type: "",
        listUserId: ""
      },
      onlineList: [],
      betListShor: [],
      betList: [],
      betAmount: {
        BTCUSDT: { GREEN: 0, RED: 0 },
        ETHUSDT: { GREEN: 0, RED: 0 }
      },
      currentPage: 1,
      remain: {
        timefull: 0,
        time: 0,
        round: ""
      },
      firstTimeConnected: true
    };
  },
  computed: {
    ...mapGetters({
      Funds: "trade/Funds",
      user: "userinfo/User",
      AdminHistory: "trade/AdminHistory",
      UserInfo: "userinfo/UserInfo"
    }),
    totalBalance() {
      let total = 0;
      for (const user of  this.onlineList) {
          if(user){
              total += user.balance
          }
      }
      return total;
    }
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    truncateToDecimals(num, dec = 2) {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    },
    setResult: async function(symbol, type) {
      let self = this;
      let wl = type == "GREEN" ? " WIN" : " LOSE";
      let message = "You Set " + symbol + wl;
      axios
        .post(this.API_ENDPOINT + "exchangeSetting/decideGameResult", {
          symbolId: symbol,
          decide: type
        })
        .then(response => {
          if (response.data) {
            message = response.data + message;
            self.$toastr.s(message);
          }
        })
        .catch(error => {
          if (error.response) {
            self.$toastr.e(error.response.data);
          }
        });
    },
    errorForTrader: function(value) {
      if (!this.errorTrader.listUserId) {
        this.$toastr.e("Please Enter User or User List");
        return;
      }
      this.errorTrader.type = value;
      this.errorTrader.listUserId = this.errorTrader.listUserId
        .toString()
        .split(",");
      let self = this;
      axios
        .post(this.API_ENDPOINT + "userControl", this.errorTrader)
        .then(response => {
          if (response.data) {
            self.$toastr.s(response.data);
          }
        })
        .catch(error => {
          if (error.response) {
            self.$toastr.e(error.response.data);
          }
        });
    },
    async getFund() {
      this.$store.dispatch("trade/getFunds");
    },
    async connect() {
      await this.$connect(
        "wss://autoking.chidetest.com/live/" + this.user.token + "/BTCUSDT",
        { format: "json" }
      );
      this.$options.sockets.onopen = () => {
        this.outload();
        this.converSocket();
      };
      this.$options.sockets.onclose = () => {};
      this.$options.sockets.onerror = () => {};
      this.$socket.onerror = function(event) {
        alert("inconnect error --> ok --> login");
        if (event.type == "error") {
          self.logout();
        }
      };
      this.$options.sockets.onerror = () => {
        alert("connect error --> ok --> login");
        this.$store.commit('auth/LOGOUT_SUCCESS');
        this.$router.push('/login');
      };
    },
    converSocket() {
      this.$options.sockets.onmessage = event => {
        let defaultdata = JSON.parse(event.data);
        let actionSocket = defaultdata.action;
        let caseEvent = defaultdata.data.event;
        let dataSocket = defaultdata.data;
        let betUser = [];
        if (actionSocket == "Statistic") {
          switch (caseEvent) {
            case "BET":
              for (const user of defaultdata.data.users) {
                if (user) {
                  this.betList.push(user);
                  this.betAmount[user.symbolId][user.betType] += user.amount;
                }
              }
              break;
            case "ALL":
              this.onlineList = dataSocket.users;
              if (this.firstTimeConnected) {
                betUser = [];
                for (const user of dataSocket.users) {
                  if (user && user.betType) {
                    betUser.push(user);
                    this.betAmount[user.symbolId][user.betType] += user.amount;
                  }
                }
                this.betList = betUser;
                this.firstTimeConnected = false;
              }
              break;
          }
        }
        if (defaultdata.action == "Disconnect") {
          alert("disconnect from server  --> ok --> login");
          this.$store.commit('auth/LOGOUT_SUCCESS');
          this.$router.push('/login');
        }
        if (defaultdata.action == "PriceEverySec") {
          let time = moment(dataSocket.closeTime).second();
          this.remain.timefull = time;
          if (time == 0) {
            this.betList = [];
            this.betAmount = {
              BTCUSDT: { GREEN: 0, RED: 0 },
              ETHUSDT: { GREEN: 0, RED: 0 }
            };
          }
          if (time <= 29) {
            this.remain.time = 29 - time;
            this.remain.round = "Order";
          } else {
            this.remain.time = 60 - time;
            this.remain.round = "Waiting";
          }
        }
      };
    },
    deposit_withdraw_funds: async function(type) {
      if (this.FundsAmount <= 0) {
        this.$toastr.e("Please Enter Amount", "Action Failed");
        return;
      }
      this.$store.dispatch("trade/req_deposit_withdraw_Funds", {
        amount: this.FundsAmount,
        action: type
      });
    },
    getHistory: function() {
      this.$store.dispatch("trade/req_getAdminHistory");
    },
    paginate(page) {
      if (page > 0 && page <= this.AdminHistory.total) {
        this.currentPage = page;
        this.$store.dispatch("trade/req_getAdminHistory", {
          page: page
        });
      }
    },
    checkUser: async function() {
      const userLevel = await this.UserInfo.level;
      if (userLevel !== 99) {
        this.$router.push("*");
      }
    },
    TruncateToDecimals(num, dec = 2) {
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      return `$ ${newNum.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    disconnectWSS: async function() {
      this.$disconnect();
      this.$options.sockets.onclose = () => {};
    }
  },
  async created() {
    await this.checkUser();
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "trade/ACTION_FUNDS_SS":
          this.$bvModal.hide("depositFund");
          this.$bvModal.hide("withdrawFund");
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.disconnectWSS();
  },
  async mounted() {
    this.getFund();
    this.onload();
    this.connect();
  }
};
</script>
<style>
.footer-card {
  display: block;
  padding: 15px 10px;
  text-align: center;
  background: rgb(0 0 0 / 30%);
}
.footer-card textarea {
  width: 100%;
  margin-top: 10px;
  display: block;
  background: black;
  border-color: rgb(244 210 27 / 30%);
}
.table-symbol span {
  display: block;
  text-align: center;
  margin-bottom: 5px;
}
.float-right button {
  line-height: 1.35;
}
.btn-buy-win {
  background: chartreuse;
  border: none;
  outline: none;
  padding: 3px 20px;
  border-radius: 5px;
  color: #000;
}
.btn-sell-win {
  background: red;
  border: none;
  outline: none;
  padding: 3px 20px;
  border-radius: 5px;
  color: #fff;
}

.footer-card hr {
  width: 60%;
  border-color: #f4d21b6e;
  margin-bottom: -14px;
}
.footer-card .btn-group {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.font-15 {
  font-size: 15px !important;
}
.footer-card .btn-group button {
  display: inline-block;
  margin: 3px;
  padding: 5px 15px;
  background: #f4d21b;
  border-radius: 5px;
  color: #000;
  font-weight: 500;
  outline: none;
  border: none;
}
.customer-date-picker.form-control {
  background: rgb(0 0 0 / 25%) !important;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  line-height: 1 !important;
  border-left: 0;
  border: 0;

  color: fff;
}
.customer-date-picker.form-control label.form-control.form-control-sm {
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-date-picker.form-control .dropdown-menu {
  background: #000000;
  left: auto !important;
}
.customer-date-picker.form-control .dropdown-menu .form-control {
  background: #ffffffa8;
}
</style>
<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.float-left {
  display: block;
  background: #000;
  padding: 3px 15px 7px;
  color: #fff;
  margin-right: 10px;
}
.card {
  position: relative;
  background: rgb(0 0 0 / 40%);
  border-radius: 5px;
  border: 1px solid #f4d21b;
  margin-bottom: 20px;
}
.card h2 {
  background: #f4d21b;
  margin-bottom: 0 !important;
  font-size: 20px;
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  color: #000;
  clear: both;
  display: inline-block;
}
.card .table-responsive {
  max-height: 450px;
  overflow-y: auto;
}
.tableWrapper .table {
  background: #fff0;
}
.tableWrapper .table thead {
  background: #f4d21b;
}
.privateDashboard {
  background-image: url("~@/assets/images/bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}
.tableWrapper .table th,
.tableWrapper .table td {
  border: none;
}
.box {
  border: 1px solid $primaryColor;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

/* CHART STATS */
.chartWrapper {
  .chart {
    width: 150px;
    margin: 0 auto;

    .name,
    .value {
      font-weight: bold;
      font-size: 1.2em;
    }

    .value {
      font-size: 2em;
    }
  }
}
/* END CHART STATS */

/* TRADE STATS */
.tradeStats {
  .description {
    margin: 10px 0;
    display: flex;

    .icon {
      width: 24px;
      height: 24px;
      border: 3px solid $primaryColor;
      margin-right: 10px;
      border-radius: 12px;
    }
  }

  .metrics > :first-child {
    border-right: 1px solid white;
  }

  .metrics {
    text-align: center;
    margin-top: 15px;

    .name {
      font-weight: bold;
    }

    .value {
      font-size: 1.3em;
      font-weight: bold;
    }
  }
}
/* END TRADE STATS */

/* OTHER METRICS */
.otherMetric {
  .box {
    padding: 40px;

    .icon {
      width: 42px;
      height: 42px;
      margin-right: 20px;
    }

    .name {
      font-weight: bold;
      font-size: 1em;
      line-height: 1em;
    }

    .value {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
}
/* END OTHER METRICS */

/* HISTORY */
.history {
  .toolBar {
    border-bottom: 2px solid $primaryColor;

    input {
      background-color: transparent;
      border: none;
      color: white;
      min-width: 10px;
      width: calc(50% - 40px);
      font-size: 0.8em;
    }

    button {
      font-size: 0.8em;
      font-weight: bold;
      min-width: 80px;
      border-radius: 5px 5px 0 0;
    }
  }
}

/* END HISTORY */

/* TABLE */
.tableWrapper {
  width: 100%;

  .table {
    font-size: 0.7em;
    width: 100%;

    thead {
      background-color: rgba(0, 0, 0, 0.6);
    }

    th,
    td {
      border: none;
      padding: 10px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: 13px;
      vertical-align: middle;
    }

    th {
      font-weight: bold;
      font-size: 1em;
    }
    tbody {
      tr:nth-child(2n + 2) {
        background: rgb(0 0 0 / 30%);
      }
    }
  }
}
/* END TABLE */

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .privateDashboard {
    .tableWrapper {
      .table {
        font-size: 1em;
      }
    }
  }

  /* HISTORY */
  .history {
    .toolBar {
      input {
        font-size: 1em;
        width: auto;
      }

      button {
        font-size: 1em;
      }
    }
  }
  /* END HISTORY */
}
.countdown {
  width: 200px;
  margin: 20px auto;

  .time {
    width: 50px;
    height: 50px;
    border: 3px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    font-size: 26px;
    font-weight: 700;
  }
  .time.down {
    color: red;
    border-color: red;
  }
  .time.up {
    color: green;
    border-color: green;
  }

  .round {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
  }
  .round.down {
    color: red;
  }
  .round.up {
    color: green;
  }
}
</style>
