<template>
	<div class="PrivateProfile my-4 p-2">
		<!-- UPDATE ACCOUNT -->
		<b-container class="">
			<b-row>
				<b-col cols="12" md="4">
					<div class="mb-4 box p-3">
						<div class="avatarWrapper my-4">
							<div class="avatar text-center mb-3">
								<img
									v-b-modal.uploadImage
									v-lazy="require('@/assets/images/logo.svg')"
									alt="Profile"
									style="max-width: 180px; width: 90%"
								/>
							</div>
							<b-col cols="12">
								<b-row>
									<b-col cols="12" md="12">
										<div class="form-group text-center" v-if="UserInfo">
											<p class="mb-1">
												{{ $t('promotion_insurance.user_id') }}:
												{{ UserInfo.userId }}
											</p>
											<p>{{ $t('auth.email') }}: {{ UserInfo.email }}</p>
										</div>
									</b-col>
									<b-col cols="12" md="12" class="text-center">
										<b-button
											variant="outline-primary"
											class="my-1"
											v-b-modal.changePass
											>{{ $t('profile.change_password') }}</b-button
										>
										<b-button
											:variant="
												UserKYC.status == -1 || UserKYC.status == null
													? 'danger'
													: UserKYC.status == 1
													? 'success'
													: 'warning'
											"
											class="outline-primary ml-1 my-1 btn"
										>
											{{
												UserKYC.status == -1 || UserKYC.status == null
													? $t('profile.unverified_account')
													: UserKYC.status == 1
													? $t('profile.verified_account')
													: $t('profile.account_verification_approval')
											}}
										</b-button>
									</b-col>
								</b-row>
							</b-col>
						</div>
					</div>
					<div class="mb-4 box p-3">
						<div class="avatarWrapper my-4">
							<div class="avatar text-center mb-3">
								<img
									v-b-modal.uploadImage
									v-lazy="require('@/assets/images/authentic.png')"
									alt="Profile"
									width="60"
								/>
							</div>
							<b-col cols="12">
								<b-row>
									<b-col cols="12" md="12">
										<div class="form-group text-center" v-if="UserInfo">
											<p>
												{{ $t('profile.google_authentication') }}
											</p>
										</div>
									</b-col>
									<b-col cols="12" md="12" class="text-center">
										<b-button
											:variant="UserAuth.enable ? 'danger' : 'outline-primary'"
											v-b-modal.authi
											>{{
												UserAuth.enable
													? $t('profile.disable_auth')
													: $t('profile.enable_auth')
											}}</b-button
										>
									</b-col>
								</b-row>
							</b-col>
						</div>
					</div>
				</b-col>
				<b-col cols="12" md="8">
					<div class="mb-4 box p-3" id="uploadImage">
						<div class="form-group">
							<label for="">{{ $t('profile.id_passport_number') }}</label>
							<input
								v-if="UserKYC.status !== -1"
								type="text"
								v-model="UserKYC.passport_id"
								class="form-control"
								readonly
								style="background: transparent"
							/>
							<input
								v-else
								type="text"
								v-model="passport"
								class="form-control"
							/>
						</div>
						<div class="form-group">
							<div class="imgInfo">
								<p class="text-left">
									<span class="fileSize">{{
										$t('profile.id_passport_image')
									}}</span
									><br />
									<span class="fileSize text-warning"
										>*{{
											$t(
												'profile.make_sure_the_image_is_full_and_clear_and_the_format_is_jpg'
											)
										}}</span
									><br />
									<span class="fileSize text-warning"
										>*{{
											$t('profile.please_use_image_up_to_maximum_size')
										}}</span
									>
								</p>
							</div>
							<div
								class="avatarEmpty text-center"
								v-if="!passport_image && UserKYC.status == -1"
							>
								<input
									@change="onFileChange($event, 0)"
									type="file"
									accept=".jpg,.jpeg,.png"
									class="fileUpload"
								/>
								<svg
									data-v-de2bfc3e=""
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									viewBox="0 0 89.156 95.1"
								>
									<g
										data-v-de2bfc3e=""
										id="upload-and-send"
										transform="translate(0 0)"
										opacity="0.3"
									>
										<path
											data-v-de2bfc3e=""
											id="Path_34910"
											data-name="Path 34910"
											d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
											transform="translate(17.306 0)"
											fill="#fefefe"
										></path>
										<path
											data-v-de2bfc3e=""
											id="Path_34911"
											data-name="Path 34911"
											d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
											transform="translate(0 54.381)"
											fill="#fefefe"
										></path>
									</g>
								</svg>
								<p data-v-de2bfc3e="">
									{{ $t('profile.drag_and_drop_a_file_here_or_click') }}
								</p>
							</div>
							<div v-else class="avatarEmpty show-img">
								<img
									v-lazy="
										UserKYC.status !== -1
											? UserKYC.passport_image
											: passport_image
									"
									width="225"
								/>
								<button
									v-if="UserKYC.status == -1"
									class="button removi-img block bg-theme-1 text-white"
									@click="removeImage(0)"
								>
									{{ $t('profile.remove') }}
								</button>
							</div>
						</div>

						<div class="form-group">
							<div class="imgInfo">
								<p class="text-left">
									<span class="fileSize">
										{{ $t('profile.id_passport_image_with_selfie') }}
									</span>
									<br />
									<span class="fileSize text-warning">
										* {{ $t('profile.your_face') }}.</span
									><br />
									<span class="fileSize text-warning">
										* {{ $t('profile.your_id_passport') }}.</span
									><br />
									<span class="fileSize text-warning">
										*
										{{
											$t(
												'profile.make_sure_the_image_is_full_and_clear_and_the_format_is_jpg'
											)
										}}</span
									>
									<br />
									<span class="fileSize text-warning">
										*{{
											$t('profile.please_use_image_up_to_maximum_size')
										}}</span
									>
								</p>
							</div>
							<div
								class="avatarEmpty text-center"
								v-if="!passport_image_selfie && UserKYC.status == -1"
							>
								<input
									@change="onFileChange($event, 1)"
									type="file"
									accept=".jpg,.jpeg,.png"
									class="fileUpload"
								/>
								<svg
									data-v-de2bfc3e=""
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									viewBox="0 0 89.156 95.1"
								>
									<g
										data-v-de2bfc3e=""
										id="upload-and-send"
										transform="translate(0 0)"
										opacity="0.3"
									>
										<path
											data-v-de2bfc3e=""
											id="Path_34910"
											data-name="Path 34910"
											d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
											transform="translate(17.306 0)"
											fill="#fefefe"
										></path>
										<path
											data-v-de2bfc3e=""
											id="Path_34911"
											data-name="Path 34911"
											d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
											transform="translate(0 54.381)"
											fill="#fefefe"
										></path>
									</g>
								</svg>
								<p data-v-de2bfc3e="">
									{{ $t('profile.drag_and_drop_a_file_here_or_click') }}
								</p>
							</div>
							<div v-else class="avatarEmpty show-img">
								<img
									v-lazy="
										UserKYC.status !== -1
											? UserKYC.passport_image_selfie
											: passport_image_selfie
									"
									width="225"
								/>
								<button
									v-if="UserKYC.status == -1"
									class="button removi-img block bg-theme-1 text-white"
									@click="removeImage(1)"
								>
									{{ $t('profile.remove') }}
								</button>
							</div>
						</div>
						<div
							class="d-flex justify-content-center align-items-center submit"
						>
							<b-button
								variant="primary"
								v-if="UserKYC.status == -1"
								:disabled="
									!passport_image || !passport_image_selfie || !passport
								"
								class="buttonSubmit btn-disable"
								@click.prevent="uploadImage()"
								>{{ $t('profile.save') }}</b-button
							>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<!-- END UPDATE ACCOUNT -->
		<b-modal
			ref="changePass"
			id="changePass"
			title="Change Password"
			hide-footer
		>
			<form @submit.prevent="onChangePass()">
				<div class="form-group">
					<label for="">{{ $t('profile.current_password') }}</label>
					<div class="d-flex">
						<input
							:type="showPass[1] ? 'text' : 'password'"
							v-model="changePass.User_Password"
							class="form-control"
							required
						/>
						<span
							class="btm-s-h"
							v-show="showPass[1]"
							@click="showPass[1] = !showPass[1]"
						>
							<i class="fas fa-eye"></i>
						</span>
						<span
							class="btm-s-h"
							v-show="!showPass[1]"
							@click="showPass[1] = !showPass[1]"
						>
							<i class="fas fa-eye-slash"></i>
						</span>
					</div>
				</div>
				<div class="form-group">
					<label for="">{{ $t('profile.new_password') }}</label>
					<div class="d-flex">
						<input
							:type="showPass[2] ? 'text' : 'password'"
							v-model="changePass.User_New_Password"
							class="form-control"
							required
						/>
						<span
							class="btm-s-h"
							v-show="showPass[2]"
							@click="showPass[2] = !showPass[2]"
						>
							<i class="fas fa-eye"></i>
						</span>
						<span
							class="btm-s-h"
							v-show="!showPass[2]"
							@click="showPass[2] = !showPass[2]"
						>
							<i class="fas fa-eye-slash"></i>
						</span>
					</div>
					<div class="d-flex flex-column justify-content-center text-center">
						<small
							v-if="score < 1 && changePass.User_New_Password"
							class="mt-1 text-warning"
							>You Need Change Stong Password</small
						>
						<small
							v-if="score > 1 && score < 2 && changePass.User_New_Password"
							class="mt-1 text-warning"
							>I Think You Needed Stong Password</small
						>
						<Password
							v-model="changePass.User_New_Password"
							:strength-meter-only="true"
							:toggle="true"
							@score="showScore"
							class="w-100"
						/>
					</div>
				</div>
				<div class="form-group">
					<label for="">{{ $t('profile.password_confirm') }}</label>

					<div class="d-flex">
						<input
							:type="showPass[3] ? 'text' : 'password'"
							v-model="changePass.User_Re_New_Password"
							class="form-control"
							required
						/>
						<span
							class="btm-s-h"
							v-show="showPass[3]"
							@click="showPass[3] = !showPass[3]"
						>
							<i class="fas fa-eye"></i>
						</span>
						<span
							class="btm-s-h"
							v-show="!showPass[3]"
							@click="showPass[3] = !showPass[3]"
						>
							<i class="fas fa-eye-slash"></i>
						</span>
					</div>
				</div>
				<div class="d-flex justify-content-center align-items-center submit">
					<b-button
						:disabled="score == null"
						variant="primary"
						type="submit"
						class="buttonSubmit"
						>{{ $t('profile.save') }}</b-button
					>
				</div>
			</form>
		</b-modal>
		<b-modal
			id="authi"
			ref="changeAuth"
			:title="
				UserAuth.enable ? $t('profile.enable_auth') : $t('profile.disable_auth')
			"
			hide-footer
		>
			<form @submit.prevent="changAuth()">
				<div class="form-group text-center" v-if="!UserAuth.enable">
					<label for=""
						>{{ $t('profile.authenticator_secret_code') }}:
						<span>{{ UserAuth.secret }}</span></label
					>
				</div>
				<div class="form-group text-center" v-if="!UserAuth.enable">
					<img v-lazy="UserAuth.qr" width="300" class="mx-auto" />
				</div>
				<div class="form-group">
					<label for="">{{
						$t(
							'profile.enter_the_verification_code_provided_by_your_authentication_app'
						)
					}}</label>
					<input type="text" v-model="authCode" class="form-control" />
				</div>
				<div class="d-flex justify-content-center align-items-center submit">
					<b-button
						variant="primary"
						:disabled="!authCode"
						type="submit"
						class="buttonSubmit btn-disable"
						>{{ $t('profile.save') }}</b-button
					>
				</div>
			</form>
		</b-modal>
		<!-- END SECURITY -->
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
	components: { Password: () => import('vue-password-strength-meter') },
	data: () => ({
		reader: new FileReader(),
		passport_image: '',
		passport_image_selfie: '',
		passport: '',
		kyc: {
			passport_image: '',
			passport_image_selfie: '',
			passport: '',
		},
		authCode: '',
		score: null,
		changePass: {
			User_Password: '',
			User_New_Password: '',
			User_Re_New_Password: '',
		},
		showPass: {
			1: true,
			2: true,
			3: true,
		},
	}),
	computed: {
		...mapGetters({
			UserInfo: 'userinfo/UserInfo',
			UserAuth: 'userinfo/UserAuth',
			UserKYC: 'userinfo/UserKYC',
		}),
	},
	created() {
		this.$store.dispatch('userinfo/req_getAuth');
		this.$store.dispatch('userinfo/req_getKYC');
		this.unsubscribe = this.$store.subscribe((mutation) => {
			switch (mutation.type) {
				case 'userinfo/CHANGE_PASSWORD_SUCCESS':
					this.$refs['changePass'].hide();
					break;
				case 'userinfo/CHANGE_AUTH_SUCCESS':
					this.$refs['changeAuth'].hide();
					break;
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	},
	methods: {
		...mapMutations(['onload', 'outload']),

		onFileChange(e, type) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;

			this.reader.onload = (e) => {
				if (type == 0) {
					this.passport_image = e.target.result;
					this.kyc.passport_image = files[0];
				} else {
					this.passport_image_selfie = e.target.result;
					this.kyc.passport_image_selfie = files[0];
				}
			};
			this.reader.readAsDataURL(files[0]);
		},
		uploadImage() {
			this.kyc.passport = this.passport;
			this.$store.dispatch('userinfo/req_postKYC', this.kyc);
		},
		removeImage(type) {
			if (type == 0) {
				this.passport_image = '';
			} else {
				this.passport_image_selfie = '';
			}
		},
		showScore(score) {
			this.score = score;
		},
		onChangePass() {
			if (this.changePass.User_Password == this.changePass.User_New_Password) {
				this.$toastr.e(
					"Please Don't Use New Password Like Old Password",
					'Change Password Failed'
				);
			} else {
				this.$store.dispatch(
					'userinfo/req_postChangePassword',
					this.changePass
				);
			}
		},
		changAuth() {
			this.$store.dispatch('userinfo/req_postAuth', {
				authCode: this.authCode,
				secret: this.UserAuth.secret,
			});
		},
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';
.backgroundLive *,
.box * {
	z-index: 2;
	position: relative;
}
.avatarEmpty {
	width: 100%;
}
.avatarEmpty img {
	width: 100%;
}
.btn-success {
	background: green;
}
.btn-warning {
	background: yellow;
}
.btn-danger {
	background: red;
}
.box {
	min-height: 150px;
	background: rgb(35 31 32 / 0.6);
	margin: 0 2px;
	border: 1px solid #00ff85;
	width: 100%;
	border-radius: 15px;
	padding: 20px;
}
.close {
	color: #fff;
}
.removi-img {
	position: absolute;
	top: calc(50% - 15px);
	left: 0;
	right: 0;
	margin: auto;
	background: #00ff85;
	border: 1px #00ff85 solid;
	border-radius: 5px;
	padding: 7px 20px;
	opacity: 0;
	z-index: 2000;
	transition: 0.3s;
	transform: translateY(100%);
	color: #000 !important;
}
.show-img:hover .removi-img {
	transform: translateY(0);
	opacity: 1;
}
.show-img {
	padding: 10px !important;
	height: auto !important;
	position: relative !important;
}
.show-img img {
	position: relative;
	z-index: 1090;
	max-width: 225px;
}
.show-img::after {
	background: rgb(0, 0, 0, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	border-radius: 10px;
	height: 100%;
	z-index: 2;
	content: '';
}
svg {
	font-size: 30px;
}
#uploadImage {
	.avatarEmpty {
		background: #1111111f;
		padding: 45px 0;
		color: #424242;
		border-radius: 10px;
		height: 180px;
		margin-bottom: 23px;
		width: 100%;
		position: relative;
		cursor: pointer;
		text-align: center;

		.fileUpload {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 22;
			outline: none;
			opacity: 0;
		}

		p {
			font-size: 20px;
			font-weight: bold;
			margin-top: 20px;
			margin-bottom: 0;
		}
	}

	.imgInfo {
		text-align: center;
		margin-bottom: 20px;

		.colorGreen {
			color: #25e69c;
			margin: 0 10px;
		}
	}
}
.PrivateProfile {
	min-height: 100vh;
	margin-top: 0 !important;
	padding-top: 1.5rem !important;
}
@media (min-width: 768px) {
	.PrivateProfile {
		background-image: url('~@/assets/images/background/bg.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 100vh;
	}
}
@media (max-width: 768px) {
	.PrivateProfile {
		background-image: url('~@/assets/images/background/bg.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 100vh;
	}
}
.btm-s-h {
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	align-items: center;
	border: 1px solid #00ff85;
	height: calc(1.5em + 0.75rem + 2px);
	border-radius: 5px;
	padding: 2px;
	cursor: pointer;
}
</style>