<template>
	<router-link
		:to="link || '#'"
		:class="`navLink btn btn-none ${name} ${isActive ? 'active' : ''}`"
		@click.native="$emit('click')"
        :key="icon + name + langOB"
	>
		<i v-if="icon" :class="icon" style="font-size: 35px;"></i>
		<img v-else class="toolIcon" v-lazy="navIcon" />
		<div class="toolText">
			{{ $t(`${langOB}.${name}`) }}
		</div>
	</router-link>
</template>

<script>
export default {
	props: ['name', 'link', 'langOB', 'img', 'icon'],
	data() {
		return {
			isActive: false,
		};
	},
	computed: {
		navIcon() {
			var images = require.context('@/assets/images/icons/', false, /\.svg$/);
			return images('./' + this.img + '.svg');
		},
	},
	methods: {
		checkActiveRoute(path) {
			this.isActive = path === this.link;
		},
	},
	mounted() {
		this.checkActiveRoute(this.$router.currentRoute.path);
	},
	watch: {
		$route(to) {
			this.checkActiveRoute(to.path);
		},
	},
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';

// secondary tools
.navLink {
	margin: 0 4px;

	.toolIcon {
		width: auto;
		height: 28px;
	}

	.toolText {
		font-size: 0.6em;
		margin-top: 3px;
		font-weight: bold;
	}
}

.navLink.active,
.navLink:hover {
	.toolIcon {
		filter: brightness(0) saturate(1099%) invert(71%) sepia(88%) saturate(3112%) hue-rotate(90deg) brightness(102%) contrast(105%);
	}

	.toolText {
		color: $primaryColor;
	}
}
.navLink.profile {
	padding: 0;
	width: 100px !important;
	height: 50px !important;
	margin-top: 5px;
	border: 0px #00ff85 solid !important;
	border-radius: 10px;
}
.navLink.profile img {
	width: 30px;
	height: auto;
}
.btn {
	padding: 0.375rem 0.5rem;
}
</style>