const axios = require("../config/axios.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        user_info: {},
        user: {},
        user_balance: {},
        user_auth: {},
        user_kyc: {},
        user_transfers: {
            class: null,
            message: null
        },
    }),

    getters: {
        UserInfo: (state) => { return state.user_info },
        UserAuth: (state) => { return state.user_auth },
        UserKYC: (state) => { return state.user_kyc },
        User: (state) => { return state.user },
        UserTransfers: (state) => { return state.user_transfers }
    },

    actions: {

        req_getInfo: function ({ commit, dispatch }) {
            axios.get("auth/get-user-info").then(function (response) {
                if (response) {
                    commit("SET_USER_INFO", response);
                    dispatch("req_getBalance");
                }
            });
        },
        req_getBalance: function ({ commit }) {
            axios.get("auth/balance").then(function (data) {
                commit("setBalace", {
                    main: true,
                    balance: data.MainBalance,
                })
                commit("setBalace", {
                    live: true,
                    balance: data.TradeBalance,
                })

                commit("setBalace", {
                    demo: true,
                    balance: data.TradeDemoBalance,
                })
            });
        },
        req_getAuth: function ({ commit }) {
            axios.get("profile/get-auth").then(function (response) {
                commit("SET_USER_AUTH", response)
            })
        },
        req_postAuth: function ({ dispatch, commit }, input) {
            axios.post("profile/confirm-auth", input).then(function (response) {
                if (response) {
                    commit("CHANGE_AUTH_SUCCESS")
                    dispatch("req_getAuth");
                }
            })
        },
        req_getKYC: function ({ commit }) {
            axios.get("profile/get-kyc").then(function (response) {
                commit("SET_USER_KYC", response)
            })
        },
        req_postKYC: function ({ commit }, input) {
            let formData = new FormData();
            formData.append('passport', input.passport);
            formData.append('passport_image', input.passport_image);
            formData.append('passport_image_selfie', input.passport_image_selfie);

            axios
                .post("profile/upload-kyc", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                    if (response !== null && response !== undefined) { commit('UPLOAD_KYC_SUCCESS', response); }
                })

        },
        req_postChangePassword: function ({ commit }, input) {
            axios.post("profile/change-password", input).then(function (response) {
                if (response) {
                    commit("CHANGE_PASSWORD_SUCCESS")
                }
            })
        },
        req_getUserTransfer: function ({ commit }, input) {
            axios.get("email-user", {
                params: input
            }).then((response) => {
                if (response) {
                    commit('GET_USER_TRANSFER', response)
                }
            })
        }
    },

    mutations: {
        setUser: function (state, data) {
            state.user = data;
        },
        saveInfo(state) {
            var min = ((state.user.time[0] - new Date().getHours()) * 60) + state.user.time[1] - new Date().getMinutes();
            window.$cookies.set("wx_user", JSON.stringify(state.user), min * 60);
        },
        setBalace(state, account) {

            if (account.demo) {
                state.user.accounts[0].balance = account.balance;
            }
            if (account.live) {
                state.user.accounts[1].balance = account.balance;
            }
            if (account.main) {
                state.user.walletList[0].balance = account.balance;
            }
            this.commit("userinfo/saveInfo");
        },
        SET_USER_INFO: function (state, user) {
            state.user_info = user.user;
            window.$cookies.set("wx_info", JSON.stringify(state.user_info), "2H");
        },
        SET_USER_BALANCE: function (state, balance) {
            state.user_balance = balance;
        },
        GET_USER_INFO: function (state, user) {
            state.user_info = user;
        },
        SET_USER_AUTH: function (state, data) {
            state.user_auth = data;
        },
        SET_USER_KYC: function (state, data) {
            state.user_kyc = data;
        },
        CHANGE_PASSWORD_SUCCESS: function () { },
        CHANGE_AUTH_SUCCESS: function () { },
        UPLOAD_KYC_SUCCESS: function (state, data) {
            if (data.check_kyc !== null && data.check_kyc !== undefined) {
                state.user_kyc = data.check_kyc;
            }
        },
        GET_USER_TRANSFER: function (state, data) {
            state.user_transfers.class = data.class;
            state.user_transfers.message = data.message;
        }
    }
};
