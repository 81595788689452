<template>
  <div class="landing">
    <div class="content">
      <!-- Section 1 -->
      <section class="section-1" id="home" ref="home">
        <img class="logo" src="~@/assets/images/logoAK.png" />
        <b-container>
          <div>
            <div>
              <div>
                <div>
                  <img
                    src="~@/assets/images/mockup/line.png"
                    class="mi-4 line"
                  />
                </div>
                <div class="content">
                  <img
                    src="~@/assets/images/mockup/banner.png"
                    class="banner"
                  />
                  <div class="title">
                    <p>THE TRADING PLATFORM CAN GENERATE</p>
                    <p>UNLIMITED INCOME</p>
                  </div>
                </div>
                <div style="padding-top:60px">
                  <a href="/login">
                    <img src="~@/assets/images/mockup/btn-trade.png" style="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <!-- END SECTION 1 -->

      <!-- Section 2 -->
      <section class="section-2" id="s2">
        <b-container>
          <div class="title">
            <div class="section-title">
              <p>THE FUTURE CRYPTOCURRENCY MARKET EXPLOSION</p>
            </div>
            <p class="sub-title">
              The cryptocurrency market is a very potential market and worth
              investing in
            </p>
          </div>
          <div class="time-line">
            <img
              src="~@/assets/images/mockup/timeline.png"
              alt=""
              style="max-width:100%;padding-top: 50px;"
            />
          </div>
        </b-container>
      </section>
      <!-- END SECTION 2 -->

      <!-- Section 3 -->
      <section class="section-3" style="padding-top:60px">
        <b-container class="box-section-3 p-3 col-12">
          <div class="row">
            <div class="col-lg-8 col-md-12 section-title">
              <div class="section-title col-8">ABOUT US</div>
              <div class="text-white p col-8 sub-title">
                <p class="sub-title"><i>Company name:</i></p>
                <p>BITGRAND INVESTMENT COMPANY</p>
                <p class="sub-title">Address:</p>
                <p>65 Kingsway, Manchester. UK</p>

                <p class="sub-title">Established time:</p>
                <p>March 27, 2017</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div>
                <img
                  class="company"
                  src="~@/assets/images/mockup/company.jpg"
                />
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <!-- END SECTION 3 -->

      <!-- Section 4 -->
      <section class="section-4">
        <b-container>
          <div style="padding-top: 100px">
            <p class="section-title">
              <b>AUTOKING TRADE</b>
            </p>
            <p class="section-sub-title">
              THE TRADING PLATFORM CAN GENERATE UNLIMITED INCOME
            </p>
          </div>
          <b-container>
            <div class="row" style="padding-bottom: 50px;">
              <div class="col-lg-6 col-md-12">
                <img
                  style="width:100%"
                  src="~@/assets/images/mockup/auto-trade.png"
                />
              </div>
              <div class="col-lg-6 col-md-12 sub-title">
                <div>
                  <p style="text-align:left;font-size:25px">
                    <span style="color:#00FF85"><b>AUTOKING TRADE</b></span>
                    is a blockchain-based cryptocurrency trading platform.
                  </p>
                  <p style="text-align:left;font-size:25px">
                    <span style="color:#00FF85"><b>AUTOKING TRADE</b></span>
                    allows investors to earn profits by forecasting whether the
                    rate of a cryptocurrency will rise or fall over a certain
                    period of time.
                  </p>
                </div>
              </div>
              <div class="btn-img">
                <a href="/login">
                  <img
                    class="btn-img"
                    src="~@/assets/images/mockup/btn-join.png"
                    style="cursor: pointer;"
                  />
                </a>
              </div>
            </div>
          </b-container>
        </b-container>
      </section>
      <!-- END SECTION 4 -->

      <!-- Section 5 -->
      <section class="section-5">
        <b-container>
          <div class="row">
            <div class="col-lg-6 col-md-12 section-title">
              <p>VIDEO TRAILER</p>
            </div>
            <div class="col-lg-6 col-md-12" >
              <iframe  width="100%" height="400" src="https://www.youtube.com/embed/oWOtpXmH1hA" 
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </b-container>
      </section>
      <!-- END SECTION 5 -->

      <!-- Section 6 -->
      <section class="section-6">
        <b-container>
          <div
            class="section-title"
            style="padding-top:60px,padding-bottom:20px,font-family: 'Montserrat'"
          >
            OUTSTANDING ADVANCE TAGES OF AUTOKING TRADE
          </div>
          <div class="area">
            <div class="inner">
              <div class="wrapper">
                <b-container style="padding-top:8%">
                  <div class="row">
                    <div class="col-md-3 col-lg-3">
                      <div class="img-container">
                        <img
                          style="padding-top: 20px"
                          src="~@/assets/images/mockup/point1.png"
                          alt=""
                          class="img w-60"
                        />
                      </div>
                      <p class="text-dark" style="font-family: 'Montserrat'">
                        <i
                          ><b>Ensuring transparency</b> and anonymity for investors
                          thanks to the application off <b>Blockchain technology</b></i
                        >
                      </p>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="img-container">
                        <img
                          style="padding-top: 20px"
                          src="~@/assets/images/mockup/point2.png"
                          alt=""
                          class="img w-60"
                        />
                      </div>
                      <p class="text-dark" style="font-family: 'Montserrat'">
                        <i
                          ><b>Ensuring Anonymity</b> and account information for
                          investors</i
                        >
                      </p>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="img-container">
                        <img
                          style="padding-top: 20px"
                          src="~@/assets/images/mockup/point3.png"
                          alt=""
                          class="img w-60"
                        />
                      </div>
                      <p class="text-dark" style="font-family: 'Montserrat'">
                        <i>The only <b>platform neutral</b> to the external market.</i>
                      </p>
                    </div>
                    <div class="col-md-3 col-lg-3">
                      <div class="img-container">
                        <img
                          style="padding-top: 20px"
                          src="~@/assets/images/mockup/point4.png"
                          alt=""
                          class="img w-60"
                        />
                      </div>
                      <p class="text-dark" style="font-family: 'Montserrat'">
                        <i><b>Easy access</b> and <b>instant trading</b> for beginners and
                          lack of investment knowledge</i
                        >
                      </p>
                    </div>
                  </div>
                </b-container>
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <!-- END SECTION 6 -->

      <!-- Section 7 -->
      <section class="section-7">
        <b-container>
          <div class="section-title" style="font-family: 'Montserrat'">
            WHY CHOOSE AUTOKING TRADE ?
          </div>
          <div class="grid-box" style="padding-top: 50px;">
            <div class="first">
              <img
                src="~@/assets/images/mockup/Asset 2.png"
                style="height: 100%;"
              />
            </div>
            <div><img src="~@/assets/images/mockup/Asset 3.png" /></div>
            <div><img src="~@/assets/images/mockup/Asset 4.png" /></div>
            <div><img src="~@/assets/images/mockup/Asset 5.png" /></div>
            <div><img src="~@/assets/images/mockup/Asset 6.png" /></div>
            <div><img src="~@/assets/images/mockup/Asset 7.png" /></div>
            <div><img src="~@/assets/images/mockup/Asset 8.png" /></div>
          </div>
        </b-container>
      </section>
      <!-- END SECTION 7 -->

      <!-- FOOTER -->
      <section class="footer pt-60 pb-60">
        <div class="container text-left text-white">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <h2>
                ABOUT US
              </h2>
              <p style="font-size:15px" class="menu">
                <i>
                  AK Trade - Is a cryptocurrency trading platform that generates unlimited income. Developed by the leading company in the field of Blockchain technology
                </i>
              </p>
            </div>
            <div class="col-sm-12 col-md-4" style="padding-left: 5%">
              <h2 class="font-weight-bold">MENU</h2>
              <ul class="menu" style="list-style: none">
                <li class="font-weight-bold">
                  <a href="#s2"
                    >Future Cryptocurrency Market Explosion
                  </a>
                </li>
                <li class="font-weight-bold">
                  <a href="#">Back to comity</a>
                </li>
                <li class="font-weight-bold">
                  <a href="#"
                    >AK trade - The trading platform can generate unlimited income</a
                  >
                </li>
                <li class="font-weight-bold">
                  <a href="#">Outstanding advantages of Autoking trade</a>
                </li>
                <li class="font-weight-bold">
                  <a href="#">Why choose Autoking Trade? </a>
                </li>
                <li class="font-weight-bold">                  
                  <a href="/AUTOKING TRADE PROPOSAL.pdf" target="_blank">Documents </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4 wow" style="padding-left: 10%">
              <h2 class="font-weight-bold">SOCIAL CHANNELS:</h2>
              <ul class="social menu" style="list-style: none">
                <li>
                  <a href=""><img src="~@/assets/images/mockup/fb.png"/></a>
                </li>
                <li>
                  <a href=""><img src="~@/assets/images/mockup/tele.png"/></a>
                </li>
                <li>
                  <a href=""><img src="~@/assets/images/mockup/yt.png"/></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/landing.scss";
</style>
