<template>
  <div class="floatPanel" v-if="value">
    <div class="mainBox">
      <slot/>
    </div>
    <div class="mainBoxBg" @click="$emit('input', false)"></div>
  </div>
</template>

<script>
export default {
  props: [
    'value'
  ]
}
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';


.mainBoxBg {
  // width: 100%;
  // height: 100%;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-top: 10px;
  margin-right: 10px;
  top: 0;
  right: 0;
  position: fixed;
  background-color: #424242;
  z-index: 3001;
  cursor: pointer;
}

.mainBoxBg:after {
  background-image: url('~@/assets/images/icons/close.svg');
  background-size: 40%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  @include whiteFilter();
}

.mainBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background-color: black;
  text-align: left;
  height: 100vh;
  width: 100%;
  padding: 20px;
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px){
  .mainBox {
    width: 300px;
    height: auto;
    left: auto;
    border-radius: 10px;
    border: 1px solid $primaryColor;
  }

  .mainBoxBg {
    border-radius: 0;
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 2999;
    cursor: initial;
  }

  .mainBoxBg:after {
    display: none;
  }
}
</style>