const axios = require("../config/axios.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        userID: "",
        token: "",
        balance: [{ main: 0 }, { trade: 0 }, { demo: 0 }]
    }),

    getters: {},

    actions: {
        req_PostLogin: function ({ commit, dispatch }, input) {
            axios.post("login", input).then(function (response) {
                if (response) {
                    if (response.require_auth) {
                        commit("REQUIRE_AUTH");
                    } else {
                        commit("LOGIN_SUCCESS", response);
                        dispatch("userinfo/req_getInfo", null, { root: true });
                        // dispatch("UserInfo/req_getBalance",null,{root:true});
                    }
                }
            });
        },
        req_PostRegister: function ({ commit }, input) {
            axios.post("register", input).then(function (response) {
                commit("REGISTER_SUCCESS", response);
            });
        },
        req_PostForgot: function ({ commit }, input) {
            axios.post("forgot-password", input).then(function (response) {
                commit("FORGOT_SUCCESS", response);
            });
        },
        req_SendMail: function ({ commit }, input) {
            axios.post("resend-mail", input).then(function (response) {
                commit("RESEND_SUCCESS", response);
            });
        }
    },

    mutations: {
        LOGIN_SUCCESS: function (state, data) {
            state.userID = data.userID;
            state.token = data.token;
            state.balance.main = data.MainBalance;
            state.balance.trade = data.TradeBalance;
            state.balance.demo = data.TradeDemoBalance;
            var user = {
                name: "Demo User",
                user: state.userID,
                token: state.token,
                time: [new Date().getHours() + 2, new Date().getMinutes()],
                walletList: [
                    { id: 1, name: "Main Balance", balance: state.balance.main }
                ],
                accounts: [
                    {
                        id: 1,
                        name: "DEMO Account",
                        balance: state.balance.demo,
                    },
                    {
                        id: 2,
                        name: "Live Account",
                        balance: state.balance.trade,
                    }
                ]
            };
            this.commit("userinfo/setUser", user);
            axios.defaults.headers.common["Authorization"] = "Bearer " + data.Bearer;

            window.$cookies.set("user_session", { key: data.Bearer }, "2H");
            window.$cookies.set("wx_user", JSON.stringify(user), "2H");
        },
        LOGOUT_SUCCESS: function (state) {
            this.state.userinfo.user = null;
            state.currentAccountId = null;
            window.$cookies.remove("wx_user");
            window.$cookies.remove("wx_info");
            window.$cookies.remove("user_session");

        },
        REGISTER_SUCCESS: function () { },
        FORGOT_SUCCESS: function () { },
        RESEND_SUCCESS: function () { },
        REQUIRE_AUTH: function () { }
    }
};
