<template>
  <div class="privateDashboard py-3">
    <b-container fluid="xl">
      <b-row class="mb-4">
        <b-col cols="12" xl="6" class="mt-4">
          <!-- TRADE STATS -->
          <div class="tradeStats box">
            <!-- <h3 class="mb-3 position-relative">
							{{ $t('dashboard.tradeStats') }}
						</h3> -->
            <!-- CHART -->
            <b-row class="chartWrapper mb-4">
              <b-col cols="12" md="6">
                <!-- For more information about vc-donut: https://www.npmjs.com/package/vue-css-donut-chart -->
                <vc-donut
                  class="chart"
                  background="#111315"
                  size.Number="150"
                  :sections="sections"
                >
                  <div class="name">{{ $t("dashboard.total_trade") }}</div>
                  <div class="value">{{ Statistics.total }}</div>
                </vc-donut>
              </b-col>
              <b-col cols="12" md="6" class="align-self-center">
                <div
                  class="description"
                  v-for="section in sections"
                  :key="`desc-${section.color}`"
                >
                  <div
                    class="icon"
                    :style="`border-color: ${section.color}`"
                  ></div>
                  <div class="label">
                    {{ $t(`dashboard.${section.label}`) }} ({{
                      ((section.value / 100) * Statistics.total).toFixed(0)
                    }})
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- END CHART -->

            <!-- METRIC -->
            <b-row class="metrics">
              <b-col class="winRate">
                <div class="name position-relative">
                  {{ $t("dashboard.win_rate") }}
                </div>
                <div class="value">
                  {{ Math.floor(Statistics.percent_win) }}%
                </div>
              </b-col>
              <b-col class="totalTransaction">
                <div class="name position-relative">
                  {{ $t("dashboard.total_trade") }}
                </div>
                <div class="value">
                  {{ Statistics.total }}
                </div>
              </b-col>
            </b-row>
            <!-- END METRIC -->
          </div>
          <!-- END TRADE STATS -->
        </b-col>
        <b-col cols="12" xl="6">
          <!-- SUMMARY -->
          <div class="otherMetric h-100">
            <b-row class="h-100">
              <b-col cols="12">
                <b-row>
                  <b-col cols="12" sm="6" class="mt-4">
                    <!-- PROFIT -->
                    <div class="profit card-dashboard box d-flex">
                      <div class="icon">
                        <img
                          v-lazy="
                            require('@/assets/images/dashboard/icon-1.svg')
                          "
                          alt="Profit icon"
                          width="42"
                        />
                      </div>
                      <div class="info">
                        <div class="name">{{ $t("dashboard.net_profit") }}</div>
                        <CurrencyFormat
                          :value="Statistics.net_profix || 0"
                          class="value"
                        />
                      </div>
                    </div>
                    <!-- END PROFIT -->
                  </b-col>
                  <b-col cols="12" sm="6" class="mt-4">
                    <!-- INCOME -->
                    <div class="profit card-dashboard box d-flex">
                      <div class="icon">
                        <img
                          v-lazy="
                            require('@/assets/images/dashboard/icon-2.svg')
                          "
                          alt="Income icon"
                          width="42"
                        />
                      </div>
                      <div class="info">
                        <div class="name">
                          {{ $t("dashboard.total_income") }}
                        </div>
                        <CurrencyFormat
                          :value="Statistics.total_profit || 0"
                          class="value"
                        />
                      </div>
                    </div>
                    <!-- END INCOME -->
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="div-summary">
                <div>
                  <h4 class="my-3 text-center">
                    {{ $t("dashboard.transaction_summary") }}
                  </h4>
                  <RatioBar
                    :value="Statistics.total_buy / Statistics.total || 0.5"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- END SUMMARY -->
        </b-col>
      </b-row>

      <!-- HISTORY -->
      <div class="history d-block d-lg-flex flex-wrap">
        <div class="flex-fill">
          <h3 class="mb-3">{{ $t("dashboard.history_transaction") }}</h3>
        </div>

        <div class="toolBar d-flex mb-2">
          <div class="input-date">
            <b-form-datepicker
              variant="primary"
              id="datepicker-sm"
              size="sm"
              locale="en"
              v-model="from"
              today-button
              reset-button
              close-button
              class="mb-0 customer-date-picker text-white"
              placeholder="yyyy/mm/dd"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
            ></b-form-datepicker>
            <div class="mx-2">-</div>
            <b-form-datepicker
              variant="primary"
              id="datepicker-sm-2"
              size="sm"
              locale="en"
              v-model="to"
              today-button
              reset-button
              close-button
              class="mb-0 customer-date-picker text-white"
              placeholder="yyyy/mm/dd"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
            ></b-form-datepicker>
          </div>

          <b-button class="btn-aktrade" @click.prevent="search()">{{
            $t("dashboard.search")
          }}</b-button>
        </div>
      </div>
      <div class="tableWrapper table-responsive">
        <b-table
          thead-class="thead-ig"
          :striped="true"
          :bordered="true"
          :hover="true"
          :items="History.data"
          :fields="fields"
          :tbody-tr-class="rowClass"
          show-empty
        >
          <template #empty="">
            <h5 class="text-center p-2">
              {{ $t("support.no_data_available_in_table") }}
            </h5>
          </template>
          <template #cell(candleOpenTime)="data">
            <p class="text-right mb-0">
              {{ getDateTime(data.item.candleOpenTime) }}
            </p>
          </template>
          <template #cell(candleOpen)="data">
            <p class="text-right mb-0 w-max-content">
              {{ TruncateToDecimals(data.item.candleOpen) }}
            </p>
          </template>
          <template #cell(candleClose)="data">
            <p class="text-right mb-0 w-max-content">
              {{ TruncateToDecimals(data.item.candleClose) }}
            </p>
          </template>
          <template #cell(amount)="data">
            <p class="text-right mb-0 w-max-content">
              {{ TruncateToDecimals(data.item.amount) }}
            </p>
          </template>
          <template #cell(pay)="data">
            <p class="text-right mb-0">
              {{
                data.item.status == "COMPLETE_TIE"
                  ? TruncateToDecimals(data.item.amount)
                  : data.item.status == "COMPLETE_LOSE"
                  ? TruncateToDecimals(data.item.pay, "-")
                  : TruncateToDecimals(data.item.pay, "+")
              }}
            </p>
          </template>
        </b-table>
      </div>
      <div class="row px-2 py-2 align-items-center mb-5">
        <div class="flex-fill pt-1">
          {{ currentPage }} Page Of {{ History.total }} Pages
        </div>
        <div class="flex-fill button-div text-right d-flex justify-content-end">
          <b-button
            variant="primary"
            :disabled="currentPage <= 1"
            class="mr-2 btn btn-aktrade"
            @click.prevent="paginate(currentPage - 1)"
            >{{ $t("dashboard.previous") }}</b-button
          >
          <b-button
            variant="primary"
            :disabled="currentPage >= History.total"
            class="mr-2 btn btn-aktrade"
            @click.prevent="paginate(currentPage + 1)"
            >{{ $t("dashboard.next") }}</b-button
          >
        </div>
      </div>
      <!-- END HISTORY -->
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CurrencyFormat from "@/components/CurrencyFormat.vue";
import RatioBar from "@/components/RatioBar.vue";
import moment from "moment";
export default {
  components: {
    CurrencyFormat,
    RatioBar
  },
  data() {
    return {
      sections: [
        { value: 0, color: "#dc3545", label: "Total Lost" },
        { value: 0, color: "darkorange", label: "Total Draw" },
        { value: 0, color: "#28a745", label: "Total Win" }
      ],
      winRate: 0.95,
      totalTransaction: 12,
      numTransaction: 3,
      profit: 1.23,
      income: 12.3,
      value: "",
      value2: "",
      from: "",
      to: "",
      currentPage: 1
    };
  },
  computed: {
    ...mapGetters({
      Statistics: "dashboard/Statistical",
      History: "dashboard/History"
    }),
    fields() {
      return [
        {
          key: "order_id",
          label: this.$t("dashboard.order_id"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "candleOpenTime",
          label: this.$t("dashboard.datetime"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "symbolId",
          label: this.$t("exchange.coins"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "betType",
          label: this.$t("dashboard.option_type"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "candleOpen",
          label: this.$t("dashboard.open"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "candleClose",
          label: this.$t("dashboard.close"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "amount",
          label: this.$t("dashboard.trade_amount"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "pay",
          label: this.$t("dashboard.payout"),
          sortable: true,
          class: "text-center text-middle"
        }
      ];
    }
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "COMPLETE_WIN") return "color-win";
      if (item.status === "COMPLETE_LOSE") return "color-lose";
      if (item.status === "COMPLETE_TIE") return "color-tie";
    },
    Sections() {
      var section = [
        {
          value: this.Statistics.percent_win,
          color: "#28a745",
          label: "total_trade_win"
        },
        {
          value: this.Statistics.percent_lose,
          color: "#dc3545",
          label: "total_trade_lose"
        },
        {
          value: this.Statistics.percent_drawn,
          color: "darkorange",
          label: "total_trade_draw"
        }
      ];
      this.sections = section;
    },
    search() {
      this.currentPage = 1;
      this.$store.dispatch("dashboard/req_getHistory", {
        from: this.from,
        to: this.to
      });
    },
    getDateTime(unix) {
      return moment
        .unix(unix / 1000)
        .utcOffset("+00:00")
        .format("HH:mm - DD/MM/YYYY");
    },
    paginate(page) {
      if (page > 0 && page <= this.History.total) {
        this.currentPage = page;
        this.$store.dispatch("dashboard/req_getHistory", {
          from: this.from,
          to: this.to,
          page: page
        });
      }
    },
    TruncateToDecimals(num, mah, dec = 2) {
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split(".")[1];
      const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
      return `$ ${mah ? mah : ""}${newNum
        .toFixed(precision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  },
  created() {
    this.$store.dispatch("dashboard/req_getStatistical");
    this.$store.dispatch("dashboard/req_getHistory", 0);
  },
  watch: {
    Statistics: {
      handler(nVal) {
        if (nVal) {
          this.Sections();
        }
      }
    }
  },
  async mounted() {
    // simulate load data
    this.onload();
    await new Promise(resolve => setTimeout(resolve, 300));
    this.outload();
  }
};
</script>
<style>
.card-dashboard {
  min-height: 165px;
  align-items: center;
}

.customer-date-picker.form-control label.form-control.form-control-sm {
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .customer-date-picker.form-control label.form-control.form-control-sm {
    font-size: 11px;
  }
  .b-form-btn-label-control.form-control {
    position: unset;
  }
}

.customer-date-picker.form-control .dropdown-menu .form-control {
  background: #ffffffa8;
}
</style>
<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

.red {
  color: red;
}
.green {
  color: green;
}
/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.fz-16 {
  font-size: 16px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
.btn-none {
  padding: 3px 10px;
  background: #6abe43;
  border: none;
  color: #fff;
  font-size: 14px;
  margin: 0 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// @media (min-width: 768px) {
  .privateDashboard {
    //     background-image: url("~@/assets/images/background/bg.jpg");
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    min-height: 100vh;
  }
// }
// @media (max-width: 768px) {
//   .privateDashboard {
//     background-image: url("~@/assets/images/bg_mobile.jpg");
//     background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
//     min-height: 100vh;
//   }
// }
h3,
.h3 {
  font-size: 1.5rem;
}
.box {
  min-height: 250px;
  background: rgb(35 31 32 / 0.6) !important;
  margin: 0 2px;
  border: 1px solid #00ff85;
  width: 100%;
  padding: 20px;
  position: relative;
  margin: auto;
  border-radius: 15px;
}

/* CHART STATS */
.chartWrapper {
  .chart {
    width: 150px;
    margin: 0 auto;

    .name,
    .value {
      font-weight: bold;
      font-size: 1.1em;
    }

    .value {
      font-size: 2em;
    }
  }
}
/* END CHART STATS */

/* TRADE STATS */
.tradeStats {
  .description {
    margin: 10px 0;
    display: flex;

    .icon {
      width: 24px;
      height: 24px;
      border: 6px solid $primaryColor;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .metrics > :first-child {
    border-right: 1px solid white;
  }

  .metrics {
    text-align: center;
    margin-top: 15px;

    .name {
      font-weight: bold;
    }

    .value {
      font-size: 1.3em;
      font-weight: bold;
    }
  }
}
/* END TRADE STATS */

/* OTHER METRICS */
.otherMetric {
  .box {
    padding: 40px;
    max-width: 500px;
    min-height: 150px;

    .icon {
      width: 42px;
      height: 42px;
      margin-right: 20px;
    }

    .name {
      font-weight: bold;
      font-size: 1em;
      line-height: 1em;
    }

    .value {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
}
@media (min-width: 768px) {
  .div-summary {
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
/* END OTHER METRICS */

/* HISTORY */
.history {
  .toolBar {
    display: flex;
    .input-date {
      height: 35px;
      align-items: center;
      border-radius: 30px;
      background: transparent;
      align-self: center;
      margin-right: 10px;
      display: flex;
    }

    input {
      background-color: transparent;
      border: none;
      color: white;
      min-width: 10px;
      width: calc(50% - 40px);
      font-size: 0.8em;
    }

    button {
      font-size: 0.8em;
      font-weight: bold;
      min-width: 80px;
      border-radius: 5px 5px 0 0;
    }
  }
}

/* END HISTORY */

/* TABLE */
.tableWrapper {
  width: 100%;

  .table {
    width: 100%;
    font-size: 1.1em;
    background-color: rgb(35 31 32 / 0.6);
    border: 1px solid #00ff85;

    thead {
      background: linear-gradient(90deg, #00ff85, #00c3ff) !important;

      th {
        color: black !important;
      }
    }

    th,
    td {
      border: none;
      padding: 10px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      border: 1px solid #ccc !important;
      background: #fff0 !important;
      color: #fff;
    }

    th {
      font-weight: bold;
      font-size: 1em;
    }
  }
}
/* END TABLE */

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .privateDashboard {
    .tableWrapper {
      .table {
        font-size: 1em;
      }
    }
  }

  /* HISTORY */
  .history {
    .toolBar {
      input {
        font-size: 1em;
        width: auto;
      }

      button {
        font-size: 1em;
      }
    }
  }
  /* END HISTORY */
}
.paginate {
  display: flex;
  justify-content: space-around;
  height: 50px;
  align-items: center;

  .button-div {
    flex: 0 0 300px;
    display: flex;
    justify-content: space-evenly;
  }
}
@media (min-width: 1600px) {
  .container-xl {
    max-width: 1580px;
  }
}
</style>
