<template>
	<keep-alive>
		<div class="privateTransaction p-4">
			<div class="d-lg-flex position-relative z-index-2">
				<!-- MAIN PANEL -->

				<div class="mainPanel">
					<!-- TRADING CHART WRAPPER -->
					<div
						class="tradingChartWrapper position-relative"
						ref="tradingChartWrapper"
					>
						<Loader
							v-if="!isLoad && ohlcv.length < 1"
							class="position-absolute"
						/>
						<b-button
							class="rounded customer-drop"
							variant="outline-primary"
							style="
								height: auto;
								border: 0;
								padding: 0;
								background: transparent !important;
								cursor: default;
								font-weight: 600;
							"
						>
							<div
								class="
									d-flex
									justify-content-center
									align-items-center align-self-center
								"
							>
								<div
									class="coinName"
									style="color: #fff !important; opacity: 1"
								>
									{{ currentCoin.name }}
								</div>
								<div
									v-if="ohlcv.length > 1 && widthL >= 1200"
									class="
										statBTC
										d-lg-flex d-none
										align-items-center
										ml-xl-5 ml-lg-2
									"
								>
									<div class="price-index">
										{{
											TruncateToDecimals2(
												ohlcv[ohlcv.length - 1][4]
											)
										}}
									</div>
									<div class="priceUpDown">
										<span class="mx-1">|</span>
										<span
											class="label-percent"
											:class="upDownColor"
										>
											{{
												percentUpDown(
													ohlcv[ohlcv.length - 1][4],
													ohlcv[ohlcv.length - 1][1]
												)
											}}
										</span>
										<div
											class="label-price"
											:class="upDownColor"
										>
											<span class="mx-1">|</span>
											{{
												priceUpdown(
													ohlcv[ohlcv.length - 1][4],
													ohlcv[ohlcv.length - 1][1]
												)
											}}
										</div>
									</div>
									<div class="priceStat">
										<div class="high24 stat24">
											<div class="flex-auto mx-1">
												<span class="mx-1">|</span>24h
												High
											</div>
											<div class="flex-auto mx-1">
												{{
													TruncateToDecimals2(
														LowHightVol.max
													)
												}}
												<span class="mx-1">|</span>
											</div>
										</div>
										<div class="low24 stat24">
											<div class="flex-auto mx-1">
												24h Low
											</div>
											<div class="flex-auto mx-1">
												{{
													TruncateToDecimals2(
														LowHightVol.min
													)
												}}
												<span class="mx-1">|</span>
											</div>
										</div>
										<div class="vol24 stat24">
											<div class="flex-auto mx-1">
												1m Vol
											</div>
											<div class="flex-auto mx-1">
												{{
													TruncateToDecimals2(
														ohlcv[
															ohlcv.length - 1
														][5]
													)
												}}
												<span class="mx-1">|</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-button>
						<!-- END LABEL -->

						<div class="notify-win" v-if="show_popup_profit">
							<div class="body-noti p-2">
								<transition
									name="custom-classes-transition"
									enter-active-class="animate__animated  animate__bounceIn"
									leave-active-class="animate__animated  animate__bounceOut"
								>
									<div class="content">
										<p
											:class="
												win_amount > 0 ? 'buy' : 'sell'
											"
										>
											{{
												win_amount > 0
													? "WIN!"
													: "LOSE!"
											}}
										</p>
										<p
											:class="
												win_amount > 0
													? 'amount-win'
													: 'amount-lose'
											"
										>
											{{
												win_amount
													.toFixed(2)
													.replace(
														/\d(?=(\d{3})+\.)/g,
														"$&,"
													)
											}}
											<i
												:class="
													win_amount > 0
														? 'fad  fa-arrow-up'
														: 'fad  fa-arrow-down'
												"
											></i>
										</p>
									</div>
								</transition>
							</div>
						</div>
						<trading-vue
							:data="this.$data"
							:overlays="overlays"
							:titleTxt="''"
							:chart-config="config"
							:width="tradingChart.width"
							:height="tradingChart.height"
							colorBack="#111315"
							colorCross="#f3d91f"
							colorGrid="rgb(255,255,255,.0)"
							colorCandleUp="#28a845"
							colorCandleDw="#ed2325"
							:key="updataC"
							ref="tradingChart"
							class="tradingChart"
							v-if="showtrade"
						></trading-vue>
						<!-- END CHART -->
					</div>
					<!-- END TRADING CHART WRAPPER -->

					<!-- SUMMARY -->
					<b-tabs class="summary">
						<b-tab title="Indicators" v-if="false">
							<div class="rateChartList d-flex">
								<div
									class="
										rateChartWrapper
										secondary
										d-flex
										align-items-center
									"
								>
									<RateChart
										:value="0.2"
										title="Oscillators"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
								<div
									class="
										rateChartWrapper
										primary
										d-flex
										align-items-center
									"
								>
									<RateChart
										:value="0.7"
										title="Summary"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
								<div
									class="
										rateChartWrapper
										secondary
										d-flex
										align-items-center
									"
								>
									<RateChart
										:value="0.3"
										title="Moving Averages"
										:sell="4"
										:neutral="5"
										:buy="0"
									/>
								</div>
							</div>
						</b-tab>
						<b-tab
							:title="$t('Trade Stat')"
							class="lastResult"
							v-if="widthL <= 1199"
							:active="widthL <= 1199"
							@click="activeBotTable = false"
						>
							<div
								class="
									form-group
									d-flex
									justify-content-around
									my-3
									box-stat-tr
								"
								v-if="ohlcv.length > 1"
							>
								<div class="d-flex flex-column div-price-main">
									<div class="price-index">
										{{
											TruncateToDecimals2(
												ohlcv[ohlcv.length - 1][4]
											)
										}}
									</div>
									<div
										class="
											priceUpDown
											flex-column flex-md-row
										"
									>
										<span
											class="label-percent"
											:class="upDownColor"
										>
											{{
												percentUpDown(
													ohlcv[ohlcv.length - 1][4],
													ohlcv[ohlcv.length - 1][1]
												)
											}}
										</span>
										<div
											class="label-price"
											:class="upDownColor"
										>
											{{
												priceUpdown(
													ohlcv[ohlcv.length - 1][4],
													ohlcv[ohlcv.length - 1][1]
												)
											}}
										</div>
									</div>
								</div>
								<div class="priceStat">
									<div class="high24 stat24">
										<div class="flex-1">24h High</div>
										<div class="flex-1">
											{{
												TruncateToDecimals2(
													LowHightVol.max
												)
											}}
										</div>
									</div>
									<div class="low24 stat24">
										<div class="flex-1">24h Low</div>
										<div class="flex-1">
											{{
												TruncateToDecimals2(
													LowHightVol.min
												)
											}}
										</div>
									</div>
									<div class="vol24 stat24">
										<div class="flex-1">1m Vol</div>
										<div class="flex-1">
											{{
												TruncateToDecimals2(
													ohlcv[ohlcv.length - 1][5]
												)
											}}
										</div>
									</div>
								</div>
							</div>
						</b-tab>
						<b-tab
							:title="$t('exchange.last_results')"
							class="lastResult"
							:active="widthL > 1199"
						>
							<b-row>
								<b-col
									cols="8"
									lg="5"
									xl="4"
									class="align-self-center llg-7"
								>
									<div class="dotGraph my-2">
										<div class="dotGroup d-flex">
											<div
												:class="`dot ${
													value == 0
														? 'increase'
														: value == 1
														? 'decrease'
														: value == 2
														? 'draw'
														: ''
												}`"
												v-for="(
													value, index
												) in history.slice(0, 30)"
												:key="`dot-${index}`"
											></div>
											<div
												class="dot"
												v-for="(value, index) in 30 -
												history.slice(0, 30).length"
												:key="`dot-${index + 30}`"
											></div>
										</div>
										<div class="dotGroup d-flex">
											<div
												:class="`dot ${
													value == 0
														? 'increase'
														: value == 1
														? 'decrease'
														: value == 2
														? 'draw'
														: ''
												}`"
												v-for="(
													value, index
												) in history.slice(30, 60)"
												:key="`dot-${index + 60}`"
											></div>
											<div
												class="dot"
												v-for="(value, index) in 30 -
												history.slice(30, 60).length"
												:key="`dot-${index + 90}`"
											></div>
										</div>
									</div>
								</b-col>
								<b-col
									cols="4"
									lg="2"
									xl="3"
									class="align-self-center llg-5"
								>
									<div class="info">
										<span
											class="
												action
												bet
												btn_buy
												pt-3
												pb-3
												mb-3
											"
											style="
												background-image: none;
												background: #28a845;
												text-align: center;
												justify-content: center;
											"
										>
											{{ historyNumberIncrease }}
											{{ $t("exchange.buy") }}
										</span>

										<span
											class="
												action
												bet
												btn_sell
												pt-3
												pb-3
												cursor-default
											"
											style="
												background-image: none;
												background: #ed2325;
												text-align: center;
												justify-content: center;
											"
										>
											{{ historyNumberDecrease }}
											{{ $t("exchange.sell") }}
										</span>
									</div>
								</b-col>
								<b-col
									cols="12"
									lg="5"
									xl="5"
									v-if="widthL > 1024"
								>
									<TableBot :owner="userOwner"></TableBot>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab
							:title="$t('exchange.order_list')"
							class="lastResult"
							v-if="widthL <= 1024"
						>
							<TableBot :owner="userOwner"></TableBot>
						</b-tab>
					</b-tabs>
					<!-- END SUMMARY -->
				</div>
				<div class="sidePanel box-right mt-0">
					<div class="position-relative z-index-2 p-2">
						<!-- PREDICT VALUE -->
						<div class="form-group d-block d-lg-none mb-0">
							<div class="predictValue text-center text-lg-left">
								<span class="currentRatio" style="color: #fff"
									><span style="font-size: 12px">{{
										$t("exchange.profit")
									}}</span>
									{{ transaction.currentRatio * 100 }}%</span
								>
								<span class="sign" style="color: #fff">{{
									predictValue >= 0 ? "+" : "-"
								}}</span>
								<CurrencyFormat
									class="predictValue d-inline"
									style="color: #fff"
									:value="Math.abs(predictValue)"
								/>
							</div>
						</div>
						<!-- END PREDICT VALUE -->

						<!-- ADD VALUE -->
						<div class="form-group mb-1 mb-lg-2">
							<label for="value" class="d-none d-lg-block">{{
								$t("exchange.amount")
							}}</label>
							<div class="d-flex">
								<b-button
									v-if="!showHideZoom.zoom.visible"
									class="px-3 mr-2 d-block d-lg-none flex-30p"
									@click="
										transaction.value =
											transaction.value >= 5
												? transaction.value - 5
												: 0
									"
									>-</b-button
								>
								<div
									class="
										transactionValue
										rounded
										flex-fill flex-40p
									"
									style="
										border: 1px solid #00ff85;
										background: #000;
									"
								>
									<input
										type="number"
										step="1"
										class="w-100"
										v-model.number="transaction.value"
										:disabled="showHideZoom.zoom.visible"
									/>
									<b-button
										variant="warning"
										size="sm"
										pill
										class="clearValue"
										@click="transaction.value = 0"
									>
										<img
											v-lazy="
												require('@/assets/images/icons/close.svg')
											"
											alt="Clear value"
										/>
									</b-button>
								</div>
								<b-button
									v-if="!showHideZoom.zoom.visible"
									class="px-3 ml-2 d-block d-lg-none flex-30p"
									@click="transaction.value += 5"
									>+</b-button
								>
							</div>

							<div
								v-if="showHideZoom.zoom.visible"
								class="
									addValue
									flex-wrap
									justify-content-center
									d-flex
								"
							>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value += parseInt(
											expertinfo.startZoomBalance * 0.01
										)
									"
								>
									+1%
								</b-button>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value += parseInt(
											expertinfo.startZoomBalance * 0.02
										)
									"
								>
									+2%
								</b-button>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value = parseInt(
											expertinfo.startZoomBalance * 0.05
										)
									"
								>
									5%
								</b-button>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value += parseInt(
											expertinfo.startZoomBalance * 0.1
										)
									"
									>10%
								</b-button>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value = parseInt(
											expertinfo.startZoomBalance * 0.12
										)
									"
								>
									12%
								</b-button>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value += parseInt(
											expertinfo.startZoomBalance * 0.15
										)
									"
								>
									15%
								</b-button>
							</div>

							<div
								v-else
								class="
									addValue
									flex-wrap
									justify-content-center
									d-none d-lg-flex
								"
							>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="transaction.value += 5"
									>+5</b-button
								>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="transaction.value += 10"
									>+10</b-button
								>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="transaction.value += 20"
									>+20</b-button
								>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="transaction.value += 50"
									>+50</b-button
								>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="transaction.value += 100"
									>+100</b-button
								>
								<b-button
									class="sampleValue"
									variant="outline-primary"
									@click="
										transaction.value = parseInt(
											user.accounts[1].balance
										)
									"
									>{{ $t("exchange.all") }}</b-button
								>
							</div>
						</div>
						<!-- END ADD VALUE -->

						<!-- PREDICT VALUE -->
						<div class="form-group d-none d-lg-block">
							<label
								for=""
								class="d-none d-lg-block"
								style="font-size: 12px; margin-top: 10px"
								>{{ $t("exchange.profit") }}</label
							>
							<div
								class="predictValue text-center"
								style="color: #fff"
							>
								<span class="currentRatio"
									>{{ transaction.currentRatio * 100 }}%</span
								>
								<span class="sign">{{
									predictValue >= 0 ? "+" : "-"
								}}</span>
								<CurrencyFormat
									class="predictValue d-inline"
									:value="Math.abs(predictValue)"
								/>
							</div>
							<label
								for=""
								class="d-none d-lg-block"
								style="font-size: 12px"
							>
								{{ $t("exchange.traders_sentiments") }}
							</label>

							<RatioBar
								:value="
									betAmount.BTCUSDT.GREEN /
										(betAmount.BTCUSDT.GREEN +
											betAmount.BTCUSDT.RED) || 0.5
								"
							/>
						</div>
						<!-- TRANSACTION ACTION -->
						<div
							v-if="showHideZoom.bet"
							class="
								form-group
								transactionAction
								mb-0
								d-flex d-lg-block
							"
						>
							<div class="py-1 pr-1 py-lg-0 pr-lg-0 flex-35p">
								<b-button
									v-if="ohlcv.length <= 0"
									variant="danger"
									size="lg"
									class="w-100 action bet btn_buy pt-3 pb-3"
									disabled
								>
									Connecting
								</b-button>
								<button
									:key="`buy_button_key_${new Date().getSeconds()}`"
									v-else
									type="button"
									size="lg"
									class="
										w-100
										btn_btn_bet
										action
										bet
										btn_buy
										pt-3
										pb-3
									"
									@click="!disabledBTN ? betBuy() : ''"
									:disabled="DisabledBuyNow"
								>
									{{
										amountBet.buy > 0
											? amountBet.buy % 1 != 0
												? truncateToDecimals(
														amountBet.buy
												  )
												: amountBet.buy
											: $t("exchange.buy")
									}}
								</button>
							</div>
							<div class="info w-100 p-1 flex-30p pt-3 pb-3">
								<div>
									<small v-if="ohlcv.length <= 0"
										>Connecting</small
									>
									<small v-else>{{
										$t(
											transaction.round == "order"
												? "exchange.order"
												: "exchange.waiting"
										)
									}}</small>
								</div>
								<div>
									<strong v-if="ohlcv.length <= 0">0</strong>
									<strong v-else :key="transaction.timeRemain"
										>{{
											transaction.timeRemain > 29
												? 59 - transaction.timeRemain
												: 29 - transaction.timeRemain
										}}s</strong
									>
								</div>
							</div>
							<div class="py-1 pl-1 py-lg-0 pl-lg-0 flex-35p">
								<b-button
									v-if="ohlcv.length <= 0"
									variant="danger"
									size="lg"
									class="w-100 action bet btn_sell pt-3 pb-3"
									disabled
								>
									Connecting
								</b-button>
								<button
									:key="`sell_button_key_${new Date().getSeconds()}`"
									type="button"
									size="lg"
									v-else
									class="
										w-100
										btn_btn_bet
										action
										bet
										btn_sell
										pt-3
										pb-3
									"
									@click="!disabledBTN ? betSell() : ''"
									:disabled="DisabledSellNow"
								>
									{{
										amountBet.sell > 0
											? amountBet.sell % 1 != 0
												? truncateToDecimals(
														amountBet.sell
												  )
												: amountBet.sell
											: $t("exchange.sell")
									}}
								</button>
							</div>
						</div>
						<div
							class="form-group stop-start-zoom"
							v-if="showHideZoom.zoom.visible"
						>
							<b-button
								v-if="showHideZoom.zoom.start"
								class="start-zoom"
								variant="success"
								@click="onEventByExpert('start')"
							>
								{{ $t("expert.start zoom") }}
							</b-button>
							<b-button
								v-if="showHideZoom.zoom.end"
								class="end-zoom"
								variant="danger"
								@click="onEventByExpert('end')"
							>
								{{ $t("expert.end zoom") }}
							</b-button>
						</div>
					</div>
					<!-- END TRANSACTION ACTION -->
				</div>
				<!-- MAIN PANEL -->
			</div>
		</div>
	</keep-alive>
</template>
<script>
import TableBot from "@/components/TableBot.vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import TradingVue from "trading-vue-js";
import EMA from "@/components/overlay/EMA.vue";
import RateChart from "@/components/RateChart.vue";
import CurrencyFormat from "@/components/CurrencyFormat.vue";
import RatioBar from "@/components/RatioBar.vue";
import moment from "moment";
import ten from "@/assets/sound/10.mp3";
import lose from "@/assets/sound/lose.mp3";
import win from "@/assets/sound/win.mp3";
import ting from "@/assets/sound/ting.mp3";
import _ from "lodash";
export default {
	components: {
		RateChart,
		TradingVue,
		CurrencyFormat,
		RatioBar,
		Loader,
		TableBot,
	},
	data() {
		return {
			ohlcv: [],
			history: [],
			localhistory: [],
			tradingChart: {
				width: 100,
				height: 100,
			},
			overlays: [EMA],
			config: {
				DEFAULT_LEN: window.innerWidth > 992 ? 60 : 15,
				TB_BORDER: 1,
				CANDLEW: 0.8,
				GRIDX: window.innerWidth > 992 ? 40 : 100,
				tf: "3M",
				VOLSCALE: "auto",
			},
			onchart: [
				{
					name: "EMA, 5",
					type: "EMA",
					data: [],
					settings: {
						legend: false,
					},
				},
				{
					name: "EMA, 20",
					type: "EMA",
					data: [],
					settings: {
						legend: false,
					},
				},
				{
					name: "Data sections",
					type: "Splitters",
					data: [[], []],
					settings: {
						legend: false,
						"z-index": 10,
					},
				},
			],
			offchart: [
				{
					name: "Vol",
					type: "Volume",
					data: [],
					settings: {
						upper: 100,
						lower: 0,
						legend: false,
						volscale: 0.1,
						colorVolUp: "#28a845",
						colorVolDw: "#ed2325",
					},
				},
			],
			windowEvent: null,
			connection: null,
			win_amount: 0,
			symbolId: "BTCUSDT",
			time: 0,
			updatedData: 0,
			classInfo: "",
			updataC: 0,
			newKey: 0,
			intervalTime: null,
			firstTimeConnected: true,
			disabledBTN: false,
			show_popup_profit: false,
			connected: false,
			amountBet: {
				sell: 0,
				buy: 0,
			},
			transaction: {
				value: 0,
				currentRatio: 0.95,
				timeRemain: 0,
				round: "",
			},
			bet_data: {
				action: "onbet",
				data: {
					amount: 0,
					exchangeType: "binance.com",
					symbolId: "BTCUSDT",
					userId: "",
					betType: "",
				},
			},
			coinList: [
				{
					id: 1,
					name: "BTC/USDT",
					image: "bitcoin.png",
					betSymbol: "BTCUSDT",
				},
			],
			currentCurrencyID: 1,
			interValReconect: null,
			reConnect: true,
			timerId: null,
			showtrade: true,
			currenyW: "BTCUSDT",
			toggleSound: true,
			timeOutSlide: null,
			randomEnd: true,
			BuySell: false,
			TimeBS: null,
			userOwner: [],
			widthL: 0,
			betAmount: {
				BTCUSDT: { GREEN: 0, RED: 0 },
				ETHUSDT: { GREEN: 0, RED: 0 },
			},
			experEvent: {
				start: false,
				end: false,
				time: "",
			},
			expertinfo: {
				endTime: null,
				isEndZoom: false,
				isStartZoom: false,
				startTime: null,
				show: false,
				startZoomBalance: 0,
			},
			upDownColor: "",
			mahh: "",
			LowHightVol: {
				min: 0,
				max: 0,
			},
		};
	},
	computed: {
		...mapGetters({
			user: "userinfo/User",
			maintainer: "core/Maintainer",
			UserInfo: "userinfo/UserInfo",
		}),
		...mapState(["isLoad", "userBalance", "soundsE"]),
		predictValue() {
			return this.transaction.value * (this.transaction.currentRatio + 1);
		},
		historyNumberIncrease() {
			return this.history.filter((i) => i == 0).length;
		},
		historyNumberDecrease() {
			return this.history.filter((i) => i == 1).length;
		},
		currentCoin() {
			for (let coin of this.coinList)
				if (coin.id === this.currentCurrencyID) return coin;
			return null;
		},
		coinListAv() {
			let self = this;
			return _.pickBy(this.coinList, function (display) {
				return display.id !== self.currentCurrencyID;
			});
		},
		showHideZoom() {
			const expert = this.expertinfo;
			let show = {
				bet: true,
				zoom: {
					visible: false,
					start: false,
					end: false,
				},
			};
			if (expert.show) {
				if (!expert.isStartZoom) {
					show.zoom.start = true;
					show.zoom.visible = true;
					show.bet = false;
				} else {
					show.zoom.end = true;
					show.zoom.visible = true;
					show.bet = true;
				}
				if (expert.isEndZoom) {
					show.zoom.visible = false;
					show.bet = true;
				}
			}
			return show;
		},
		DisabledSellNow() {
			if (this.transaction.timeRemain <= 29 && this.disabledBTN) {
				return true;
			}
			if (this.transaction.timeRemain > 29) {
				return true;
			}
			// if (this.amountBet.buy > 0) {
			// 	return true;
			// }
			return false;
		},
		DisabledBuyNow() {
			if (this.transaction.timeRemain <= 29 && this.disabledBTN) {
				return true;
			}
			if (this.transaction.timeRemain > 29) {
				return true;
			}
			// if (this.amountBet.buy > 0) {
			// 	return true;
			// }
			return false;
		},
	},
	created() {
		this.$store.dispatch("core/req_getMaintainer");
		this.createjs.Sound.registerSound(ten, "ten", 4);
		this.createjs.Sound.registerSound(lose, "lose", 5);
		this.createjs.Sound.registerSound(win, "win", 6);
		this.createjs.Sound.registerSound(ting, "ting", 7);
		this.checkUser();
	},
	watch: {
		maintainer: {
			handler(newVal) {
				if (newVal == 1) {
					this.checkUser("realtime");
				}
			},
		},
	},
	methods: {
		...mapMutations(["onload", "outload"]),
		checkUser: async function (type) {
			const userLevel = await this.UserInfo.level;
			if (type == "realtime") {
				if (this.maintainer == 1 && userLevel !== 1) {
					this.$router.push({ name: "dashboard" });
					this.$toastr.e(
						"Opps!, Exchange Is Maintainer",
						"Please Come Back Later"
					);
				}
			} else {
				if (this.maintainer == 1 && userLevel !== 1) {
					this.$router.push({ name: "dashboard" });
					this.$toastr.e(
						"Opps!, Exchange Is Maintainer",
						"Please Come Back Later"
					);
				} else {
					this.connnectWSS("BTCUSDT");
				}
			}
		},
		connnectWSS: async function (symbol) {
			let self = this;
			await this.$connect(
				"wss://autoking.chidetest.com/live/" +
					this.user.token +
					"/" +
					symbol,
				{ format: "json" }
			);

			this.$options.sockets.onopen = () => {
				clearInterval(this.timerId);
				this.connected = true;
				this.reConnect = false;
				this.ohlcv = [];
				clearTimeout(self.timeOutSlide);
				this.getRoundBet();
				this.switchData();
			};
			this.$options.sockets.onclose = () => {};
			this.$socket.onerror = function (event) {
				if (event.type == "error") {
					self.logout();
				}
			};
			this.$options.sockets.onerror = () => {
				this.$store.commit("auth/LOGOUT_SUCCESS");
				this.$router.push("/login");
			};
		},
		getDateTime(unix) {
			return moment.unix(unix / 1000).format("HH:mm:ss - DD/MM/YYYY");
		},
		getDateTime2(unix) {
			return moment
				.unix(unix / 1000)
				.utcOffset("+00:00")
				.format("HH:mm - DD/MM/YYYY");
		},
		randomUserId: function (user) {
			if (parseInt(user) > 100000) {
				return user;
			}
			const user_c =
				+Math.floor(Math.random() * (999999 - 100000)) + 100000;
			return user_c;
		},
		checkResizeWindow() {
			this.CheckDevid();
			this.widthL = window.innerWidth;
			if (window.innerWidth >= 992) {
				this.tradingChart.width =
					this.$refs.tradingChartWrapper.offsetWidth;
			} else {
				this.tradingChart.width = window.innerWidth - 50;
			}

			let heightApp =
				document.getElementById("app-reponsive").clientHeight;
			if (window.innerHeight <= 480) {
				this.tradingChart.height = 250;
			} else if (window.innerWidth >= 1024 && window.innerHeight <= 800) {
				this.tradingChart.height = window.innerHeight - 355;
			} else if (window.innerWidth <= 768 && window.innerWidth > 480) {
				this.tradingChart.height = heightApp - 470;
			} else if (window.innerWidth <= 992 && window.innerWidth >= 768) {
				this.tradingChart.height = heightApp - 460;
			} else if (window.innerWidth <= 480) {
				this.tradingChart.height = heightApp - 460;
			} else {
				this.tradingChart.height = window.innerHeight - 430;
			}
		},
		switchData: async function () {
			let self = this;
			var ohlcv = [];
			this.$options.sockets.onmessage = (event) => {
				let defaultdata = JSON.parse(event.data);
				let actionSocket = defaultdata.action;
				let dataSocket = defaultdata.data;
				let timeIndexSec = 0;
				let timeIndexMin = 0;

				switch (actionSocket) {
					case "UserInfo":
						self.convertBalance(dataSocket);
						break;
					case "CopyTrade":
						self.converExportJson(dataSocket);
						break;
					case "ListCandle":
					case "CandleEvery30s":
						if (
							dataSocket != undefined &&
							dataSocket.length != undefined
						) {
							ohlcv = [];
							clearTimeout(self.timeOutSlide);
							for (let i = 0; i < dataSocket.length; i++) {
								let each_ohlcv = [];
								each_ohlcv[0] = dataSocket[i].openTime;
								each_ohlcv[1] = dataSocket[i].open;
								each_ohlcv[2] = dataSocket[i].high;
								each_ohlcv[3] = dataSocket[i].low;
								each_ohlcv[4] = dataSocket[i].close;
								each_ohlcv[5] = dataSocket[i].volume;
								this.symbolId = dataSocket[i].symbolId;
								ohlcv.push(each_ohlcv);
							}
							this.ohlcv = ohlcv;
							this.offchart[0].data = ohlcv;
						}
						break;
					case "PriceEverySec":
						timeIndexSec = moment(dataSocket.closeTime).second();
						timeIndexMin = moment(dataSocket.closeTime).minutes();
						if (self.expertinfo && !self.expertinfo.isEndZoom) {
							self.expertinfo.show = self.isValidTime();
						}
						if (
							self.firstTimeConnected &&
							this.ohlcv.length >= 10
						) {
							this.$nextTick(() => {
								setTimeout(
									function () {
										this.showtrade = true;
										this.outload();
									}.bind(this),
									100
								);
							});
							self.converWater(
								timeIndexMin,
								timeIndexSec < 30 ? "order" : "waiting"
							);
							self.MA(5, this.ohlcv, 0);
							self.MA(20, this.ohlcv, 1);
							// self.caculatorTime(timeIndexSec);
							self.firstTimeConnected = false;
						} else {
							if (
								self.transaction.timeRemain != timeIndexSec &&
								this.soundsE
							) {
								self.createjs.Sound.play("ting");
							}

							self.transaction.timeRemain = timeIndexSec;

							if (timeIndexSec < 30) {
								this.transaction.round = "order";
								self.disabledBTN = false;
							} else {
								this.transaction.round = "waiting";
								self.disabledBTN = true;
								if (59 - timeIndexSec == 50) {
									if (this.soundsE) {
										self.createjs.Sound.play("ten");
									}
								}
							}
						}
						if (self.BuySell) {
							let timex =
								dataSocket.closeTime +
								(0 - timeIndexSec) * 1000;
							self.BuySell = false;
							if (this.amountBet.sell > 0) {
								this.onchart[2].data[0] = [
									timex > 0 ? timex : 30,
									"↓" + this.amountBet.sell,
									0,
									"#dc3545",
									0.87,
								];
							}
							if (this.amountBet.buy > 0) {
								this.onchart[2].data[1] = [
									timex > 0 ? timex : 30,
									"↑" + this.amountBet.buy,
									1,
									"#28a745",
									0.87,
								];
							}
						}
						if (
							this.ohlcv != [] &&
							this.ohlcv != undefined &&
							this.ohlcv[this.ohlcv.length - 1] &&
							this.ohlcv[this.ohlcv.length - 1][4]
						) {
							let data = dataSocket; //close data every second

							var num = this.ohlcv.length - 1;
							// var temp = this.ohlcv[num][4];

							this.ohlcv[num][1] = data.open;
							this.ohlcv[num][2] = data.high;
							this.ohlcv[num][3] = data.low;
							this.ohlcv[num][5] = data.volume;

							this.offchart[0].data[num] = this.ohlcv[num];
							this.ohlcv[num][4] = data.close;
							if (this.ohlcv[num][4] < this.ohlcv[num][1]) {
								self.classInfo = "info-red";
								self.upDownColor = "down";
								self.mahh = "-";
							}
							if (this.ohlcv[num][4] > this.ohlcv[num][1]) {
								self.classInfo = "info-green";
								self.upDownColor = "up";
								self.mahh = "+";
							}
							if (self.widthL >= 768) {
								this.updatedData++;
							}
							// var interval = 40;
							// var time = 900;

							// var difference = (temp - data.close) / interval;

							// for (let i = 0; i < interval; i++) {
							// 	if (i % 5 == 0) {
							// 		clearTimeout(self.timeOutSlide);
							// 	}
							// 	self.timeOutSlide = setTimeout(() => {
							// 		let diff = temp - i * difference;
							// 		this.ohlcv[num][4] = diff;
							// 		if (this.ohlcv[num][4] < this.ohlcv[num][1]) {
							// 			self.classInfo = 'info-red';
							// 		}
							// 		if (this.ohlcv[num][4] > this.ohlcv[num][1]) {
							// 			self.classInfo = 'info-green';
							// 		}
							// 		if (self.widthL >= 768) {
							// 			this.updatedData++;
							// 		}
							// 	}, time / interval);
							// }

							//back to index charts
							if (self.transaction.timeRemain == 0) {
								this.newKey += 1;
								if (this.newKey == 2) {
									this.updataC += 1;
									this.newKey = 0;
								}
							}
						}
						// this.ohlcv = ohlcv;
						if (timeIndexSec == 0) {
							self.userOwner = [];
							if (
								this.amountBet.buy > 0 ||
								this.amountBet.sell > 0
							) {
								this.$store.commit("trade/HISTORY_TRADE", {
									clearOpen: true,
								});
							}
							self.MA(5, ohlcv, 0);
							self.MA(20, ohlcv, 1);
							sessionStorage.removeItem("wx_bet_live");
							sessionStorage.removeItem("wx_bet_chart_live");
							this.amountBet.buy = 0;
							this.amountBet.sell = 0;
							this.onchart[2].data[0] = [];
							this.onchart[2].data[1] = [];
						}

						if (timeIndexSec == 4) {
							self.converHistoryByChart();
						}
						break;
					case "EndGame":
						self.profit(dataSocket);
						break;
					case "NewBetFromUser":
						if (dataSocket.symbolId == this.currentCoin.betSymbol) {
							self.userOwner.unshift({
								amount: dataSocket.amount,
								betType: dataSocket.betType,
								exchangeType: dataSocket.exchangeType,
								message: dataSocket.message,
								owner: self.randomUserId(dataSocket.owner),
								symbolId: dataSocket.symbolId,
								time: dataSocket.time,
							});
							self.betAmount[dataSocket.symbolId][
								dataSocket.betType
							] += dataSocket.amount;
						}

						break;
					case "BalanceChange":
						this.$store.commit("userinfo/setBalace", {
							live: true,
							balance: dataSocket.balance,
						});
						break;
					case "error":
						if (!dataSocket === "skip this bet!") {
							this.$toastr.e(dataSocket);
						}

						break;
					case "BetResult":
						self.convertBetResult(dataSocket);
						break;
					case "Disconnect":
						self.logout();
						break;
					case "onStartZoom":
						self.expertinfo.isStartZoom = true;
						if (dataSocket !== 0) {
							self.expertinfo.startZoomBalance = dataSocket;
						}
						break;
					case "onStopZoom":
						self.expertinfo.isEndZoom = true;
						break;
				}
			};
		},
		convertBalance(userinfo) {
			this.$store.commit("userinfo/setBalace", {
				live: true,
				balance: userinfo.tempBalance,
			});
		},
		profit(result) {
			this.win_amount = result.payMoney;
			if (this.soundsE) {
				this.createjs.Sound.play(result.payMoney > 0 ? "win" : "lose");
			}
			this.$store.commit("userinfo/setBalace", {
				live: true,
				balance: result.balance,
			});
			this.$nextTick(() => {
				this.show_popup_profit = true;
			});
			setTimeout(
				function () {
					this.show_popup_profit = false;
				}.bind(this),
				5000
			);
		},
		convertBetResult(result) {
			let self = this;
			let newmessage = "";
			if (
				result.tempBalance !== undefined &&
				result.tempBalance !== null
			) {
				this.$store.commit("userinfo/setBalace", {
					live: true,
					balance: result.tempBalance,
				});
			}
			this.userOwner.unshift({
				amount: result.amount,
				betType: result.betType,
				exchangeType: "binance.com",
				message: "new bet from user",
				owner: this.user.user,
				symbolId: result.symbol,
				time: new Date().getTime(),
			});
			switch (result.betType) {
				case "GREEN":
					self.amountBet.buy += result.amount;
					newmessage = "You Are Buy $" + result.amount + "";
					break;
				case "RED":
					self.amountBet.sell += result.amount;
					newmessage = "You Are Sell $" + result.amount + "";
					break;
			}
			this.transaction.value = result.amount;
			if (result.tempBalance < 1) {
				this.transaction.value = 0;
			}
			this.BuySell = true;
			this.$toastr.s(newmessage, "You Are Betting Successfully");
			this.$nextTick(() => {
				setTimeout(
					function () {
						this.setLocalBet();
						this.getRoundBet();
					}.bind(this),
					200
				);
			});
		},
		betBuy: async function () {
			if (parseInt(this.transaction.value) <= 0) {
				this.$toastr.e(
					"Please Enter An Integer Value That Is Greater Than Or Equal To 1",
					"Betting Failed!"
				);
				return;
			}

			if (
				parseInt(this.transaction.value) > this.user.accounts[1].balance
			) {
				this.$toastr.e("Your Balance Is Not Enough", "Betting Failed!");
				return;
			}
			let self = this;
			this.bet_data.data.userId = this.user.user;
			this.bet_data.data.betType = "GREEN";
			this.bet_data.data.amount = parseInt(this.transaction.value);
			this.$socket.sendObj(this.bet_data);
			self.disabledBTN = true;
		},
		betSell: async function () {
			if (parseInt(this.transaction.value) <= 0) {
				this.$toastr.e(
					"Please Enter An Integer Value That Is Greater Than Or Equal To 1",
					"Betting Failed!"
				);
				return;
			}
			if (
				parseInt(this.transaction.value) > this.user.accounts[1].balance
			) {
				this.$toastr.e("Your Balance Is Not Enough", "Betting Failed!");
				return;
			}
			let self = this;
			this.bet_data.data.userId = this.user.user;
			this.bet_data.data.betType = "RED";
			this.bet_data.data.amount = parseInt(this.transaction.value);
			this.$socket.sendObj(this.bet_data);
			self.disabledBTN = true;
		},
		onEventByExpert: async function (type) {
			if (type == "start") {
				this.$socket.sendObj({ action: "onStartZoom", data: {} });
				this.experEvent.start = true;
			}
			if (type == "end") {
				this.$socket.sendObj({ action: "onStopZoom", data: {} });
				this.experEvent.end = true;
			}
		},
		converWater(min, type) {
			var count = type == "order" ? 2 : 3;
			for (let i = count; i < min * 2; i = i + 2) {
				if (
					this.ohlcv.length > 2 &&
					this.ohlcv[this.ohlcv.length - i] &&
					this.ohlcv[this.ohlcv.length - i][1]
				) {
					if (
						this.ohlcv[this.ohlcv.length - i][1] >
						this.ohlcv[this.ohlcv.length - i][4]
					) {
						this.history.push(1);
					} else if (
						this.ohlcv[this.ohlcv.length - i][1] <
						this.ohlcv[this.ohlcv.length - i][4]
					) {
						this.history.push(0);
					} else if (
						this.ohlcv[this.ohlcv.length - i][1] ==
						this.ohlcv[this.ohlcv.length - i][4]
					) {
						this.history.push(2);
					}
				}
			}
			this.history = this.history.reverse();
		},
		converHistoryByChart() {
			if (this.history.length >= 60) {
				this.history = [];
			}
			if (
				this.ohlcv[this.ohlcv.length - 2][1] >
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(1);
			} else if (
				this.ohlcv[this.ohlcv.length - 2][1] <
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(0);
			} else if (
				this.ohlcv[this.ohlcv.length - 2][1] ==
				this.ohlcv[this.ohlcv.length - 2][4]
			) {
				this.history.push(2);
			} else {
				this.history.push(3);
			}
		},
		MA(date, data, pos) {
			var i,
				value = 0,
				multiplier = 0;
			var ema = [];
			var daten = date + 1;
			multiplier = 2 / daten;
			for (i = 0; i < data.length; i++) {
				if (ema && ema.length > 0) {
					value =
						data[i][4] * multiplier +
						ema[i - 1][1] * (1 - multiplier);
				} else {
					value = data[i][4] * multiplier;
				}
				ema.push([data[i][0], value]);
			}
			this.onchart[pos].data = ema;
		},
		checkUndefined(x) {
			if (x === undefined) {
				return false;
			}
			return true;
		},
		getRoundBet() {
			const detail = this.$cookies.get("wx_bet_live");
			let time = new Date().getMinutes();
			if (detail && detail.time == time) {
				this.amountBet = detail.value;
			} else {
				this.amountBet.buy = 0;
				this.amountBet.sell = 0;
				this.$cookies.remove("wx_bet_live");
			}
			let onChartBet = this.$cookies.get("wx_bet_chart_live");
			if (onChartBet && onChartBet.time == time) {
				this.onchart[2].data = onChartBet.value;
			} else {
				this.amountBet.buy = 0;
				this.amountBet.sell = 0;
				this.$cookies.remove("wx_bet_chart_live");
			}
		},
		setLocalBet() {
			var bet = { value: this.amountBet, time: new Date().getMinutes() };
			var betchart = {
				value: this.onchart[2].data,
				time: new Date().getMinutes(),
			};
			this.$cookies.set("wx_bet_live", JSON.stringify(bet), "2MIN");
			this.$cookies.set(
				"wx_bet_chart_live",
				JSON.stringify(betchart),
				"2MIN"
			);
		},
		caculatorTime(time) {
			let self = this;
			var caser = "";
			//set current time
			this.transaction.timeRemain = time;

			//set case
			if (time > 29) {
				caser = "waiting";

				this.transaction.round = "waiting";
			} else {
				caser = "order";

				this.transaction.round = "order";
			}

			//case switch
			switch (caser) {
				case "order":
					clearInterval(self.intervalTime);
					self.intervalTime = setInterval(
						function () {
							if (29 - self.transaction.timeRemain == 0) {
								clearInterval(self.intervalTime);
							}
							this.disabledBTN = false;
							self.transaction.timeRemain++;
							if (this.soundsE) {
								self.createjs.Sound.play("ting");
							}
						}.bind(this),
						1000
					);
					break;
				case "waiting":
					clearInterval(self.intervalTime);
					self.intervalTime = setInterval(
						function () {
							if (59 - self.transaction.timeRemain <= 0) {
								clearInterval(self.intervalTime);
							}

							this.disabledBTN = true;
							self.transaction.timeRemain++;
							if (this.soundsE) {
								self.createjs.Sound.play("ting");
							}
							if (self.transaction.timeRemain == 50) {
								if (this.soundsE) {
									self.createjs.Sound.play("ten");
								}
							}
						}.bind(this),
						1000
					);
					break;
			}
		},
		CheckDevid() {
			if (
				navigator.userAgent.indexOf("like Mac") != -1 ||
				navigator.userAgent.indexOf("Android") != -1
			) {
				// document.getElementById('app').requestFullscreen();
				// setTimeout(function () {
				// 	// Hide the address bar!
				// 	window.scrollTo(0, 100);
				// }, 0);
				this.hideAddressBar();
			}
		},
		hideAddressBar() {
			if (!window.location.hash) {
				if (document.height < window.outerHeight)
					document.body.style.height = window.outerHeight + 50 + "px";
				setTimeout(function () {
					window.scrollTo(0, 1);
					document.body.style.height = "auto";
				}, 50);
			}
		},
		truncateToDecimals(num, dec = 2) {
			const calcDec = Math.pow(10, dec);
			return Math.trunc(num * calcDec) / calcDec;
		},
		disconnectWSS: async function () {
			this.$disconnect();
			this.$options.sockets.onclose = () => {};
			this.connected = false;
		},
		logout() {
			this.$toastr.w(
				"Please Login Again",
				"Something Wrong & Session Timeout"
			);
			this.$store.commit("auth/LOGOUT_SUCCESS");
			this.$router.push("/login");
		},
		isValidTime(indexTime) {
			const time = this.getTime(new Date().getTime());
			const format = "hh:mm:ss";
			const expert = this.expertinfo;
			const start = moment(expert.startTime, format);
			const end = moment(expert.endTime, format);
			const isBetween = moment(time, format).isBetween(start, end);
			return isBetween;
		},
		converExportJson(experInfo) {
			const t = this;
			this.expertinfo = {
				startTime: t.getTimeD(experInfo.startTime),
				endTime: t.getTimeD(experInfo.endTime),
				isStartZoom: experInfo.isStartZoom,
				isEndZoom: experInfo.isEndZoom,
				startZoomBalance: experInfo.startZoomBalance,
				show: false,
			};
		},
		getTime(unix) {
			return moment
				.unix(unix / 1000)
				.utcOffset("+00:00")
				.format("hh:mm:ss");
		},
		getTimeD(unix) {
			return `${moment
				.unix(unix / 1000)
				.utcOffset("+00:00")
				.hours()}:00:00`;
		},
		TruncateToDecimals2(num, mah, dec = 2) {
			var calcDec = Math.pow(10, dec);
			var newNum = Math.trunc(num * calcDec) / calcDec;
			const d = newNum.toString().split(".")[1];
			const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
			return `${mah ? mah : ""} ${newNum
				.toFixed(precision)
				.replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
		},
		percentUpDown(close, open) {
			let updown = Math.abs(open - close);
			let percent = (updown / close) * 100;
			return `${this.mahh} ${percent.toFixed(3)}%`;
		},
		priceUpdown(close, open) {
			let updown = Math.abs(open - close);
			return this.TruncateToDecimals2(updown, this.mahh);
		},
		getLowHighBinance: function () {
			const self = this;
			var socket = new WebSocket(
				"wss://stream.binance.com:9443/ws/btcusdt@ticker"
			);
			socket.onmessage = function (event) {
				var data = JSON.parse(event.data);
				self.LowHightVol = {
					min: data["l"],
					max: data["h"],
				};
			};
		},
	},
	async mounted() {
		moment.suppressDeprecationWarnings = true;
		let self = this;
		window.onload = function () {
			document.getElementById("app").click();
		};
		this.getLowHighBinance();
		this.checkResizeWindow();
		this.windowEvent = () => {
			this.checkResizeWindow();
		};
		window.addEventListener("resize", this.windowEvent);
		// simulate load data
		this.CheckDevid();
		this.onload();
		window.onresize = function () {
			self.CheckDevid();
			self.widthL = window.innerWidth;
			self.tradingChart.width = self.widthL - 50;
			self.widthL = window.innerWidth;
			let heightApp =
				document.getElementById("app-reponsive").clientHeight;
			if (window.innerHeight <= 480) {
				self.tradingChart.height = 250;
			} else if (window.innerWidth >= 1024 && window.innerHeight <= 800) {
				self.tradingChart.height = window.innerHeight - 355;
			} else if (window.innerWidth <= 768 && window.innerWidth > 480) {
				self.tradingChart.height = heightApp - 440;
			} else if (window.innerWidth <= 992 && window.innerWidth >= 768) {
				self.tradingChart.height = heightApp - 460;
			} else if (window.innerWidth <= 480) {
				self.tradingChart.height = heightApp - 460;
			} else {
				self.tradingChart.height = window.innerHeight - 430;
			}
		};
	},
	beforeDestroy() {
		// this.setLocalBet();
		this.disconnectWSS();
		clearInterval(this.intervalTime);
		clearTimeout(self.timeOutSlide);
	},
	destroyed() {
		window.removeEventListener("resize", this.windowEvent);
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap");
/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}
.box table td.border-right-none {
	border-right: none !important;
}
.box table td.border-left-none {
	border-left: none !important;
}
/* Track */
::-webkit-scrollbar-track {
	background: #000;
}
.bg-transparent {
	background: transparent !important;
}
.fx-12 {
	font-size: 12px !important;
}
.box .table {
	width: 100%;
	max-width: 100%;
}
.tableWrapper.box {
	height: 100%;
	border: none;
	width: 100%;
	max-width: 800px;
	margin: auto;
	border-radius: 5px !important;
	overflow-x: auto;
	position: relative;
	min-height: 150px;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: 15px;
	max-height: 200px;
	background: rgb(35 31 32 / 0.6);
	border: 1px solid #00ff85;
}
.box thead tr {
	background: #fff0 !important;
}
.box .table th {
	background: #00ff85 !important;
	color: rgb(252, 252, 252) !important;
	font-weight: 600;
	text-transform: uppercase;
	position: sticky;
	top: 0;
}
.box table th,
.box table td {
	border: 0px solid #00ff85 !important;
	border-bottom: 1px solid rgba(51, 51, 51, 0.35) !important;
	background: #fff0 !important;
	color: #fff;
}
.no-border {
	border: none !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
}
.height-70 {
	height: 70px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.btn_sell {
	background-image: url("~@/assets/images/sell_new.png");
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	text-align: left;
	text-transform: uppercase;
}
@media (max-width: 992px) {
	.tableWrapper.box {
		max-height: 160px;
	}
}
@media (max-height: 800px) and (min-width: 1024px) {
	.sidePanel.box-right {
		height: auto !important;
	}
	.mainPanel .tradingChartWrapper {
		height: calc(100vh - 355px) !important;
	}
}
@media (max-height: 480px) {
	.mainPanel .tradingChartWrapper {
		height: auto !important;
	}
}
.btn_buy {
	background-image: url("~@/assets/images/buy_new.png");
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	text-align: left;
	text-transform: uppercase;
}
.privateTransaction {
	height: calc(100vh - 50px);
	overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1024px) {
	.llg-7 {
		flex: 0 0 58.3333333333% !important;
		max-width: 58.3333333333% !important;
	}
	.llg-5 {
		flex: 0 0 41.6666666667% !important;
		max-width: 41.6666666667% !important;
	}
}
// @media (min-width: 768px) {
.privateTransaction {
	// background-image: url("~@/assets/images/background/bg.jpg");
	// background-size: cover;
	// background-position: center center;
	// background-repeat: no-repeat;
	// background-attachment: fixed;
	position: relative;
}
// }
// @media (max-width: 768px) {
//   .privateTransaction {
//     background-image: url("~@/assets/images/background/bg.jpg");
//     background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
//     position: relative;
//   }
// }
.privateTransaction:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0, 0.5);
	z-index: 1;
}
.position-relative {
	position: relative;
}
.z-index-2 {
	z-index: 2;
}
.bg-form {
	background: rgb(0, 0, 0, 0.3);
	padding: 15px 10px 25px;
	margin-bottom: 0 !important;
}
.box-right {
	background-color: #8585854a !important;
	background-position: top center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	border: 1px #eeeeee61 solid;
	z-index: 2;
}
.bg-form2 {
	margin-top: -80px;
	border-radius: 20px;
	background: rgb(0, 0, 0, 0.3);
	padding: 15px 10px 10px;
}
.bg-form2 > label {
	padding-top: 60px;
	text-align: center;
	text-transform: uppercase;
	font-size: 22px;
	display: block;
}
.predictValue {
	color: $colorTemplate;
}
.mainPanel {
	width: 100%;

	// trading chart
	.tradingChartWrapper {
		position: relative;
		height: calc(100vh - 420px);

		.tradingChartLabel {
			height: 50px;
			border: 1px solid $primaryColor;
			display: inline-block;
			background-image: url("~@/assets/images/transaction/bitcoin.png");
			background-size: 36px 36px;
			background-position: 7px 7px;
			background-repeat: no-repeat;
			font-size: 1.2em;
			font-weight: bold;
			line-height: 50px;
			padding-left: 50px;
			padding-right: 10px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
		}
	}

	// summary
	.summary {
		.rateChartList {
			margin-left: auto;
			margin-right: auto;
			max-width: 320px;
			width: 100%;

			.secondary {
				width: 30%;
			}

			.primary {
				width: 40%;
			}

			.rateChartWrapper {
				padding: 10px;

				.rateChart {
					width: 100%;
				}
			}
		}

		.lastResult {
			position: relative;

			.info {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				align-self: center;
				.value {
					display: block;
					height: 20px;
					font-size: 0.8em;
					text-transform: uppercase;
					padding: 0 10px;
					background-color: #212121;
					margin: 5px;
					font-weight: bold;
				}

				.increase {
					.label {
						color: #52ef00;
					}
				}

				.decrease {
					.label {
						color: #ff0000;
					}
				}

				.draw {
					.label {
						color: #f4e825;
					}
				}
			}
		}

		.dotGraph {
			display: flex;
			justify-content: space-around;

			.dotGroup {
				width: 95px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				max-height: 150px;

				.dot {
					width: 15px;
					height: 15px;
					margin: 2px;
					border-radius: 10px;
					background-color: #ccc;
				}

				.dot.increase {
					background-color: #28a745;
				}
				.dot.draw {
					background-color: #f4e825;
				}

				.dot.decrease {
					background-color: #dc3545;
				}
			}
		}
	}
}
.btn-outline-primary {
	color: #fff;
	border-color: $colorTemplate;
}
.sidePanel.right {
	width: 240px;
	margin: -10px -10px 0 15px;
	background: #2b2f42;
	border-radius: 0;
}
.sidePanel {
	border-radius: 0.5em;
	padding: 10px;
	margin: -10px;

	.transactionValue:before {
		content: "$";
		position: absolute;
		top: 1px;
		left: 5px;
	}

	.transactionValue {
		border: 1px solid $colorTemplate;
		line-height: 40px;
		padding: 0 10px 0 20px;
		font-size: 1.3em;
		font-weight: bold;
		position: relative;

		input {
			background-color: transparent;
			border: none;
			color: inherit;
			font-size: inherit;
			width: 100%;
			outline: none;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type="number"] {
			-moz-appearance: textfield;
		}
		.clearValue {
			background: #00ff85;
			width: 26px;
			height: 26px;
			padding: 8px;
			text-align: center;
			position: absolute;
			top: 7px;
			right: 7px;
			border: none;

			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}

	.addValue {
		margin-top: 5px;
		.sampleValue {
			width: calc(33.33% - 1.67px);
			margin-top: 5px;
		}
	}

	.predictValue {
		font-weight: bold;

		.currentRatio {
			font-size: 1.2em;
		}

		.sign,
		.predictValue {
			font-size: 1.8em;
		}
	}

	.transactionAction {
		.info {
			border: 1px solid $primaryColor;
			font-size: 1.1em;
			padding: 10px 0;
			line-height: 1em;
			border-radius: 0.3em;
			text-align: center;
			margin: 5px 0;
		}

		.action {
			font-size: 1.2em;
			font-weight: bold;

			img {
				width: 50px;
				margin-left: 10px;
			}
		}
	}
	.info-red {
		background-color: #dc3545;
	}
	.info-green {
		background: #28a745;
	}
	.transactionAction {
		.bet.action:disabled {
			background: #65757d;
			border: 1px solid black;
		}
	}
}

/*** RESPONSIVE ON PC ***/

@media (min-width: 992px) {
	.privateTransaction {
		height: calc(100vh - 0px);
		overflow: hidden;
	}
	.info .span.action.bet {
		height: 50px;
	}
	.mainPanel {
		width: calc(100% - 240px);

		// summary
		.summary {
			.rateChartList {
				max-width: 640px;
			}

			.lastResult {
				.info {
					.value {
						display: inline-block;
						line-height: 30px;
						height: 30px;
						font-size: 1em;
					}
				}
			}

			.dotGraph {
				padding: 30px 0;
				max-width: 1000px;
				margin: auto;

				.dotGroup {
					width: 150px;

					.dot {
						width: 20px;
						height: 20px;
						margin: 2px;
						border-radius: 10px;
					}
				}
			}
		}
	}

	.sidePanel {
		width: 240px;
		margin: 10px 0px 0 15px;
		height: calc(100vh - 130px);
		overflow-y: auto;
	}
}
@media (max-width: 991px) {
	.d-md-grid {
		display: grid;
	}
	.mainPanel {
		grid-row-start: 1;
		padding-top: 10px;
	}
	.privateTransaction {
		height: auto;
		overflow-y: auto;
	}
	.sidePanel.right {
		display: none !important;
	}
}

.notify-win {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: #0000009c;
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;

	.body-noti {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 0;
		word-wrap: break-word;
		background-clip: border-box;
		border-radius: 4px;
		background: #00000096;

		img {
			max-width: 150px;
			max-width: auto;
		}

		.content {
			text-align: center;
			font-size: 25px;
			font-family: "Yusei Magic", sans-serif;
			p.buy {
				text-align: center;
				font-size: 70px;
				color: #10ff10;
			}

			p.sell {
				text-align: center;
				font-size: 80px;
				color: #d20000;
			}
			p.amount-win {
				text-align: center;
				font-size: 60px;
				color: #10ff10;
			}
			p.amount-lose {
				text-align: center;
				font-size: 60px;
				color: #d20000;
			}
		}
	}
}
.customer-drop {
	height: 45px;
	position: absolute;
	z-index: 1090;

	button {
		padding: 4px !important;
	}
}
.customer-items {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 0;
}

@media (max-width: 991px) {
	.dotGraph {
		max-width: 500px;
		margin: auto;
		.dotGroup {
			max-height: 110px !important;
		}
	}
	.sidePanel {
		margin-top: 10px !important;
	}
	.mainPanel {
		.tradingChartWrapper {
			height: auto;
		}
	}
	.mainPanel .summary .lastResult .info {
		display: flex;
		top: -30px;
		right: 15px;
	}
	.customer-drop {
		height: 35px;
		top: -7px;

		.coinImage img {
			width: 25px;
		}
	}
}
@media (max-width: 768px) {
	.mainPanel {
		.tradingChartWrapper {
			height: auto;
		}
	}

	.privateTransaction {
		padding-top: 0.5rem !important;
	}

	.notify-win .body-noti .content p.buy {
		font-size: 33px;
	}
	.info.pb-3,
	.py-3 {
		padding-bottom: 0.5rem !important;
	}
	.box-right:after {
		height: calc(100% - 10px);
	}
	.info.pt-3,
	.py-3 {
		padding-top: 0.5rem !important;
	}
	.notify-win .body-noti .content p.sell {
		font-size: 52px;
	}
	.notify-win .body-noti .content p.amount-win {
		font-size: 46px;
	}
	.notify-win .body-noti .content p.amount-lose {
		font-size: 42px;
	}
	.mainPanel .summary .lastResult .info {
		display: flex;
		top: 0;
		right: 15px;
		position: relative;
	}
}
@media (max-width: 480px) {
	.transactionAction button.pb-3,
	.py-3 {
		padding-bottom: 0.8rem !important;
	}

	.transactionAction button.pt-3,
	.py-3 {
		padding-top: 0.8rem !important;
	}
	.box-right {
		height: 100%;
	}
	span.action.bet {
		height: 35px;
		font-size: 16px;
		margin-bottom: 0.5rem !important;
		padding: 5px;
	}
	span.action.bet.btn_buy {
		background-image: url("~@/assets/images/buy_new.png") !important;
	}
	span.action.bet.btn_sell {
		background-image: url("~@/assets/images/sell_new.png") !important;
	}
	.sidePanel.box-right .d-flex .btn.flex-30p {
		flex: 0 0 20% !important;
	}
	.tableWrapper.box {
		max-height: 140px;
		min-height: 100px;
	}
	.mainPanel .summary .lastResult .info {
		right: 0;
	}
	.btn_buy {
		background-image: none !important;
		background-color: #52ef00;
		text-align: center !important;
	}
	.btn_sell {
		background-image: none !important;
		background-color: #ff0000;
		text-align: center !important;
	}
	.dotGraph .dotGroup {
		max-height: 100px !important;
		width: 100% !important;
	}
	.mainPanel .summary .dotGraph .dotGroup .dot {
		width: 12px;
		height: 12px;
	}
	.mainPanel {
		.tradingChartWrapper {
			height: calc(100vh - 420px);
		}
	}
}
@media (max-width: 480px) {
	.mainPanel {
		.tradingChartWrapper {
			height: auto;
		}
	}
}
@media (max-width: 340px) {
	// .mainPanel {
	// 	.tradingChartWrapper {
	// 		height: calc(100vh - 442px);
	// 	}
	// }
}
@media (max-width: 321px) {
	// .mainPanel {
	// 	.tradingChartWrapper {
	// 		height: calc(100vh - 420px);
	// 	}
	// }
}
@media (max-width: 1200px) and (min-width: 992px) {
	.mainPanel .summary .dotGraph .dotGroup {
		max-height: 120px;
		.dot {
			width: 15px;
			height: 15px;
		}
	}
	span.action.bet {
		height: 40px;
		font-size: 16px;
		padding: 5px 10px;
	}
}
.text-draw {
	color: darkorange;
}
.owner-buy {
	color: #28a745;
}
.owner-sell {
	color: #dc3545;
}
</style>

<!-- GLOBAL CSS -->

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.privateTransaction {
	.mainPanel {
		.tradingChartWrapper {
			.tradingChart {
				.trading-vue-legend {
					top: 20px !important;
					margin-left: 0;
					font-weight: 500;
				}

				canvas {
					background-color: transparent !important;
				}
			}
		}

		.summary {
			.nav-tabs {
				border: none;

				.nav-item {
					.nav-link {
						border: none;
						color: white;
						background-color: transparent;
						position: relative;
					}

					.nav-link:after {
						content: " ";
						position: absolute;
						width: 100%;
						height: 1px;
						background-color: white;
						bottom: 0;
						left: 0;
					}

					.nav-link.active {
						font-weight: bold;
					}

					.nav-link.active:after {
						background-color: $primaryColor;
						height: 3px;
						bottom: -1px;
					}
				}
			}
		}
	}
}
.flex-40p {
	flex: 0 0 40% !important;
}
.flex-35p {
	flex: 0 0 35% !important;
}
.flex-30p {
	flex: 0 0 30% !important;
}
.tableWrapper.box:after {
	display: none;
}
span.action.bet {
	font-size: 25px;
	width: 100%;
	max-width: 180px;
	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 20px;
	font-weight: 700;
}
.stop-start-zoom {
	display: flex;
	margin-top: 30px;
	@media (min-width: 992px) {
		flex-direction: column;
		justify-content: center;
	}
	.start-zoom {
		min-height: 50px;
		margin-top: 5px;
		margin-bottom: 5px;
		flex: 1;
		@media (min-width: 992px) {
			flex: 0 0 100%;
		}
	}
	.end-zoom {
		min-height: 50px;
		margin-top: 5px;
		margin-bottom: 5px;
		flex: 1;
		@media (min-width: 992px) {
			flex: 0 0 100%;
		}
	}
}
.btn_btn_bet {
	color: #fff;
	border: 0 none !important;
	border-radius: 5px;
	padding-left: 10px;
}
.btn_btn_bet {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
}
.btn_btn_bet:hover,
.btn_btn_bet:focus,
.btn_btn_bet:active {
	-webkit-transform: scale(0.99);
	transform: scale(0.99);
}
.price-index {
	font-size: 18px;
	font-weight: 400;
}
.priceUpDown {
	display: flex;
	justify-content: flex-start;
	.label-percent {
		margin-right: 5px;
		color: #fff;
		height: 20px;
		width: 75px;
		display: flex;
		font-weight: 600;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		&.up {
			background: #25d64d;
		}
		&.down {
			background: #dc3545;
		}
	}
	.label-price {
		margin-left: 5px;
		color: #fff;
		font-weight: 600;
		&.up {
			color: #1aff4f !important ;
		}
		&.down {
			color: #ff0e25 !important ;
		}
	}
}
.priceStat {
	display: flex;

	.stat24 {
		display: flex;
	}
}
@media (max-width: 1199px) {
	.price-index {
		font-size: 25px;
		font-weight: 400;
	}

	.priceStat {
		display: flex;
		flex-direction: column;
		.stat24 {
			display: flex;
		}
	}
	.box-stat-tr {
		max-width: 400px;
		margin: auto;
	}
	.div-price-main {
		flex: 0 0 41%;
	}
	.priceStat {
		flex: 1 1 auto;
		text-align: right;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	.box-stat-tr {
		max-width: 600px;
		margin: auto;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.box-stat-tr {
		max-width: 600px;
		margin: auto;
	}
	.priceStat {
		font-size: 20px !important;
	}
	.priceUpDown {
		font-size: 20px !important;
		flex-direction: column !important;
		.label-price,
		.label-percent {
			width: 100%;
			max-width: 120px;
			text-align: left;
			display: flex;
			justify-content: flex-start;
			padding-left: 10px;
			margin-left: 0;
		}
	}
}
@media (max-width: 480px) {
	.privateTransaction .mainPanel .summary .nav-tabs .nav-item .nav-link {
		padding: 10px !important;
	}
}
@media (max-width: 1300px) and (min-width: 1200px) {
	.priceStat,
	.priceUpDown,
	.price-index {
		font-size: 15px !important;
	}
	.rounded.customer-drop {
		.mx-1 {
			margin-left: 3px !important;
			margin-right: 3px !important;
		}
	}
}
.priceUpDown span.mx-1 {
	color: #fff !important;
}
</style>
