<template>
  <div>{{ currency(truncateToDecimals(value)) }}</div>
</template>

<script>
export default {
  props: ["value", "precision"],
  methods: {
    currency(num) {
      if (this.symbol !== undefined) {
        var money = num
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0
          })
          .replace("$", " ");
        return money + " " + this.symbol;
      }
      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
      });
    },
    truncateToDecimals(num, dec = 2) {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    }
  }
};
</script>

<style></style>
