<template>
  <div class="privateAgency">
    <template
      v-if="Agency.isBuyAgency == null || Agency.isBuyAgency == undefined"
    >
      <Loader class="position-absolute" />
    </template>
    <template v-else>
      <template v-if="Agency.isBuyAgency == 1">
        <div class="agency-u d-flex align-items-center">
          <b-container>
            <b-row class="mb-2 align-items-center">
              <b-col cols="12" lg="5">
                <b-row>
                  <b-col cols="12" class="mb-4 mx-auto">
                    <div class="card-statis position-relative">
                      <div class="form-group text-center">
                        <label for="">{{ $t("agency.register_link") }}</label>
                        <div class="inputGroup" v-if="Agency">
                          <input
                            type="text"
                            v-model="Agency.link_ref"
                            readonly
                          />
                          <b-button
                            variant="primary"
                            v-clipboard:copy="Agency.link_ref"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            >{{ $t("agency.copy") }}</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-4 mx-auto">
                    <div
                      class="card-statis level-div"
                      v-if="Agency && Agency.Rank"
                    >
                      <div class="img">
                        <img
                          :src="Agency.Rank.Image"
                          alt=""
                          style="max-width: 120px"
                        />
                      </div>
                      <div
                        class="ib-text text-center mt-2 d-flex flex-column justify-content-around align-items-center align-self-center"
                      >
                        <div
                          class="ib-text text-center mt-2 d-flex flex-column align-items-center align-self-center"
                        >
                          <span class="name-ib">{{
                            $t("agency.level_agency")
                          }}</span>
                          <span class="name-ib">{{ Agency.Rank.Name }}</span>
                        </div>
                        <div class="hr d-block" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="7" class="mb-4">
                <div class="card-statis statis" v-if="Agency && Agency.Total">
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.f1s_total_trade") }}
                    </div>
                    <div class="right">{{ Agency.Total.F1Trade || 0 }}</div>
                  </div>
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.f1s_total_agency") }}
                    </div>
                    <div class="right">{{ Agency.Total.F1Agency || 0 }}</div>
                  </div>
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.total_commission") }}
                    </div>
                    <div class="right">{{ Agency.Total.Commission || 0 }}</div>
                  </div>
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.total_profit_company") }}
                    </div>
                    <div class="right">
                      {{ Agency.Total.ProfitCompany || 0 }}
                    </div>
                  </div>
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.pending_agency_commission") }}
                    </div>
                    <div class="right">
                      {{ Agency.Pending.Agency || 0 }}
                    </div>
                  </div>
                  <div class="box-statis">
                    <div class="left">
                      {{ $t("agency.pending_rank_commission") }}
                    </div>
                    <div class="right">
                      {{ Agency.Pending.Rank || 0 }}
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="historyTab overflow-hidden">
                  <div class="table-responsive historyTable">
                    <b-table
                      thead-class="thead-ig"
                      :striped="true"
                      :bordered="true"
                      :hover="true"
                      :items="Member.data"
                      :fields="fields"
                      :no-local-sorting="true"
                      @sort-changed="sortChanged"
                      show-empty
                    >
                      <template #empty="">
                        <h5 class="text-center p-2">
                          {{ $t("support.no_data_available_in_table") }}
                        </h5>
                      </template>
                      <template #cell(email)="data">
                        <p class="text-left text-lowercase">
                          {{ data.item.email }}
                        </p>
                      </template>
                    </b-table>
                  </div>

                  <div class="row px-3 align-items-center py-2 mb-5">
                    <div class="flex-fill">
                      {{ currentPage }} Page Of {{ Member.total || 0 }} Pages
                    </div>
                    <div
                      class="flex-fill button-div text-right d-flex justify-content-end"
                    >
                      <b-button
                        variant="primary"
                        :disabled="currentPage <= 1"
                        class="btn btn-aktrade mr-2"
                        @click.prevent="paginate(currentPage - 1)"
                        >{{ $t("agency.previous") }}</b-button
                      >
                      <b-button
                        variant="primary"
                        class="btn btn-aktrade"
                        :disabled="currentPage >= Member.total"
                        @click.prevent="paginate(currentPage + 1)"
                        >{{ $t("agency.next") }}</b-button
                      >
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </template>
      <template v-if="Agency.isBuyAgency == 0">
        <div class="main d-flex align-items-center">
          <b-container>
            <b-row class="align-items-center">
              <b-col class="left" cols="12" lg="6">
                <div class="title">{{ $t("agency.buy_agency") }}</div>
                <b-button variant="primary" class="button" v-b-modal.buyAgency
                  >{{ $t("agency.buy_now") }} $100</b-button
                >
                <b-modal
                  id="buyAgency"
                  :title="$t('ticket.join_package')"
                  hide-footer
                >
                  <div class="form-group text-center">
                    <img
                      :src="require('../assets/images/shake.png')"
                      style="width: 100%; max-width: 160px"
                    />
                  </div>
                  <div class="form-group text-center">
                    <label for="">{{
                      $t("agency.you_bought_an_agency_package")
                    }}</label>
                  </div>
                  <div
                    class="d-flex justify-content-center align-items-center submit"
                  >
                    <b-button
                      variant="primary"
                      class="buttonSubmit"
                      @click="onBuyPackage"
                      >{{ $t("agency.buy_now") }}</b-button
                    >
                  </div>
                </b-modal>
              </b-col>
              <b-col class="right" cols="12" lg="6">
                <div class="form-group">
                  <label for="">{{ $t("agency.register_link") }}</label>
                  <div class="inputGroup" v-if="Agency">
                    <input type="text" v-model="Agency.link_ref" readonly />
                    <b-button
                      variant="primary"
                      v-clipboard:copy="Agency.link_ref"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      >{{ $t("agency.copy") }}</b-button
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">{{ $t("agency.sponsor_code") }}</label>
                  <div class="inputGroup" v-if="user">
                    <input
                      type="text"
                      v-model="user.user"
                      v-if="user.user"
                      readonly
                    />
                    <b-button
                      v-if="Agency && Agency.User && Agency.User.userId"
                      variant="primary"
                      v-clipboard:copy="Agency.User.userId"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      >{{ $t("agency.copy") }}</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="footer">
          <b-container>
            <b-row>
              <b-col class="d-flex box" cols="12" lg="4">
                <div class="icon">
                  <img src="@/assets/images/agency/icon-1.png" alt="Icon" />
                </div>
                <div class="info">
                  <div class="name">{{ $t("agency.invite_friends") }}</div>
                  <div class="desc">
                    {{
                      $t(
                        "agency.invite_friends_to_register_ig_trade_through_the_link"
                      )
                    }}
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex box" cols="12" lg="4">
                <div class="icon">
                  <img src="@/assets/images/agency/icon-1.png" alt="Icon" />
                </div>
                <div class="info">
                  <div class="name">{{ $t("agency.friends_sign_up") }}</div>
                  <div class="desc">
                    {{
                      $t(
                        "agency.friends_accept_the_invitation_complete_registration_and_play"
                      )
                    }}
                  </div>
                </div>
              </b-col>

              <b-col class="d-flex box" cols="12" lg="4">
                <div class="icon">
                  <img src="@/assets/images/agency/icon-1.png" alt="Icon" />
                </div>
                <div class="info" cols="12">
                  <div class="name">
                    {{ $t("agency.easily_get_commission") }}
                  </div>
                  <div class="desc">
                    {{
                      $t("agency.get_a_corresponding_proportion_of_commission")
                    }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    Loader
  },
  data() {
    return {
      currentPage: 1,
      sortBy: "",
      sortDesc: null
    };
  },
  computed: {
    ...mapState(["isLoad"]),
    ...mapGetters({
      Agency: "agency/Agency",
      user: "userinfo/User",
      Member: "agency/Member"
    }),
    fields() {
      return [
        {
          key: "userId",
          label: this.$t("agency.user_id"),
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "email",
          label: this.$t("agency.email"),
          sortable: true,
          class: "text-left text-middle"
        },
        {
          key: "ib_level",
          label: this.$t("agency.agency_level"),
          sortable: false,
          class: "text-center text-middle"
        },
        {
          key: "f",
          label: "F",
          sortable: true,
          class: "text-center text-middle"
        },
        {
          key: "total_trade",
          label: this.$t("agency.total_trade"),
          sortable: false,
          class: "pr-4 text-right text-middle"
        },
        {
          key: "ponser",
          label: this.$t("agency.parent"),
          sortable: true,
          class: "text-center text-middle"
        }
      ];
    }
  },
  watch: {
    Agency: {
      handler(newVal) {
        if (newVal.isBuyAgency) {
          this.outload();
        }
      }
    }
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    onCopy: function(e) {
      this.$toastr.s(e.text, this.$t("agency.copy"));
    },
    onError: function(e) {
      this.$toastr.e(e.text, this.$t("agency.failed_please_try_again"));
    },
    async onBuyPackage(amount) {
      const token = await this.genarateCaptChaV3("trade");
      if (!token) {
        this.$toastr.e("Too Fast! Please Wating Recaptcha.", "Withdraw Failed");
        return;
      }
      if (this.user.walletList[0].balance < amount) {
        this.$toastr.e("Your Balance Is Not Enough", "Withdraw Failed");
        return;
      }
      await this.$store.dispatch("agency/postBuyAgency", {
        amount: amount,
        token: token
      });
    },
    onGetMember: function() {
      this.$store.dispatch("agency/req_getMember");
    },
    paginate(page) {
      if (page > 0 && page <= this.Member.total) {
        this.currentPage = page;
        this.$store.dispatch("agency/req_getMember", {
          page: page,
          field: this.sortBy,
          sort: this.sortDesc ? "asc" : "desc"
        });
      }
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.$store.dispatch("agency/req_getMember", {
        page: this.currentPage,
        field: this.sortBy,
        sort: this.sortDesc ? "asc" : "desc"
      });
    }
  },
  created() {
    this.onGetMember();
    this.$store.dispatch("agency/req_getAgency");
  },
  async mounted() {
    // simulate load data
    this.onload();
    // await new Promise((resolve) => setTimeout(resolve, 300));
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";

// @media (min-width: 768px) {
  .privateAgency {
    // background-image: url("~@/assets/images/background/bg.jpg");
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    min-height: 100vh;
  }
// }
// @media (max-width: 768px) {
//   .privateAgency {
//     background-image: url("~@/assets/images/bg_mobile.jpg");
//     background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
//     min-height: 100vh;
//   }
// }
.main {
  min-height: calc(100vh - 220px);
  padding: 50px 0;

  .left {
    margin-bottom: 30px;

    .title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .button {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .right {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid $primaryColor;
    border-radius: 20px;
    padding: 30px 20px;

    .inputGroup {
      border: 1px solid $primaryColor;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      overflow: hidden;
      height: 50px;

      input {
        border: none;
        background-color: transparent;
        color: white;
        flex: 1 1 auto;
      }

      button {
        width: max-content;
        border-radius: 5px;
      }

      input:active,
      input:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.footer {
  background-color: #0000006b;
  padding: 20px 0;

  .box {
    margin: 20px 0;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      border: 1px solid $primaryColor;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: calc(100% - 80px);
      margin-left: 10px;

      .name {
        font-size: 1.1em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .main {
    .left {
      .title {
        font-size: 2.5em;
      }
    }
  }
}
.agency-u {
  min-height: calc(100vh - 75px);
  padding: 50px 0px;

  .ib-level {
    width: 150px;
    border: 1px solid white;
    padding: 15px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-self: center;
    border-radius: 50%;
    background: #0000007a;
    position: relative;

    .img {
      max-width: 150px;
      margin: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .name-ib {
    font-size: 1.5em;
    font-weight: bold;
  }

  .card-statis {
    background-color: rgb(35 31 32 / 0.6);
    border-radius: 14px;
    width: 100%;
    min-height: 150px;
    padding: 30px 20px;
    position: relative;
    border: 1px solid #27ab70;

    .inputGroup {
      border: 1px solid $primaryColor;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      overflow: hidden;
      height: 50px;

      input {
        border: none;
        background-color: transparent;
        color: white;
        flex: 1 1 auto;
      }

      button {
        width: max-content;
        border-radius: 5px;
      }

      input:active,
      input:focus {
        border: none;
        outline: none;
      }
    }
  }
  .card-statis.level-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: 150px;
  }
  .historyTab {
    border-radius: 5px;
    overflow: hidden;

    .historyTable {
      width: 100%;
      font-size: 1.1em;
      background-color: rgb(35 31 32 / 0.6);
      border: 1px solid #27ab70;

      thead {
        background: linear-gradient(90deg, 90deg, #04dbf1, #0039e4) !important;
        tr {
          th {
            color: black !important;
          }
        }
      }

      th,
      td {
        border: none;
        padding: 10px 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        border: 1px solid #ccc !important;
        background: #fff0 !important;
        color: #fff;
      }

      th {
        font-weight: bold;
        font-size: 1em;
      }
    }
  }
}
.card-statis.statis {
  padding: 0px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  .box-statis {
    padding: 10px 20px;
    font-size: 1.1em;
    font-weight: 600;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-statis:last-child {
    border-bottom: 0 none;
  }
}

.card-package {
  min-height: 350px;
  background: linear-gradient(
    to bottom,
    #fefbb2,
    #d2c88c,
    #fcdfa3,
    #fcbc65,
    #6f552f
  ) !important;
  margin: 0 2px;
  border: 0px solid #27ab70;
  width: 100%;
  padding: 15px;
  position: relative;
  margin: auto;
  border-radius: 15px;
  .button-bottom,
  .price,
  .maxout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 5px;
  }
  .button-bottom,
  .price .detail,
  .maxout .detail {
    border-top: 3px solid #ffffff38;
  }
  img {
    width: 100%;
    height: auto;
    filter: drop-shadow(1px 0px 11px black);
  }
}
.card-package:after {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 1;
  border-radius: 15px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #231f20;
}
.card-package * {
  z-index: 2;
}

@media (max-width: 991px) {
  .mt-75p {
    margin-top: 75px;
  }
}
@media (min-width: 992px) and (min-height: 800px) {
  .footer {
    position: fixed;
    bottom: 0;
    left: 100px;
    right: 0;
  }
}
.hr {
  width: 100%;
  height: 1px;
  margin: 2px auto 20px auto;
  background-color: #c0c0c0;
}
.text-lowcase {
  text-transform: lowercase;
}
</style>
