<template>
  <div class="privateLucky p-2">
    <b-container fluid>
      <b-row>
        <b-col cols="12" xl="6" class="tab-left">
          <div class="top-action">
            <div class="available-ticket">
              <div class="available-ticket-show align-items-center">
                <span style="    font-size: 20px;font-weight:800">
                  {{ Balance.Ticket }}
                </span>
                <span class="available-ticket-title ml-2">{{
                  $t("lucky.Ticket")
                }}</span>
              </div>
            </div>
            <div class="buy-ticket">
              <b-button
                variant="primary"
                class="btn-aktrade"
                v-b-modal.buyTicket
                >{{ $t("lucky.Immediately purchase") }}
              </b-button>
            </div>
          </div>
          <div class="box-spin">
            <img
              src="~@/assets/images/lucky/box.png"
              class="bg-spin-box"
              alt=""
            />
            <div class="wheel-wrapper">
              <div class="wheel-pointer">
                <img src="~@/assets/images/lucky/button.png" alt="" />
              </div>
              <div
                class="wheel-bg"
                :class="{ freeze: freeze }"
                :style="`transform: rotate(${wheelDeg}deg)`"
              >
                <div class="prize-list">
                  <div
                    class="prize-item-wrapper"
                    v-for="(item, index) in Reward"
                    :key="index"
                  >
                    <div
                      class="prize-item"
                      :style="
                        `transform: rotate(${(360 / prizeList.length) *
                          index}deg)`
                      "
                    >
                      <div class="prize-name">
                        {{ item }}
                      </div>
                      <!-- <div class="prize-icon">
                        <img :src="item.icon" />
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-button-action">
            <b-button
              variant="out-line-primary"
              class="btn-1"
              @click="onClickRotate(1)"
              >{{ $t("lucky.Spin") }}</b-button
            >
            <b-button
              variant="out-line-primary"
              class="btn-10"
              @click="
                onClickRotate(10);
                rolling = true;
              "
            >
              {{ $t("lucky.Spin x10") }}
            </b-button>
            <b-button
              variant="out-line-primary"
              class="btn-all"
              @click="
                onClickRotate(parseInt(Balance.Ticket));
                rolling = true;
              "
            >
              {{ $t("lucky.Spin All") }}
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" xl="6">
          <div class="box-balance-list">
            <b-row>
              <b-col cols="12" md="6">
                <div class="balance-box">
                  <div class="balance-name">
                    {{ $t("wallet.main_balance") }}
                  </div>
                  <div class="balance-value">
                    <CurrencyFormat class="price" :value="Balance.Main || 0" />
                  </div>
                  <div class="action"></div>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="balance-box">
                  <div class="balance-name">
                    {{ $t("lucky.modal.balance") }}
                  </div>
                  <div class="balance-value">
                    <CurrencyFormat
                      class="price"
                      :value="Balance.Voucher || 0"
                    />
                  </div>
                  <div class="action">
                    <b-button
                      variant="primary"
                      class="btn-aktrade "
                      v-b-modal.withdrawprofit
                    >
                      {{ $t("wallet.withdraw") }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="box-mission-list mt-5">
            <div
              class="box-mission mt-5"
              v-for="mission in Mission"
              :key="mission.id"
            >
              <div class="header-box">{{ mission.name }}</div>
              <div class="left-content-mission">
                <span class="mission-label ml-2"
                  >{{ $t("lucky.Mission") }}
                </span>
                <div class="content-left-m">
                  <img class="mission-icon" :src="mission.icon" alt="" />
                  <span class="mission-label"> {{ $t("lucky.Reward") }}</span>
                  <div class="mission-reward">
                    <img
                      :style="
                        mission.current >= mission.step
                          ? 'filter: grayscale(100%)'
                          : ''
                      "
                      class="mission-icon-2"
                      :src="require(`@/assets/images/lucky/ticket_${languageName||'gb-eng'}.png`)"
                      alt=""
                    />
                    <!-- <span>1 Vé May Mắn</span> -->
                  </div>
                </div>
              </div>
              <div class="right-content-mission">
                <div class="right-description mb-2 px-4 text-left">
                  {{ mission.description }}
                </div>
                <div class="progress-mission">
                  <span>{{ $t("lucky.Progress") }}</span>
                  <div class="progress-mission-main">
                    <div class="progress-container">
                      <span
                        class="lable-index-progress"
                        :style="
                          `left:${
                            (mission.current / mission.step) * 100 >= 100
                              ? 82
                              : (mission.current / mission.step) * 100 -
                                (mission.current / mission.step >= 90 ? -9 : 0)
                          }%`
                        "
                      >
                        {{ mission.current + " " + mission.unit }}
                      </span>
                      <div class="progress-in">
                        <div
                          class="current-value"
                          :style="
                            `width: ${(mission.current / mission.step) * 100}%`
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>0 {{ mission.unit }}</span>
                      <span>{{ mission.step + " " + mission.unit }}</span>
                    </div>
                  </div>
                </div>
                <div class="time-expired">
                  {{ $t("lucky.Expired") }}:
                  <span class="time-end ml-1">{{ mission.expired }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="buyTicket"
      hide-footer
      :title="$t('lucky.Immediately purchase')"
      size="sm"
    >
      <div class="text-center">
        <img
          src="~@/assets/images/lucky/ticketb.png"
          alt=""
          class="mx-auto"
          style="filter:drop-shadow(2px 3px 6px #ffffff52)"
        />
      </div>
      <div class="ticket-client">
        <div class="default-buy  my-2">
          <b-button
            variant="primary"
            :disabled="buytick <= 1"
            @click="buytick--"
          >
            <i class="fad fa-minus"></i>
          </b-button>
          <b-form-input
            class="bg-white color-dark text-center"
            variant="primary"
            min="1"
            step="1"
            require
            type="number"
            v-model.number="buytick"
          ></b-form-input>
          <b-button variant="primary" @click="buytick++">
            <i class="fad fa-plus"></i>
          </b-button>
        </div>
        <div class="avandce-buy mt-2 d-flex justify-content-center">
          <b-button
            variant="success"
            @click="buyNow()"
            :disabled="buytick <= 0"
            >{{
              $t("lucky.Immediately purchase") +
                " " +
                buytick * Settings.price +
                "$"
            }}</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="reward-show"
      hide-footer
      hide-header
      size="sm"
      @hidden="loadinfo()"
    >
      <div class="content-reward text-center">
        <img
          src="~@/assets/images/lucky/failed.png"
          v-if="reward.list.length == 1 && reward.id == 0"
          alt=""
        />
        <img v-else src="~@/assets/images/lucky/reward.png" alt="" />
        <button
          type="button"
          aria-label="Close"
          class="close btn-custome-close"
          @click="hidemodal('reward-show')"
        >
          ×
        </button>
        <div
          class="failed-label"
          v-if="reward.list.length == 1 && reward.id == 0"
        >
          <span class="sub-title">{{ $t("lucky.Better Luck Next Time") }}</span>
        </div>
        <div v-else-if="reward.list.length == 1 && reward.id == 10">
          <span class="label-reward"> {{ $t("lucky.CONGRATULATIONS") }} </span>
          <br />
          <span class="sub-title">{{ $t("lucky.1 Lucky ticket") }}</span>
        </div>
        <div v-else>
          <span class="label-reward"> {{ $t("lucky.CONGRATULATIONS") }} </span>
          <br />
          <span class="sub-title">{{
            $t("lucky.You have received the gift:")
          }}</span>
          <div
            class="d-flex align-items-center pl-2"
            v-for="(key, value) in reward.show"
            :key="key"
          >
            <div class="reward-name">
              {{ value }}
            </div>
            <span class="reward-subtitle">x{{ key }}</span>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="withdrawprofit" :title="$t('lucky.modal.title')" hide-footer>
      <form @submit.prevent="onWithdrawVoucher()">
        <div class="text-center text-warning ">
          {{ $t("lucky.modal.warning") }}
        </div>

        <div class="form-group">
          <label for="" class="display-block">{{
            $t("lucky.modal.maximum")
          }}</label>
          <input
            type="text"
            class="form-control"
            style="background:#ffffff4f"
            disabled
            :value="TruncateToDecimals(StatisLucky.max_withdraw)"
          />
        </div>
        <div class="form-group">
          <label for="" class="display-block">{{
            $t("lucky.modal.remain")
          }}</label>
          <input
            type="text"
            disabled
            class="form-control"
            style="background:#ffffff4f"
            :value="TruncateToDecimals(StatisLucky.remaining_withdraw)"
          />
        </div>
        <div class="form-group">
          <label for="" class="display-block">{{
            $t("lucky.modal.balance")
          }}</label>
          <input
            type="text"
            class="form-control"
            style="background:#ffffff4f"
            disabled
            :value="TruncateToDecimals(Balance.Voucher)"
          />
        </div>
        <div class="form-group">
          <label for="" class="display-block">{{
            $t("lucky.modal.amount")
          }}</label>
          <input
            type="number"
            class="form-control"
            v-model.number="withdraw.amount"
          />
        </div>
        <div class="form-group text-center">
          <b-button
            variant="primary"
            type="submit"
            :disabled="
              !withdraw.amount ||
                withdraw.amount <= 0 ||
                withdraw.amount > parseFloat(Balance.Voucher)
            "
          >
            {{ $t("wallet.withdraw") }}
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import CurrencyFormat from "@/components/CurrencyFormat.vue";
const axios = require("../store/config/axios.config").default;
export default {
  components: {
    CurrencyFormat
  },
  data() {
    return {
      freeze: false,
      rolling: false,
      wheelDeg: 0,
      buytick: 1,
      prizeNumber: 11,
      reward: {
        list: [],
        id: null,
        show: {}
      },
      withdraw: {
        amount: 0
      },
      aTerval: 0,
      languageName:'gb-eng'
    };
  },
  computed: {
    ...mapState(["isLoad"]),
    ...mapGetters({
      user: "userinfo/User",
      Balance: "lucky/Balance",
      Mission: "lucky/Mission",
      Reward: "lucky/Reward",
      Settings: "lucky/Settings",
      StatisLucky: "lucky/StatisLucky"
    }),
    prizeList() {
      return this.Reward.slice(0, this.prizeNumber);
    }
  },
  watch: {
    prizeNumber() {
      this.freeze = true;
      this.wheelDeg = 0;

      setTimeout(() => {
        this.freeze = false;
      }, 0);
    }
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    hidemodal(name) {
      this.$bvModal.hide(name);
    },
    async onWithdrawVoucher() {
      const token = await this.genarateCaptChaV3("lucky");
      if (!token) {
        this.$toastr.e(
          this.$t("toastr.Too Fast! Please Wating Recaptcha."),
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      if (this.Balance.Voucher < this.withdraw.amount) {
        this.$toastr.e(
          this.$t("toastr.Your balance is not enough!"),
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      this.$store.dispatch("lucky/req_postWithdraw", {
        token: token,
        amount: this.withdraw.amount
      });
    },

    async onClickRotate(quantiy) {
      const t = this;
      if (this.rolling) {
        return;
      }
         this.rolling = true;
      const token = await this.genarateCaptChaV3("lucky");
      if (!token) {
        this.$toastr.e(
          this.$t("toastr.Too Fast! Please Wating Recaptcha."),
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      if (quantiy > 500) {
        quantiy = 500;
        this.$toastr.s(this.$t("lucky.notification"), this.$t("app.success"));
      }
      if (t.Balance.Ticket < quantiy || t.Balance.Ticket <= 0) {
        this.$bvModal.show("buyTicket");
        return;
      }
      this.reward = {
        list: [],
        id: null,
        show: {}
      };
      this.wheelDeg += 1000;
      t.aTerval = setInterval(() => {
        this.wheelDeg += 1000;
      }, 10);
      await axios
        .post("spin/play", {
          quantity: quantiy,
          token: token
        })
        .then(res => {
          if (res) {
            clearInterval(t.aTerval);
            const { wheelDeg, prizeList } = this;
            this.wheelDeg =
              wheelDeg -
              (wheelDeg % 360) +
              6 * 360 +
              (360 - (360 / prizeList.length) * res.reward.id);
            setTimeout(() => {
              this.rolling = false;
              this.reward.list = res.reward.name;
              this.reward.id = res.reward.id;
              var count = {};
              this.reward.list.forEach(function(i) {
                count[i] = (count[i] || 0) + 1;
              });
              this.reward.show = count;
              this.$bvModal.show("reward-show");
            }, 4500);
          }
        });
    },
    loadinfo() {
      this.$store.dispatch("lucky/req_getInfo");
    },
    async buyNow() {
      const token = await this.genarateCaptChaV3("lucky");

      if (this.buytick <= 0) {
        this.$toastr.e(
          "Please Buy Ticket equal to than 1.",
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      if (!token) {
        this.$toastr.e(
          this.$t("toastr.Too Fast! Please Wating Recaptcha."),
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      if (this.Balance.Main < this.buytick * 5) {
        this.$toastr.e(
          this.$t("toastr.Your balance is not enough!"),
          this.$t("toastr.Buy Ticket Failed")
        );
        return;
      }
      this.$store.dispatch("lucky/req_postBuyTicker", {
        quantity: this.buytick,
        token: token
      });
    },
    roll(result) {
      this.rolling = true;
      const { wheelDeg, prizeList } = this;
      this.wheelDeg =
        wheelDeg -
        (wheelDeg % 360) +
        6 * 360 +
        (360 - (360 / prizeList.length) * result);
      setTimeout(() => {
        this.rolling = false;
        alert("Result：" + prizeList[result]);
      }, 4500);
    },
    TruncateToDecimals(num, mah, dec = 2) {
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      const d = newNum.toString().split(".")[1];
      const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
      return ` ${mah ? mah : ""}${newNum
        .toFixed(precision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  },
  async mounted() {
    // simulate load data
    this.onload();
    await new Promise(resolve => setTimeout(resolve, 300));
    this.outload();

    this.timeLoop = setInterval(() => {
      this.remainTime = this.remainTime > 0 ? this.remainTime - 1 : 0;
    }, 1000);
  },
  created() {
    this.languageName =  this.$i18n.locale;
    this.$store.dispatch("lucky/req_getInfo");
    const t = this;
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "lucky/BUY_SUCCESS":
          this.$bvModal.hide("buyTicket");
          break;
        case "core/ERROR_MESSANGE":
          clearInterval(t.aTerval);
          t.wheelDeg = 0;
          break;
        case "lucky/WITHDRAW_SUCCESS":
          this.$bvModal.hide("withdrawprofit");
          this.$store.dispatch("lucky/req_getInfo");
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  destroyed() {
    clearInterval(this.timeLoop);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";
$btn-1: linear-gradient(90deg,rgba(39, 171, 112, 1) 0%,rgba(0, 122, 74, 1) 54.19%,rgba(39, 171, 112, 1) 100%);
$btn-10: linear-gradient(90deg, #e91a33, #f05c37);
$btn-all: linear-gradient(90deg, #f4eb25, #f4c41b);
$bg-box: #000000;
$bg-box-header: linear-gradient(90deg,rgba(39, 171, 112, 1) 0%,rgba(0, 122, 74, 1) 54.19%,rgba(39, 171, 112, 1) 100%);
$bg-box-header-border: #00ff85;
$bg-process: linear-gradient(90deg, #e91a33, #f05c37);

.btn-10,
.btn-all,
.btn-1 {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  color: #fff !important;
}
.btn-10 {
  background: $btn-10;
}
.btn-all {
  background: $btn-all;
}
.btn-1 {
  background: $btn-1;
}
// common info
.privateLucky {
  background-image: url("~@/assets/images/lucky/background_2.png");
  background-size: cover;
  background-position: bottom center, top center;
  background-repeat: no-repeat, no-repeat;
  min-height: calc(100vh - 75px);
}
.tab-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  .box-spin {
    position: relative;
    height: 800px;
    width: 600px;
    .bg-spin-box {
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      position: absolute;
      z-index: 5;
    }

    .wheel-wrapper {
      width: 520px;
      height: 520px;
      position: absolute;
      top: 40px;
      left: 45px;
    }

    .wheel-pointer {
      width: 90px;
      height: 90px;
      border-radius: 1000px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1090;
      cursor: pointer;
      box-shadow: 1px 2px 12px 1px #000000b3;
      img {
        z-index: 1090;
        width: 100%;
        height: 100%;
      }
    }
    .wheel-bg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      transition: transform 4s ease-in-out;
      background: url(~@/assets/images/lucky/spin.png);
      background-size: 100%100%;
      &.freeze {
        transition: none;
        background: red;
      }
    }

    .prize-list {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
    }

    .prize-item-wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 260px;
      height: 260px;
    }

    .prize-item {
      width: 100%;
      height: 100%;
      transform-origin: bottom;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      align-self: center;

      .prize-name {
        padding: 5px 65px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        align-self: center;
        transform: rotate(89deg) translateY(3px);
      }

      .prize-icon {
      }
    }
  }
  .prize-item .prize-name {
    font-size: 25px !important;
    font-weight: 700;
  }
  .prize-list .prize-item-wrapper:first-child .prize-name {
    color: #333;
    font-size: 20px !important;
    padding: 10px 85px 15px 34px;
  }
  .div-button-action {
    width: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 15%;
    z-index: 10;

    .btn {
      width: 100px;
    }
  }
}

.box-mission-list {
  min-height: 700px;
  width: 100%;
  .box-mission {
    display: flex;
    min-height: 200px;
    background-color: $bg-box;
    padding: 5px;
    border-radius: 10px;
    position: relative;
    max-width: 700px;
    margin: auto;
    .header-box {
      height: 40px;
      font-size: 17px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      border: 1px solid $bg-box-header-border;
      position: absolute;
      width: 100%;
      max-width: 300px;
      border-radius: 5px;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
      background-image: url(~@/assets/images/lucky/bg-circle.png),
        $bg-box-header;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
    .left-content-mission {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      .mission-label {
        font-size: 20px;
        font-weight: 800;
        background: -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(0, 255, 102, 1) ),color-stop(0.5419,rgba(0, 122, 74, 1) ),color-stop(1,rgba(0, 255, 102, 1) ));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .content-left-m {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .mission-icon {
        height: auto;
        width: 75px;
      }
      .mission-icon-2 {
        height: auto;
        width: 120px;
      }
      .mission-reward {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .right-content-mission {
      flex: 0 0 75%;
      padding-top: 40px;
      .progress-mission {
        min-height: 75px;
        border: 2px solid $bg-box-header-border;
        padding: 2px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .progress-mission-main {
          position: relative;
          margin: auto;
          width: 90%;
          margin-top: 20px;
          .progress-container {
            height: 15px;
            width: 100%;
            .lable-index-progress {
              position: absolute;
              top: -25px;
              width: fit-content;
            }
            .progress-in {
              width: 100%;
              height: 100%;
              background: #fff;
              border-radius: 10px;
              overflow: hidden;

              .current-value {
                background: $bg-process;
                height: 15px;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
    .time-expired {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      font-weight: 600;
      font-size: 16px;
      align-items: center;
      .time-end {
        color: #f4eb25;
        font-weight: 800;
        font-size: 20px;
      }
    }
    .right-description {
      font-size: 17px;
      text-align: center;
    }
  }
}
.top-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .available-ticket {
    display: flex;
    margin-right: 20px;
    .available-ticket-title {
      font-size: 20px;
      font-weight: 800;
    }
    .available-ticket-show {
      height: 40px;
      font-size: 19px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      border: 1px solid $bg-box-header-border;
      border-radius: 5px;
      background-image: url(~@/assets/images/lucky/bg-circle.png),
        $bg-box-header;
      background-repeat: no-repeat;
      background-position: 0 center;
      max-width: 300px;
      padding-right: 15px;
      padding-left: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  .top-action {
    flex-direction: column;
    .available-ticket {
      margin-bottom: 5px;
      margin-right: 0;
    }
  }
  .box-balance-list {
    height: auto !important;
    &::after {
      content: unset !important;
    }
    .balance-box {
      height: 150px !important;
    }
  }
  .tab-left {
    .box-spin {
      height: 440px;
      width: 300px;
      .wheel-pointer {
        width: 55px;
        height: 55px;
      }
      .wheel-wrapper {
        width: 265px;
        height: 265px;
        position: absolute;
        top: 17px;
        left: 20px;

        .prize-item-wrapper {
          width: 132.5px;
          height: 132.5px;
        }
        .prize-item .prize-name {
          font-size: 18px !important;
          font-weight: 700;
        }
        .prize-list .prize-item-wrapper:first-child .prize-name {
          color: #333;
          font-size: 12px !important;
          padding: 10px 45px 15px 10px;
        }
      }
    }
    .div-button-action {
      flex-wrap: wrap;
      width: 100%;
      padding: 5px;
      bottom: 5%;
      .btn-1 {
        flex: 0 0 200px;
        margin: 5px;
      }
      .btn-10 {
        flex: 0 0 140px;
        margin-right: 5px;
      }
      .btn-all {
        flex: 0 0 140px;
        margin-left: 5px;
      }
    }
  }
  .box-mission {
    flex-direction: column;
    .left-content-mission {
      flex: 0 0 100%;
      padding-top: 30px;
    }
    .right-content-mission {
      flex: 0 0 100% !important;
      padding-top: 10px !important ;
    }
  }
}
.default-buy {
  display: flex;
  justify-content: space-around;
}
.box-balance-list {
  height: 150px;
  border-radius: 5px;
  background-color: black;
  width: 100%;
  max-width: 700px;
  margin: auto;
  position: relative;
  &::after {
    background: #f4d8203b;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 3px;
    height: 90%;
    border-radius: 3px;
  }
  .balance-box {
    width: 100%;
    height: 150px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .balance-name {
      height: 35px;
      font-size: 19px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      border: 1px solid $bg-box-header-border;
      width: 100%;
      border-radius: 5px;
      background-image: $bg-box-header;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
    .price {
      font-weight: 900;
      font-size: 20px;
    }
    .action {
      min-height: 40px;
      .btn {
        height: 30px;
      }
    }
  }
}
.btn {
  font-weight: 800 !important;
}
</style>
<style lang="scss">
#buyTicket {
  &.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: #333 !important;
  }
}
.default-buy .form-control {
  color: #333 !important;
  font-size: 20px;
  height: 40px;
  font-weight: 900;
}
#reward-show {
  &.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .modal-dialog {
      width: 315px !important;
      max-width: 320px !important;
    }
  }
  .modal-content {
    background: #fff;
    .content-reward {
      position: relative;
      .btn-custome-close {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .label-reward {
        font-size: 28px;
        font-weight: 800;
        background: -webkit-linear-gradient(0deg, #bfe400, #16943b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .sub-title {
        color: #333;
      }
      .reward-name {
        height: 35px;
        font-size: 19px;
        text-align: center;
        display: flex;
        align-items: center;
        width: fit-content;
        justify-content: center;
        font-weight: 800;
        border: 1px solid #bfe400;
        min-width: 100px;
        padding: 5px 5px;
        margin-top: 5px;
        border-radius: 5px;
        background-image: linear-gradient(90deg, #bfe400, #16943b);
        background-repeat: no-repeat;
        background-position: 0 center;
      }
    }
    .reward-subtitle {
      margin-left: 5px;
      color: #333;
      font-size: 20px;
      font-weight: 800;
    }
  }
}
</style>
