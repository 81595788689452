<template>
  <div class="tableWrapper table-responsive bg-transparent box">
    <table class="fx-12 table w-100 table-symbol bg-transparent no-border">
      <thead>
        <th class="">{{ $t("promotion_insurance.user_id") }}</th>
        <th class="">{{ $t("exchange.coins") }}</th>
        <th class="">{{ $t("exchange.order") }}</th>
        <th class="">{{ $t("exchange.amount") }}</th>
        <th class="">{{ $t("exchange.time") }}</th>
      </thead>
      <tbody>
        <tr
          v-for="users in owner.slice(0, 100)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(100, 200)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(200, 300)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(300, 400)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(500, 600)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(700, 800)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(800, 900)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(900, 1000)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
        <tr
          v-for="users in owner.slice(1000, -1)"
          :key="users.time + users.owner + users.amount"
        >
          <td class="border-left-none">
            {{ users.owner }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.symbolId }}
          </td>
          <td class="border-left-none">
            {{
              users.betType == "GREEN"
                ? $t("exchange.buy")
                : $t("exchange.sell")
            }}
          </td>
          <td :class="users.betType == 'GREEN' ? 'owner-buy' : 'owner-sell'">
            {{ users.amount }}
          </td>
          <td>
            {{ getDateTime2(users.time) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    owner: {
      type: Array,
      default: []
    }
  },
  methods: {
    getDateTime(unix) {
      return moment.unix(unix / 1000).format("HH:mm:ss - DD/MM/YYYY");
    },
    getDateTime2(unix) {
      return moment
        .unix(unix / 1000)
        .utcOffset("+00:00")
        .format("HH:mm - DD/MM/YYYY");
    }
  }
};
</script>

<style>
.box thead tr {
  background: #fff0 !important;
}
.box .table th {
  background: #00ff85 !important;
  color: #fff !important;
  font-weight: 600;
  text-transform: uppercase;
  position: sticky;
  top: 0;
}
.box table th,
.box table td {
  border: 0px solid #00ff85 !important;
  border-bottom: 1px solid rgba(51, 51, 51, 0.35) !important;
  background: #fff0 !important;
  color: #fff;
}</style>
