<template>
  <div class="privateAgency">
    <!-- MAIN PART -->
    <div class="main px-2">
      <b-container>
        <b-row>
          <b-col class="mx-auto right mb-4" cols="12" md="5">
            <div class="card2">
              <h4>{{ $t("promotion_insurance.submit_form_insurance") }}</h4>
              <div class="form-group">
                <label for="">{{
                  $t("promotion_insurance.from_main_balance")
                }}</label>
                <input
                  type="number"
                  step="0.001"
                  class="form-control"
                  :value="user.walletList[0].balance || 0"
                  required
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="">{{
                  $t("promotion_insurance.amount_insurance")
                }}</label>
                <input
                  type="number"
                  v-model.number="insurance.amount"
                  v-on:keyup="feeInsurance"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="">{{ $t("promotion_insurance.amount_fee") }}</label>
                <input
                  type="number"
                  v-model="amountFee"
                  class="form-control"
                  required
                  id=""
                  ref="total_fee"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="">{{ $t("promotion_insurance.time_limit") }}</label>
                <select
                  class="form-control"
                  v-model="insurance.full"
                  @change="onChangeFee()"
                >
                  <option value="">Please select a time limit</option>
                  <option
                    :value="list"
                    v-for="list in ListTimelimit"
                    :key="list.id"
                  >
                    {{ list.time_limit + " " + $t("promotion_insurance.days") }}
                    / {{ parseFloat(list.fee).toFixed(2) }}%
                  </option>
                </select>
              </div>

              <div class="form-group text-center">
                <button class="btn btn-aktrade mx-auto" @click="buyInsurance">
                  {{ $t("promotion_insurance.submit") }}
                </button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="right" cols="12" md="12">
            <div class="card2">
              <div class="tableWrapper table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("promotion_insurance.user_id") }}</th>
                      <th>{{ $t("promotion_insurance.amount") }}</th>
                      <th>{{ $t("promotion_insurance.time_limit") }}</th>
                      <th>{{ $t("promotion_insurance.start_date") }}</th>
                      <th>{{ $t("promotion_insurance.end_date") }}</th>
                      <th>{{ $t("promotion_insurance.status") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="History.data && History.data.length <= 0">
                      <td colspan="7" rowspan="3" class="text-center pt-5">
                        {{ $t("support.no_data_available_in_table") }}
                      </td>
                    </tr>
                    <template v-else>
                      <tr v-for="list in History.data" :key="list.created_time">
                        <td>{{ list.id }}</td>
                        <td>{{ list.user_id }}</td>
                        <td>{{ parseFloat(list.amount).toFixed(2) }}</td>
                        <td>{{ list.days }}</td>
                        <td>{{ list.created_time }}</td>
                        <td>{{ list.expired_time }}</td>
                        <td>
                          <span
                            :style="
                              list.status == 0
                                ? 'padding:5px 10px;font-size:14px;background: yellow;color:#000'
                                : list.status == 1
                                ? 'padding:5px 10px;font-size:14px;background: green'
                                : 'padding:5px 10px;font-size:14px;background: red'
                            "
                            >{{
                              list.status == 0
                                ? "Pending"
                                : list.status == 1
                                ? "Expired"
                                : "Canceled"
                            }}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div
                class="row px-3 align-items-center justify-content-between py-2 "
              >
                <div
                  class="flex-fill text-center mb-1 mb-md-0 text-md-left"
                  style="color: #fff"
                >
                  {{ currentPage }} Page Of {{ History.last_page || 0 }} Pages
                </div>
                <div
                  class="flex-fill button-div text-right d-flex justify-content-center justify-content-md-end"
                >
                  <b-button
                    variant="primary"
                    :disabled="currentPage <= 1"
                    class="btn btn-aktrade mr-2"
                    @click.prevent="paginate(currentPage - 1)"
                    >{{ $t("dashboard.previous") }}</b-button
                  >
                  <b-button
                    variant="primary"
                    :disabled="currentPage >= History.last_page"
                    class="btn btn-aktrade"
                    @click.prevent="paginate(currentPage + 1)"
                    >{{ $t("dashboard.next") }}</b-button
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      amountFee: 0,
      feeArray: {},
      currentPage: 1,
      insurance: {
        amount: 0,
        time_limit: 7,
        full: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      ListTimelimit: "insurance/TimeLimit",
      Fee: "insurance/Fee",
      History: "insurance/HistoryInsurance",
      user: "userinfo/User"
    })
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    onCopy: function(e) {
      alert("You just copied: " + e.value);
    },
    paginate(page) {
      if (page > 0 && page <= this.History.total) {
        this.currentPage = page;
        this.$store.dispatch("insurance/req_getTimeLimit", {
          page: page
        });
      }
    },
    onChangeFee: function(event) {
      this.amountFee =
        (this.insurance.amount *
          parseFloat(this.insurance.full.fee)) /
        100;
    },
    buyInsurance: async function() {
      const token = await this.genarateCaptChaV3("trade");
      this.$store.dispatch("insurance/postBuyInsurance", {
        amount: this.insurance.amount,
        time_limit: this.insurance.full.time_limit,
        insurance:this.insurance.full.id,
        token: token
      });
    },
    feeInsurance() {
      this.amountFee =
        (this.insurance.amount *
          parseFloat(this.insurance.full.fee)) /
        100;
    }
  },
  created() {
    this.$store.dispatch("insurance/req_getTimeLimit");
  },
  async mounted() {
    // simulate load data
    this.onload();
    await new Promise(resolve => setTimeout(resolve, 300));
    this.outload();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/mixins.scss";
textarea.form-control {
  background-color: transparent !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #0b0f1b;
  opacity: 1;
}
.privateAgency {
	// background-image: url("~@/assets/images/background/bg.jpg");
	// background-size: cover;
	// background-position: center center;
	// background-repeat: no-repeat;
	// background-attachment: fixed;
	min-height: 100vh;
}

.table thead th {
  vertical-align: middle;
  // border-bottom: 1px solid $primaryColor;
  // border-top: 1px solid $primaryColor;
}
.main {
  min-height: calc(100vh - 100px);
  padding: 50px 0;

  .left {
    margin-bottom: 30px;

    .title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .button {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .right {
    .card2 {
      background-color: rgba(0, 0, 0, 0.3);
      border: 1px solid $primaryColor;
      border-radius: 20px;
      padding: 30px 20px;
    }

    .inputGroup {
      border: 1px solid $primaryColor;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      overflow: hidden;

      input {
        border: none;
        background-color: transparent;
        color: white;
        width: calc(100% - 80px);
      }

      button {
        width: 80px;
        border-radius: 5px;
      }

      input:active,
      input:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.footer {
  background-color: #2b2f42;
  padding: 20px 0;

  .box {
    margin: 20px 0;

    .icon {
      border: 1px solid $primaryColor;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: calc(100% - 80px);
      margin-left: 10px;

      .name {
        font-size: 1.1em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .main {
    .left {
      .title {
        font-size: 2.5em;
      }
    }
  }
}
</style>
