<template>
	<div class="ratioBar">
		<div class="bar">
			<div class="currentValue" :style="`width: ${value * 100}%`"></div>
		</div>
		<div class="info d-flex justify-content-between">
			<div class="right d-flex justify-content-center align-items-center">
				<!-- <span :class="!isActive ? 'fx-20' : 'fx-15'">UP</span> -->
				<span class="ml-1 up" :class="!isActive ? 'fx-20' : 'fx-15'">
					{{ (value * 100).toFixed(2) }}%</span
				>
			</div>
			<div class="left d-flex justify-content-center align-items-center">
				<!-- <span :class="!isActive ? 'fx-20' : 'fx-15'">DOWN</span> -->
				<span class="ml-1 down" :class="!isActive ? 'fx-20' : 'fx-15'">
					{{ value != 0 ? (100 - value * 100).toFixed(2):"0.00" }}%</span
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['value', 'buy', 'sell'],
	data() {
		return {
			isActive: false,
		};
	},
	created() {
		this.isActive = this.$route.meta.page == 'trade';
	},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/colors.scss';
.fx-15 {
	font-size: 15px;
	font-weight: bold;
}
.fx-20 {
	font-size: 20px;
	font-weight: bold;
}
.ratioBar {
	.bar {
		height: 6px;
		width: 100%;
		border-radius: 4px;

		background-color: #ff0000;
		overflow: hidden;

		.currentValue {
			background: #6abd46 !important;
			height: 6px;
		}
	}
	.bar.white {
		background-color: white !important;
	}
	.info {
		font-size: 0.8em;
	}
}
.up {
	color: #00b04f;
}
.down {
	color: #ff0000;
}
</style>